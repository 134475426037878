import _ from "lodash";
import * as CONSTANTS_TYPE from "../constants/type";

const CryptoJS = require("crypto-js");

export const onDecryptedData = (data, key) => {
  const bytes = CryptoJS.AES.decrypt(data, key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
export const onCryptoData = (data, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};
export const encryptBase64 = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decryptBase64 = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export const setItemUserInfo = (userInfo) => {
  const encryptUserInfo = onCryptoData(
    userInfo,
    CONSTANTS_TYPE.LOCAL_STORAGE_KEY_USER_INFO
  );
  localStorage.setItem(
    CONSTANTS_TYPE.LOCAL_STORAGE_KEY_USER_INFO,
    encryptUserInfo
  );
};

export const onResultUserInfo = () => {
  let result;
  if (localStorage.getItem(CONSTANTS_TYPE.LOCAL_STORAGE_KEY_USER_INFO)) {
    result = onDecryptedData(
      localStorage.getItem(CONSTANTS_TYPE.LOCAL_STORAGE_KEY_USER_INFO),
      CONSTANTS_TYPE.LOCAL_STORAGE_KEY_USER_INFO
    );
  }
  return result;
};
export const onAddLocalStorage = (token, user_id, status) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user_id", user_id);
  localStorage.setItem("status", status);
  return true;
};

export const onCheckValueNull = (data) => {
  const userInfo = _.pickBy(data, (v) => v == null || false);
  return _.isEmpty(userInfo);
};


