import { locales } from "edu_lms_v2/i18n/i18n";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FooterStyle = styled.footer`
  background: #2a292c;
  .fz-12 {
    font-size: 12px;
  }
  .fz-14 {
    font-size: 14px;
  }
  .color-gray {
    color: #b8b7b9;
  }
  .word-spacing {
    word-spacing: 2px;
  }
  .border-1 {
    border: 1px solid #fff;
  }
  .fz-24 {
    font-size: 24px;
  }
`;

export default function Footer() {
  const { t, i18n } = useTranslation();
  const currentLanguage = locales[i18n.language];

  return (
    <FooterStyle>
      <div className="container">
        <div className="flex info row">
          <div className="col-md-3 mb-md-0 mb-4">
            <label className="company font-weight-bold mb-3">
              {t("Early Start Joint Stock Company")}
            </label>
            {currentLanguage == "VIE" && (
              <div className="flex">
                <i className="fa fa-phone monkey-fz-24" />
                <a
                  rel="nofollow"
                  href="tel:1900 63 60 52"
                  className="monkey-f-header font-weight-bold monkey-fz-16 text-white ml-3"
                >
                  1900 63 60 52
                </a>
              </div>
            )}
          </div>
          {currentLanguage == "VIE" && (
            <div className="col-md-9">
              <p className="fz-12 color-gray word-spacing">
                Giấy phép ĐKKD số 0106651756 do Sở Kế hoạch và Đầu tư TP Hà Nội
                cấp ngày 01/10/2014, thay đổi lần thứ 3 ngày 13/11/2020
              </p>
              <p className="fz-12 color-gray word-spacing mt-2">
                Trụ sở chính: Tầng 2, Tòa nhà FS, TTTM TNR Gold Season, số 47
                Nguyễn Tuân, P. Thanh Xuân Trung, Q. Thanh Xuân, TP. Hà Nội
              </p>
              <p className="fz-12 color-gray word-spacing mt-2">
                Người đại diện pháp luật: Ông Đào Xuân Hoàng - Nhà sáng lập
                &amp; Giám đốc điều hành
              </p>
            </div>
          )}
          {currentLanguage !== "VIE" && <div>
            <p>
              Head Office: 8 The Green, Suite A, Dover, Delaware 19901, United
              States
            </p>
          </div>}
        </div>

        <div className="middle flex pr mt-5 pt-2 row">
          <div className="flex flex-nowrap col-md-7">
            <div className="col">
              <label className="nav-title text-white font-weight-bold monkey-f-header pb-3">
                {t("about monkey")}
              </label>
              <ul className="menu">
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href={currentLanguage == "VIE" ? "https://monkey.edu.vn/gioi-thieu" : "https://www.monkeyenglish.net/en/about-monkey"}
                    title=""
                  >
                    {t("intro")}
                  </a>
                </li>
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/cau-chuyen-thuong-hieu"
                    title=""
                  >
                    Câu Chuyện Thương Hiệu
                  </a>
                </li>}
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href={currentLanguage == "VIE" ? "https://monkey.edu.vn/giai-thuong" : "https://www.monkeyenglish.net/en/prize"}
                    title=""
                  >
                    {t("remarkable achievements")}
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/tin-tuc"
                    title=""
                  >
                    {t("news")}
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/lien-he"
                    title=""
                  >
                    {t("contact us")}
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    rel="nofollow"
                    target="blank"
                    href="https://tuyendung.monkey.edu.vn/"
                    title=""
                  >
                    {t("careers")}
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href={currentLanguage == "VIE" ? "https://monkey.edu.vn/dieu-khoan-su-dung" : "https://www.monkeyenglish.net/en/terms-of-use"}
                    title=""
                  >
                    {t("terms of use")}
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/chinh-sach-bao-mat"
                    title=""
                  >
                    {t("privacy policy")}
                  </a>
                </li>
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/dai-ly"
                    title=""
                  >
                    Đăng Ký Đại Lý
                  </a>
                </li>}
              </ul>
            </div>
            <div className="col">
              <label className="nav-title text-white font-weight-bold monkey-f-header pb-3">
                {t("programs")}
              </label>
              <ul className="menu">
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/san-pham/monkey-junior"
                    title=""
                  >
                    Monkey Junior
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/san-pham/monkey-stories"
                    title=""
                  >
                    Monkey Stories
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/san-pham/monkey-math"
                    title=""
                  >
                    Monkey Math
                  </a>
                </li>
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/san-pham/vmonkey"
                    title=""
                  >
                    VMonkey
                  </a>
                </li>}
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/san-pham"
                    title=""
                  >
                    Monkey Trọn Bộ
                  </a>
                </li>}
              </ul>
            </div>
            <div className="col">
              <label className="nav-title text-white font-weight-bold monkey-f-header pb-3">
                {t("support")}
              </label>
              <ul className="menu">
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/active-account"
                    title=""
                  >
                    Kích Hoạt Tài Khoản
                  </a>
                </li>}
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href={currentLanguage == "VIE" ? "https://monkey.edu.vn/huong-dan-hoc" : "https://www.monkeyenglish.net/en/learning-guide"}
                    title=""
                  >
                   {t("learning guides")}
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href={currentLanguage == "VIE" ? "https://monkey.edu.vn/ho-tro-khach-hang" : "https://www.monkeyenglish.net/en/customer-support"}
                    title=""
                  >
                    {t("customer support")}
                  </a>
                </li>
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/ho-tro-khach-hang"
                    title=""
                  >
                    Câu Hỏi Thường Gặp
                  </a>
                </li>}
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    rel="nofollow"
                    target="blank"
                    href="https://www.facebook.com/groups/donghanhcungconhocmonkey/about"
                    title=""
                  >
                    Cộng Đồng
                  </a>
                </li>}
                {currentLanguage == "VIE" && <li className="mt-2">
                  <a
                    className="color-gray fz-14"
                    href="https://monkey.edu.vn/huong-dan-thanh-toan"
                    title=""
                  >
                    Hướng dẫn thanh toán
                  </a>
                </li>}
                {currentLanguage !== "VIE" && <a className="color-gray fz-14"
                  href="https://www.monkeyenglish.net/en/customer-support">
                  FAQs
                </a>}
              </ul>
            </div>
          </div>
          <div className="flex align-items-center ml-3">
            <div className="col mb-4 w-100">
              <div className="service">
                <p>{t("hotline & customer service")}</p>
                <div className="d-flex box-btn">
                  {currentLanguage == "VIE" && <a
                    rel="nofollow"
                    href="tel:1900 63 60 52"
                    className="bg-transparent border-1"
                  >
                    <i className="fa fa-phone monkey-fz-16 mr-2" /> 1900 63 60 52
                  </a>}
                  <a
                    rel="nofollow"
                    href="mailto:monkeyxinchao@monkey.edu.vn"
                    className="bg-transparent border-1"
                  >
                    <i className="fa fa-envelope mr-2"></i>
                    {t("email support")}
                  </a>
                </div>
                <span className="color-gray">
                  {t("every day: 7.00 - 22.00")}
                </span>
              </div>
              <div className="social">
                <p className="font-weight-bold">{t("connect with us")}</p>
                <div className="social__list flex">
                  <a
                    rel="nofollow"
                    href="https://www.facebook.com/Monkey.edu.vn"
                    target="blank"
                    title=""
                    className="fz-24 d-flex justify-content-center align-items-center"
                  >
                    <i
                      className="fa fa-facebook-square text-white"
                      aria-hidden="true"
                    />
                  </a>
                  <a
                    rel="nofollow"
                    href="https://www.youtube.com/c/MonkeyVi%E1%BB%87tNam/about"
                    target="blank"
                    title=""
                    className="fz-24 d-flex justify-content-center align-items-center"
                  >
                    <i
                      className="fa fa-youtube-play text-white"
                      aria-hidden="true"
                    />
                  </a>
                </div>
              </div>
              {currentLanguage == "VIE" && <a
                rel="nofollow"
                href="http://online.gov.vn/Home/WebDetails/91089"
                className="fs-ftr-cthuong"
              >
                Đã thông báo
              </a>}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div
          className="container flex"
          style={{ borderTop: "1px solid rgba(255, 255, 255, 0.2)" }}
        >
          <div className="copyright color-gray fz-14">
            © {t("copyright 2022 EARLY START.JSC. All rights reserved.")}
          </div>
        </div>
      </div>
    </FooterStyle>
  );
}
