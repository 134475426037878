import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { FIBHeaderQuestion } from "../../components";
import ContentAnswer from "./ContentAnswer";
import { SPLIT_CHARS_ANSWER, INPUT_CHARS_REPLACE } from "../../constants/FIB";
import { getNumberOfInputs } from "../../helpers/FIB";
import "./styles.scss";

const FIBMath = (
  {
    gameData,
    hideResultAnswer = false,
    inputtedAreasProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { question, answer } = gameData;

  const [inputAreas, setInputAreas] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  /**---------- Show inputted answers result ----------**/
  useEffect(() => {
    if (inputtedAreasProp.length > 0) {
      setInputAreas(inputtedAreasProp);
    }
  }, [inputtedAreasProp]);

  /**---------- Show all correct answers result ----------**/
  useEffect(() => {
    if (showCorrectAnswer) {
      let correctInputtedAnswers = [];
      answer.answers.forEach((answerLine) => {
        if (!answerLine.answerResult) return;

        const answerInputsLine = [];
        answerLine.answerItems.forEach((answerItem, answerIndex) => {
          if (answerItem === INPUT_CHARS_REPLACE) {
            answerInputsLine.push({ lineAnswerId: answerLine.answerId, answerIndex })
          }
        });

        answerLine.answerResult[0].split(SPLIT_CHARS_ANSWER).forEach((answerItemResult, index) => {
          answerInputsLine[index].value = answerItemResult;
          answerInputsLine[index].isCorrect = true;
        });

        correctInputtedAnswers = [...correctInputtedAnswers, ...answerInputsLine];
      });
      setInputAreas(correctInputtedAnswers);
    }
  }, [showCorrectAnswer])

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    const correctAnswerItemsLines = [];
    const answerLineHasResults = answer.answers.filter(answerLine => answerLine.answerResult);
    answerLineHasResults.forEach((answerLine, lineIndex) => {
      const inputAreasLine = inputAreas
        .filter((inputArea) => inputArea.lineAnswerId === answerLine.answerId)
        .sort((a, b) => a.answerIndex - b.answerIndex);

      correctAnswerItemsLines[lineIndex] = [];
      answerLine.answerResult.forEach((answerLineResultItem) => {
        const answerItemsResult = answerLineResultItem.split(SPLIT_CHARS_ANSWER);
        const correctAnswerItemsInLineTemp = [];
        answerItemsResult.forEach((answerItemResult, index) => {
          if (inputAreasLine[index] && answerItemResult === inputAreasLine[index].value) {
            correctAnswerItemsInLineTemp.push(inputAreasLine[index]);
          }
        });
        if (correctAnswerItemsInLineTemp.length > correctAnswerItemsLines[lineIndex].length) {
          correctAnswerItemsLines[lineIndex] = correctAnswerItemsInLineTemp;
        }
      });
    });

    let correctAnswerItems = [];
    correctAnswerItemsLines.forEach((correctAnswerItemsLine) => {
      correctAnswerItems = [...correctAnswerItems, ...correctAnswerItemsLine];
    });

    const inputtedAreas = inputAreas.map((inputArea) => {
      const isCorrectInputArea = correctAnswerItems.some(
        (correctItem) =>
          correctItem.lineAnswerId === inputArea.lineAnswerId &&
          correctItem.answerIndex === inputArea.answerIndex
      );
      return {
        ...inputArea,
        isCorrect: isCorrectInputArea,
      };
    });
    setInputAreas(inputtedAreas);
  
    const isCorrect = correctAnswerItems.length === getNumberOfInputs(answer.answers);
    onComplete({ isCorrect, inputtedAreas, totalCorrectAnswer: correctAnswerItems.length });
  };

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
    setInputAreas([]);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleChangeInput = ({ lineAnswerId, answerIndex, value }) => {
    let shadowInputAreas = [...inputAreas];
    if (isCheckedAnswer) {
      shadowInputAreas = shadowInputAreas.map(input => ({ ...input, isCorrect: null }));
    }
  
    const arrIndexInputFocused = inputAreas.findIndex(
      (inputArea) =>
        inputArea.lineAnswerId === lineAnswerId &&
        inputArea.answerIndex === answerIndex
    );
    if (arrIndexInputFocused > -1) {
      shadowInputAreas.splice(arrIndexInputFocused, 1);
    }
    shadowInputAreas.push({ lineAnswerId, answerIndex, value });
    setInputAreas(shadowInputAreas);

    if (shadowInputAreas.length === getNumberOfInputs(answer.answers)) {
      onPlaying(false);
    }
  }

  return (
    <div className="FIBMath__wrapper">
      <FIBHeaderQuestion question={question} isReadOnly={isReadOnly}/>
      <ContentAnswer
        answer={answer}
        inputAreas={inputAreas}
        isView={isReadOnly || isViewOnly}
        hideResultAnswer={hideResultAnswer}
        onChangeInput={handleChangeInput}
      />
    </div>
  );
};

export default forwardRef(FIBMath);
