import React from "react";
import styled from "styled-components";
import { renderLabelIndex } from "edu_lms_v2/libraries/hoc10Game/helpers/MTC_003";
import { TYPE_ENGLISH } from "edu_lms/constants/type";

const OrderAnswerIndex = ({ typeIndex, index, styles }) => {
  const { color, background } = styles;
  

  return (
    <Label color={color} background={background}>
      {renderLabelIndex(typeIndex, index, TYPE_ENGLISH)}
    </Label>
  );
};
export default OrderAnswerIndex;

const Label = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
`;
