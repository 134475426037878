export const STATUS = {
  START: "",
  LOADING: "loading",
  SUCCESS: "success",
};

export const ROLE = {
  ADD: "addInfo",
  UPDATE: "updateInfo",
};

export const HEADERS = [
  {
    label: "STT",
    key: "stt",
  },
  {
    label: "Tên học sinh",
    key: "name",
  },
  {
    label: "Thời gian làm bài (s)",
    key: "time",
  },
  {
    label: "Trạng thái",
    key: "status",
  },
  {
    label: "Điểm",
    key: "score",
  },
];

export const SELECTED_DOING_EX = 1;
