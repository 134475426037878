import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import AudioAnalyser from 'react-audio-analyser';
import styled from "styled-components";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import HeaderQuestion from "./HeaderQuestion";
import IconRecord from "edu_lms_v2/assets/img/icon_record.gif";
import IconAudio from "edu_lms_v2/assets/img/icon-audio.jpg"
import "./styles.scss";
import { AUDIO_ERROR, AUDIO_SUCCESS } from "edu_lms/constants/type";
import { onDispatchSrcAudio } from "edu_lms/modules/App/actions";
import Loading from "edu_lms_v2/components/Loading";

const url =`${process.env.REACT_APP_API_TUTORING }/lesson/api/v1/speech/verification?app_id=69&is_web=1`;

const AITTR = (
  {
    gameData,
    hideResultAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const dispatch = useDispatch();
  
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowToolTip] = useState(!!gameData.resultHistory);
  const [result, setResult] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [showPercentResult, setShowPercentResult] = useState(false);
  
  const target = useRef(null);

  const audioProps = {
    audioType: "audio/wav",
    status,
    timeslice: 1000,
    startCallback: (e) => {
      setShowToolTip(false);
      onPlaying(false);
      setIsRecording(true);
    },

    stopCallback: (e) => {
      const formData = new FormData();
      const audioFile = new File([e], "audio.wav", {
        type: "audio/wav",
      });
      formData.append("profile_id", 1111);
      formData.append("lesson_id", 0);
      formData.append("audio_file", audioFile);
      formData.append("sentence", gameData.answers);
      sendAudioToAPI(formData);
      setShowToolTip(true);
      setIsRecording(false);
    },

    errorCallback: (err) => {
        toast.error("Vui lòng kết nối micro")
    },
  };

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    stopRecording,
  }));

  useEffect(()=> {
    setShowToolTip(!!gameData.resultHistory)
    setResult(gameData.resultHistory);
    setIsRecording(false);
    setShowPercentResult(!!gameData.resultHistory);
    return () => {
      stopRecording();
    };
  }, [gameData.activityId])

  const startRecording = () => {
    setIsRecording(true);
    setShowPercentResult(false)
  };
  
  const stopRecording = () => {
    setIsRecording(false);
  };

  const handleCheckAnswer = () => {
    setShowToolTip(true);
    setShowPercentResult(true);
    setIsRecording(false);
    const isCorrect = result?.score >= 60 // >60% pass
    !hideResultAnswer &&
      dispatch(onDispatchSrcAudio(isCorrect ? AUDIO_SUCCESS : AUDIO_ERROR));
    onComplete({ result, isCorrect });
  };

  const handleResetAnswer = () => {
    setResult(undefined);
  };

  const sendAudioToAPI = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        url,
        formData
      );
      setLoading(false);
      setResult(response.data.data);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const controlAudio = (status) => {
    setStatus(status);
    status === "recording" ? startRecording() : stopRecording();
  };

  return (
    <div className="AI-tutoring p-3">
      <HeaderQuestion question={gameData} isReadOnly={isReadOnly}/>
    
      <AudioAnalyser {...audioProps}>
      </AudioAnalyser>

      <div
        className="content-answer d-flex justify-content-center mt-5 mb-5"
      >
        {!isRecording && (
          <img
            ref={target}
            className={classNames("img-audio",{
                'pointer-none': isReadOnly
            })}
            src={IconAudio}
            onClick={() => controlAudio("recording")}
          />
        )}
        {isRecording && (
          <img
            className="cursor"
            src={IconRecord}
            onClick={() => controlAudio("inactive")}
          />
        )}
        <Overlay target={target.current} show={isReadOnly ? false : showTooltip} placement="left">
          {(props) => (
            <Tooltip id="overlay-audio" {...props}>
              <p>Click to try again</p>
            </Tooltip>
          )}
        </Overlay>
      </div>
      {loading ? (
        <Loading />
      ) : (
        !!result && !isRecording && <div className="d-flex justify-content-center align-items-center mt-5" style={{ gap: 30 }}>
          <div className="d-flex justify-content-center align-items-center flex-wrap show-answer ml-3" style={{padding: 10, minWidth: '10%', maxWidth: '85%'}}>
            {result.phonemes.map((answer, index) => {
              return (
                <ResultText
                  key={index}
                  value={answer.text}
                  score={answer.score}
                />
              );
            })}
          </div>
          {showPercentResult && <div className="show-result d-flex justify-content-center align-items-center">{Math.round(result.score)}% </div>}
        </div>
      )}
    </div>
  );
};

const TextColor = styled.span`
    color: ${props => props.isCorrect ? "green" : "red"};
    font-size: 20px;
    font-weight: bold;
`;

const ResultText = ({value, score})=> {
    if( value === ' '){
        return <span>&nbsp;</span>
    }
    return <TextColor isCorrect={score >= 60}>{value}</TextColor>
}

export default forwardRef(AITTR);
