import React from "react";
import _ from "lodash";
import THEMES from "edu_lms_v2/libraries/hoc10Game/constants/themes";
import { TYPE_DATA } from "../../constants";
import { Typography, Audio, AudioType, ImageWithFullScreen, Video } from "../../components";

const HeaderQuestion = ({ question, theme, isReadOnly }) => {
  const { typeQuestion, contentQuestion } = question;

  return (
    <div className="header-question__wrapper p-3 mb-4 quicksand-medium">
      <Typography
        fontSize={contentQuestion.text.fontSize}
        typeText={contentQuestion.text.typeText}
        text={contentQuestion.text.contentText}
      />
      {contentQuestion.srcAudio && (
        <Audio
          variant={AudioType.Secondary}
          src={contentQuestion.srcAudio}
          className="mx-auto d-block"
          isReadOnly={isReadOnly}
        />
      )}
      {_.includes(typeQuestion, TYPE_DATA.IMAGE) && (
        <ImageWithFullScreen
          src={contentQuestion.srcImage}
          alt="sequence question image"
          hasFullScreen={theme === THEMES.Sequence.Primary}
        />
      )}
      {_.includes(typeQuestion, TYPE_DATA.VIDEO) && (
        <Video src={contentQuestion.srcVideo} />
      )}
    </div>
  );
};

export default HeaderQuestion;
