import { isEmpty } from "lodash";
import styled from "styled-components";
import dayjs from "dayjs";
import PopupAddTeacher from "./PopupAddTeacher";
import { useState } from "react";
import { toast } from "react-toastify";
import { assignTeacherClassroom } from "edu_lms/services/teachingManager";
import { ROLE } from "./constant";

const StyleSectionTeacher = styled.div`
  border-radius: 12px;

  .w-200 {
    width: 200px;
  }

  .br-8 {
    border-radius: 8px;
  }
`;

const StyleButton = styled.button`
  border-radius: 8px;
`;

export default function AssignTeacherToClass({
  teacherInfo,
  classroomId,
  getListTeacher,
}) {
  const [role, setRole] = useState(ROLE.ADD);
  const [popupAddTeacher, setPopupAddTeacher] = useState(false);
  const startTime = dayjs(teacherInfo?.start_time * 1000);
  const endTime = dayjs(teacherInfo?.end_time * 1000);

  const addTeacherClassroom = (dataForm) => {
    const startTime = dayjs(dataForm.start_time).unix();
    const endTime = dayjs(dataForm.end_time).unix();
    if (endTime <= startTime) {
      return toast.error(
        "Thời gian kết thúc phải lớn hơn thời gian bắt đầu phụ trách"
      );
    }

    const data = {
      class_room_id: classroomId,
      email: dataForm.email,
      start_time: startTime,
      end_time: endTime,
    };
    handleAddTeacher(data);
  };

  const updateTeacherClassroom = (dataForm) => {
    const startTime = dayjs(dataForm.start_time).unix();
    const endTime = dayjs(dataForm.end_time).unix();
    if (endTime <= startTime) {
      return toast.error(
        "Thời gian kết thúc phải lớn hơn thời gian bắt đầu phụ trách"
      );
    }

    const data = {
      teacher_id: teacherInfo?.id,
      class_room_id: classroomId,
      email: dataForm.email,
      start_time: startTime,
      end_time: endTime,
    };
    handleAddTeacher(data);
  };

  const handleAddTeacher = (data) => {
    const loading = toast.loading(
      role === ROLE.ADD
        ? "Đang thêm giáo viên. Vui lòng chờ..."
        : "Đang thay đổi giáo viên. Vui lòng chờ..."
    );
    assignTeacherClassroom(data)
      .then((res) => {
        if (res.data.status === "success") {
          toast.dismiss(loading);
          toast.success(res.data.message);
          getListTeacher();
          return setPopupAddTeacher(false);
        }

        toast.error(res.data.message);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {!isEmpty(teacherInfo) ? (
        <StyleSectionTeacher className="d-flex justify-content-between monkey-bg-white shadow-lg mb-3 py-3 px-3 px-md-5">
          <div>
            <div className="d-md-flex mb-3 mb-md-2">
              <p className="monkey-f-header w-200">Giáo viên phụ trách :</p>
              <span className="monkey-f-header font-weight-bold">
                {teacherInfo?.name}
              </span>
            </div>
            <div className="d-md-flex mb-3 mb-md-2">
              <p className="monkey-f-header w-200">Email :</p>
              <span className="monkey-f-header font-weight-bold">
                {teacherInfo?.email}
              </span>
            </div>
            <div className="d-md-flex mb-3 mb-md-2">
              <p className="monkey-f-header w-200">Số điện thoại :</p>
              <span className="monkey-f-header font-weight-bold">
                {teacherInfo?.phone}
              </span>
            </div>
            <div className="d-md-flex">
              <p className="monkey-f-header w-200">Thời gian phụ trách :</p>
              <span className="monkey-f-header font-weight-bold">
                {startTime.format("DD/MM/YYYY")} -&nbsp;
                {endTime.format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                setRole(ROLE.UPDATE);
                setPopupAddTeacher(true);
              }}
              className="py-2 px-3 br-8 text-white monkey-bg-orange"
            >
              <i className="fa fa-exchange mr-2" aria-hidden="true" />
              Đổi giáo viên
            </button>
          </div>
        </StyleSectionTeacher>
      ) : (
        <div className="mb-2">
          <StyleButton
            onClick={() => {
              setRole(ROLE.ADD);
              setPopupAddTeacher(true);
            }}
            className="monkey-bg-orange br-8 text-white py-2 px-3 font-weight-bold"
          >
            <i className="fa fa-plus-square mr-2" aria-hidden="true" />
            Thêm giáo viên
          </StyleButton>
        </div>
      )}
      <PopupAddTeacher
        show={popupAddTeacher}
        setShow={setPopupAddTeacher}
        role={role}
        addTeacherClassroom={addTeacherClassroom}
        updateTeacherClassroom={updateTeacherClassroom}
      />
    </div>
  );
}
