import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select, { createFilter } from "react-select";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postCreateClassroom } from "edu_lms/services/teachingManager";
import { ROLE, STATUS } from "edu_lms_v2/modules/TeachingManager/constant";
import styles from "edu_lms_v2/modules/TeachingManager/teachingManager.module.scss";
import styled from "styled-components";
import {
  getDetailClassroom,
  getGrades,
  getLevelSchool,
} from "edu_lms_v2/services/listBook";
import { CLASS_TYPE, handleFormatTitle } from "./constant";

const schema = yup
  .object()
  .shape({
    cambridge: yup.object().required("Choose cambridge. Please!"),
    level: yup.object().required("Choose level. Please!").nullable(),
    class_type: yup.object().required("Choose class type. Please!"),
    opening_day: yup.string().required("Choose day. Please!").nullable(),
  })
  .required();

export default function AddClassroomTutoring({
  role,
  setRole,
  listClassroom,
  idClassroom,
  setStatus,
  setIdClassroom,
  getListClassroom,
}) {
  const defaultGrade = listClassroom.find((item) => item.id === idClassroom);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState("");
  const [classType, setClassType] = useState("");
  const [openingDay, setOpeningDay] = useState("");
  const [titleClassroom, setTitleClassroom] = useState("");
  const { handleSubmit, control, errors, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getLevelSchool()
      .then((res) => {
        if (res.data.status === "success") {
          const formatCambridge = res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          setSchoolLevels(formatCambridge);
        }
      })
      .catch((error) => console.log(error));

    if (role === ROLE.UPDATE) {
      getDetailClassroom(defaultGrade.id)
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.data;
            const classType = CLASS_TYPE.find(
              (item) => item.value === data.class_type
            );
            setValue("cambridge", {
              value: data.level_school_id,
              label: data.title_level_school,
            });

            setValue("level", {
              value: data.grade_id,
              label: data.title_grade,
            });

            setValue("class_type", classType);
            setValue("opening_day", data.opening_day * 1000);
            setLevel(data.title_grade);
            setClassType(classType.label);
            setOpeningDay(data.opening_day * 1000);
            handleGetLevels(data.level_school_id);
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    setTitleClassroom(handleFormatTitle(level, classType, openingDay));
  }, [level, classType, openingDay]);

  const handleGetLevels = (schoolLevel) => {
    getGrades(schoolLevel)
      .then((res) => {
        if (res.data.status === "success") {
          const formatLevel = res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          setLevels(formatLevel);
        }
      })
      .catch((error) => console.log(error));
  };

  const onHandleSubmit = (dataForm) => {
    const date = new Date(Number(dataForm.opening_day) || dataForm.opening_day);
    const data = {
      grade_id: dataForm.level.value,
      title: titleClassroom,
      class_type: dataForm.class_type.value,
      opening_day: date.getTime() / 1000,
    };

    if (role === ROLE.UPDATE) data.id = defaultGrade.id;
    setStatus(STATUS.LOADING);
    postCreateClassroom(data).then((res) => {
      if (res.data.status === "success") {
        setIdClassroom(res.data?.data);
        setStatus(STATUS.SUCCESS);
        getListClassroom();
      } else {
        setStatus(STATUS.START);
        setRole(ROLE.ADD);
        toast.error(res.data.message || "Tạo lớp học thất bại!");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="row my-3">
        <div className="col-sm-6 mb-3">
          <p className="font-weight-bold">
            Cambridge<span className="text-danger">*</span>
          </p>
          <Controller
            render={({ onChange, value }) => (
              <Select
                value={value}
                placeholder="Choose cambridge"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 2000 }),
                }}
                onChange={(e) => {
                  onChange(e);
                  setValue("level", null);
                  handleGetLevels(e.value);
                }}
                options={schoolLevels}
                filterOption={createFilter({
                  matchFrom: "any",
                  stringify: (option) => `${option.label}`,
                })}
              />
            )}
            name="cambridge"
            control={control}
          />
          {errors.cambridge && (
            <p className="text-danger">{errors.cambridge.message}</p>
          )}
        </div>

        <div className="col-sm-6 mb-3">
          <p className="font-weight-bold">
            Level<span className="text-danger">*</span>
          </p>
          <Controller
            render={({ onChange, value }) => (
              <Select
                value={value}
                placeholder="Choose level"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 2000 }),
                }}
                onChange={(e) => {
                  onChange(e);
                  setLevel(e.label);
                }}
                options={levels}
                filterOption={createFilter({
                  matchFrom: "any",
                  stringify: (option) => `${option.label}`,
                })}
              />
            )}
            name="level"
            control={control}
          />
          {errors.level && (
            <p className="text-danger">{errors.level.message}</p>
          )}
        </div>

        <div className="col-sm-6 mb-3">
          <p className="font-weight-bold">
            Class type<span className="text-danger">*</span>
          </p>
          <Controller
            render={({ onChange, value }) => (
              <Select
                value={value}
                placeholder="Choose class type"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 2000 }),
                }}
                onChange={(e) => {
                  onChange(e);
                  setClassType(e.label);
                }}
                options={CLASS_TYPE}
                filterOption={createFilter({
                  matchFrom: "any",
                  stringify: (option) => `${option.label}`,
                })}
              />
            )}
            name="class_type"
            control={control}
          />
          {errors.class_type && (
            <p className="text-danger">{errors.class_type.message}</p>
          )}
        </div>

        <StyleDatePicker className="col-sm-6 mb-3">
          <p className="font-weight-bold">
            Opening day<span className="text-danger">*</span>
          </p>
          <Controller
            control={control}
            name="opening_day"
            render={({ onChange, value, onBlur }) => (
              <DatePicker
                onChange={onChange}
                onSelect={(e) => setOpeningDay(e)}
                selected={value}
                onBlur={onBlur}
                showTimeSelect
                dateFormat="dd/MM/yyyy, h:mm aa"
                minDate={new Date()}
                placeholderText="Choose day"
                wrapperClassName="w-100 d-flex"
              />
            )}
          />
          {errors.opening_day && (
            <p className="text-danger">{errors.opening_day.message}</p>
          )}
        </StyleDatePicker>

        <div className="col-12 mb-3 d-flex mt-1">
          <div className="w-100">
            <div className="d-flex align-items-center">
              <p className="font-weight-bold col-4 pl-0">
                Class name<span className="text-danger">*</span>
              </p>
              <StyleClassName
                name="class_name"
                type="text"
                value={titleClassroom}
                className="col-8 rounded py-1 px-3"
                onChange={({ target }) => setTitleClassroom(target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center my-3">
        <button type="submit" className={`${styles.buttonAddClass} px-5`}>
          {role === ROLE.ADD ? "Tạo lớp" : "Chỉnh sửa"}
        </button>
      </div>
    </form>
  );
}

const StyleDatePicker = styled.div`
  input {
    width: 100%;
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .react-datepicker {
    display: flex;
  }
`;

const StyleClassName = styled.input`
  border: 1px solid #ccc;
`;
