import * as types from "../../constants/actionsType";

export const onDispatchAddBookId = (bookId) => {
  return {
    type: types.PA_ADD_BOOK_ID,
    bookId,
  };
};
export const onDispatchBookInfo = (data) => {
  return {
    type: types.PA_BOOK_INFO,
    data,
  };
};
export const onDispatchIsClickSubmitAnswer = (data) => {
  return {
    type: types.PA_IS_CLICK_SUBMIT_ANSWER,
    data,
  };
};
export const onDispatchIsClickRefresh = (data) => {
  return {
    type: types.PA_IS_CLICK_REFRESH,
    data,
  };
};
export const onDispatchClickDownImg = (data) => {
  return {
    type: types.PA_CLICK_DOWN_IMG,
    data,
  };
};
export const onDispatchPlayMusic = (data) => {
  return {
    type: types.PA_PLAY_BACKGROUND_MUSIC,
    data,
  };
};
export const onDispatchIncreaseBookTool = (data) => {
  return {
    type: types.PA_INCREASE_BOOK_TOOL,
    data,
  };
};
