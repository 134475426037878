import { GRADES, SUBJECTS_ID } from "edu_lms/constants/type";

export const SCHOOL_LEVEL_DEFAULT = [
  {
    schoolLevel: 1,
    gradeIdDefault: GRADES.lop1,
    subjectIdDefault: 32,
  },
  {
    schoolLevel: 2,
    gradeIdDefault: GRADES.lop6,
    subjectIdDefault: 42,
  },
  {
    schoolLevel: 3,
    // gradeIdDefault: GRADES.lop10,
    // subjectIdDefault: SUBJECTS_ID.ngu_van_cap3,
  },
];
export const TYPE_QUESTION_LIBRARY = 1;
export const TYPE_MOCK_TEST = 2;

export const DISABLED_GRADE = [
  GRADES.lop4,
  GRADES.lop5,
  GRADES.lop7,
  GRADES.lop8,
  GRADES.lop9,
  GRADES.lop10,
  GRADES.lop11,
  GRADES.lop12,
];
export const DISABLED_SUBJECT = [
  SUBJECTS_ID.tu_nhien_xa_hoi_cap1,
  SUBJECTS_ID.dao_duc_cap1,
  SUBJECTS_ID.am_nhac_cap1,
  SUBJECTS_ID.mi_thuat_cap1,
  SUBJECTS_ID.giao_duc_the_chat_cap1,
  SUBJECTS_ID.tieng_anh_cap1,
  SUBJECTS_ID.hoat_dong_trai_nghiem_cap1,
  SUBJECTS_ID.tin_hoc_cap1,
  SUBJECTS_ID.cong_nghe_cap1,
  SUBJECTS_ID.lich_su_va_dia_li_cap_1,
  SUBJECTS_ID.khoa_hoc_cap1,
  SUBJECTS_ID.giao_duc_cong_dan_cap2,
  SUBJECTS_ID.cong_nghe_cap2,
  SUBJECTS_ID.tieng_anh_cap2,
  SUBJECTS_ID.am_nhac_cap2,
  SUBJECTS_ID.mi_thuat_cap2,
  SUBJECTS_ID.giao_duc_the_chat_cap2,
  SUBJECTS_ID.hoat_dong_trai_nghiem_cap2,
  SUBJECTS_ID.ngu_van_cap3,
  SUBJECTS_ID.toan_cap3,
  SUBJECTS_ID.vat_ly_cap3,
  SUBJECTS_ID.lich_su_va_dia_li_cap3,
  SUBJECTS_ID.mi_thuat_cap3,
  SUBJECTS_ID.am_nhac_cap3,
  SUBJECTS_ID.cong_nghe_cap3,
  SUBJECTS_ID.tin_hoc_cap3,
  SUBJECTS_ID.sinh_hoc_cap3,
  SUBJECTS_ID.hoa_hoc_cap3,
  SUBJECTS_ID.giao_duc_kinh_te_va_phap_luat_cap3,
  SUBJECTS_ID.giao_duc_the_chat_cap3,
  SUBJECTS_ID.hoat_dong_trai_nghiem_cap3,
  SUBJECTS_ID.tieng_anh_cap3,
  SUBJECTS_ID.giao_duc_quoc_phong_an_ninh_cap3,
  SUBJECTS_ID.lich_su_cap3,
  SUBJECTS_ID.dia_li_cap3,
];
