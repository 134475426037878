import React, { useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import SEO from "edu_lms/components/Seo";
import { getListQuestion, postHistoryExam } from "../../services/question";
import Footer from "./Footer";
import Header from "./Header";
import DoingExerciseWrapper from "./DoingExerciseWrapper";
import {
  onFormatDataGameConfig,
  getQuestionSetTitle,
  getQuestionsShuffle,
  sendErrorToTelegram,
} from "./selection";
import OverviewQuestionSet from "./OverviewQuestionSet";
import ResultComplete from "./ResultComplete";
import ReportError from "./ReportError";
import PopupConfirmSubmit from "./PopupConfirmSubmit";
import PopupTimeUp from "./PopupTimeUp";
import { DRAFT, PLAY_MODE } from "edu_lms/constants/type";
import ResultQuestion from "edu_lms_v2/modules/MockTest/ResultQuestion";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { PLAY_MOCK_TEST, SUBMIT_TEST } from "edu_lms/constants/eventNameGTM";
import { locales } from "edu_lms_v2/i18n/i18n";
import { decryptBase64 } from "../selection";
import useQuery from "edu_lms_v2/components/useQuery";

const QUESTION_DISTANCE = 60;

export default function ExerciseContainer(props) {
  const gameRef = useRef();
  const QuestionsRef = useRef();
  const { id, studentId } = useParams();
  const query = useQuery();
  const { t, i18n } = useTranslation();
  const [catchError, setCatchError] = useState(false);
  const [questionListResponse, setQuestionListResponse] = useState({});
  const [originQuestions, setOriginQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [isButtonNext, setIsButtonNext] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showPopupConfirmSubmit, setPopupConfirmSubmit] = useState(false);
  const [showPopupTimeUp, setShowPopupTimeUp] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [timeStart, setTimeStart] = useState();
  const [timeEnd, setTimeEnd] = useState();
  const [result, setResult] = useState({});
  const [showListAnswer, setShowListAnswer] = useState(false);
  const [step, setStep] = useState(1);
  const [showPopupSuggestions, setShowPopupSuggestions] = useState(false);
  const [questionReportError, setQuestionReportError] = useState([]);

  const currentLanguage = locales[i18n.language];

  useEffect(() => {
    setTimeStart(dayjs().unix());
    const token = localStorage.getItem("token");
    const guest_id = localStorage.getItem("guest_id");
    if (studentId) {
      const decodeStudentId = window.atob(studentId);
      const studentInfo = { id: decodeStudentId };
      localStorage.setItem("student_info", JSON.stringify(studentInfo));
    }
    if (!token && !guest_id) {
      localStorage.setItem("guest_id", uuidv4());
    }
  }, []);

  useEffect(() => {
    onGetData({
      question_set_id: id,
    });
  }, [id]);

  useEffect(() => {
    const language = localStorage.getItem("language");
    language && i18n.changeLanguage(language);
  }, [currentLanguage]);

  useEffect(() => {
    const arrQuestionDone = questions.filter((question) => question?.isCorrect);
    const countDone = arrQuestionDone.length;
    const total = questions.length;
    setIsDone(countDone === total);
  }, [questions]);

  const onGetData = ({ question_set_id }) => {
    setLoading(true);
    getListQuestion({ question_set_id })
      .then((res) => res.data)
      .then((res) => {
        setQuestionListResponse(res.data);

        const onError = (_data) => {
          const content = `Lỗi format data bộ đề ID ${_data?.questionId} lỗi câu số ${_data?.index} activity ID ${_data?.activityId}  activity name ${_data?.activityName} `;
          sendErrorToTelegram(content, _data?.activityId);
        };
        const originData = res.data?.list_question || [];
        const formattedQuestions = onFormatDataGameConfig(originData, onError);
        setQuestionReportError(originData);

        const isRandom = res.data?.random;
        const questionsShuffle = getQuestionsShuffle(formattedQuestions, id);
        setOriginQuestions(isRandom ? questionsShuffle : formattedQuestions);
        setQuestions(isRandom ? questionsShuffle : formattedQuestions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setEventPlayMockTest = (isSubmit) => {
    if (questionSetTitle.includes(DRAFT)) return;

    setEventGTM({
      event: PLAY_MOCK_TEST,
      mock_test_name: questionSetTitle,
      grade_name: gradeName,
      subject_name: subjectName,
      type_exam: limitedTime,
      click_submit: isSubmit,
    });
  };

  const handleScrollQuestions = (currentQuestion) => {
    const displayNumberQuestion = Math.round(
      window.innerWidth / QUESTION_DISTANCE
    );
    const scrollIncrease =
      (currentQuestion - displayNumberQuestion) * QUESTION_DISTANCE;

    if (currentQuestion >= displayNumberQuestion) {
      QuestionsRef.current.questions.scroll(scrollIncrease, 0);
    }
  };

  const onChangeQuestion = (index) => {
    gameRef.current && gameRef.current.handleReset && gameRef.current.handleReset();

    activeQuestionIndex !== index &&
      gameRef.current?.onNextQuestion &&
      gameRef.current?.onNextQuestion();

    setActiveQuestionIndex(index);
    setIsButtonNext(questions[index].isCorrect !== undefined);
    handleScrollQuestions(index + 1);

    if (catchError) {
      onRefresh();
      setCatchError(false);
    }
  };

  const onNextQuestion = () => {
    let index = activeQuestionIndex + 1;
    if (index > questions.length - 1) {
      onSubmitExam();
    } else {
      onChangeQuestion(index);
    }
  };

  const handleCheckAnswer = () => {
    if (!gameRef.current) return;
    gameRef.current.handleCheck();
  };

  const onComplete = (data) => {
    const newQuestions = questions.map((question, _index) =>
      _index === activeQuestionIndex ? { ...question, ...data } : question
    );
    const arrFinishedQuestion = newQuestions.filter(
      (question) => question?.isCorrect
    );
    const historyData = { id_questions_set: id, data: newQuestions };
    localStorage.setItem("history", JSON.stringify(historyData));

    postHistoryQuestion(arrFinishedQuestion.length > 1 ? 2 : 1);
    setQuestions(newQuestions);
    setIsButtonNext(true);
  };

  const onPlaying = () => {
    setIsButtonNext(false);
  };

  const postHistoryQuestion = (type) => {
    let countCorrect = 0;
    let totalScore = 0;
    const data = questions.map((question) => {
      const score = isNaN(question.score) ? 0 : Number(question.score);
      let status_answer = 1;
      if (question.isCorrect === true) {
        countCorrect = countCorrect + 1;
        totalScore = +(totalScore + score).toFixed(12);
        status_answer = 2;
      }

      if (question.isCorrect === false) {
        status_answer = 3;
      }
      const result = {
        activity_id: question?.activity_id,
        status_answer: status_answer,
      };
      return result;
    });

    let dataResult = {
      question_set_id: id,
      data: JSON.stringify(data),
      time_start: timeStart,
      time_end: dayjs().unix(),
      total_question: questions.length,
      total_correct: countCorrect,
      mark: totalScore,
      type: type,
      guest_id: localStorage.getItem("guest_id"),
      result_detail: JSON.stringify(questions),
      tutoring_user_id: query.get("tutoring_user") && (decryptBase64(query.get("tutoring_user")) ?? ''),
      class_item_id: Number(query.get("class_item_id")) ?? '',
      student_id: Number(query.get("student_id")) ?? '',
    };

    const rawStudentInfo = localStorage.getItem("student_info");
    const user_class_room_id = JSON.parse(rawStudentInfo)?.id;
    dataResult = { ...dataResult, user_class_room_id };

    setResult(dataResult);
    postHistoryExam(dataResult);
  };

  const onSubmitExam = () => {
    const questionCompleted = questions.filter(
      (question) => question.isCorrect
    );
    const percentCompleted = questionCompleted.length / questions.length;
    let currentTime = localStorage.getItem("timer");
    currentTime = currentTime
      ? JSON.parse(currentTime)
      : { minute: 0, second: 0 };
    const timeDone =
      limitedTime * 60 - (currentTime.minute * 60 + currentTime.second);

    setEventPlayMockTest(true);
    postHistoryQuestion(3);
    localStorage.removeItem("history");
    localStorage.removeItem("timer");
    setTimeEnd(dayjs().unix());
    setIsComplete(true);
    setStep(2);
    setShowPopupTimeUp(false);
    setPopupConfirmSubmit(false);
    setEventGTM({
      event: SUBMIT_TEST,
      test_name: questionSetTitle,
      test_grade: gradeName,
      test_subject: subjectName,
      test_duration: timeDone,
      stop_at: `Câu ${activeQuestionIndex + 1}`,
      completed: `${percentCompleted.toFixed(2) * 100}%`,
    });
  };

  const onConfirmSubmit = () => {
    const arrQuestionDone = questions.filter((question) => question.isCorrect !== undefined);
    const countDone = arrQuestionDone.length;
    const total = questions.length;
    setIsDone(countDone === total);
    setPopupConfirmSubmit(true);
  };

  const onTimeUp = () => {
    setShowPopupTimeUp(true);
    setPopupConfirmSubmit(false);
    localStorage.removeItem("history");
    localStorage.removeItem("timer");
  };

  const onRefresh = () => {
    localStorage.removeItem("history");
    localStorage.removeItem("timer");
    setActiveQuestionIndex(0);
    setStep(1);
    setTimeStart(dayjs().unix());
    setIsButtonNext(false);
    onGetData({
      question_set_id: id,
    });
  };

  const toggle = () => {
    setShowPopupSuggestions(!showPopupSuggestions);
  };

  const activeQuestion = questions[activeQuestionIndex];
  const activeQuestionReportError = questionReportError[activeQuestionIndex];
  if (loading) {
    return <div>Loading...</div>;
  }

  const handleTrackingError = () => {
    const { activity_id, activity_name } = activeQuestion;
    const content = `Lỗi bộ đề activity ID ${activity_id} activity name ${activity_name} `;
    sendErrorToTelegram(content, activity_id);
  };

  const {
    type: playMode,
    status,
    title,
    public_title,
    grade_name: gradeName,
    subject_name: subjectName,
    limited_time: limitedTime,
  } = questionListResponse;

  const questionSetTitle = getQuestionSetTitle({
    playMode,
    status,
    title,
    public_title,
  });
  const titleSEO = { title: title };

  return (
    <>
      <SEO data={titleSEO} />
      <Header
        subjectName={subjectName}
        gradeName={gradeName}
        title={title}
        setEventPlayMockTest={setEventPlayMockTest}
        step={step}
        id={id}
        currentLanguage={currentLanguage}
      />
      <>
        <PopupConfirmSubmit
          show={showPopupConfirmSubmit}
          setShow={setPopupConfirmSubmit}
          onSubmitExam={onSubmitExam}
          isComplete={isDone}
        />
        <PopupTimeUp show={showPopupTimeUp} onSubmitExam={onSubmitExam} />
        <Wrapper className="d-flex justify-content-around">
          {step === 1 ? (
            <>
              <Sentry.ErrorBoundary
                fallback={
                  <div className="w-100">
                    <p className="text-center monkey-fz-24 font-weight-bold mt-5">
                      Đang cập nhật...
                    </p>
                  </div>
                }
                onError={handleTrackingError}
              >
                <DoingExerciseWrapper
                  data={activeQuestion}
                  hasAnsweredChecking={playMode === PLAY_MODE.PRACTICE}
                  onComplete={onComplete}
                  onPlaying={onPlaying}
                  ref={gameRef}
                  isComplete={isComplete}
                  toggle={toggle}
                  showPopupSuggestions={showPopupSuggestions}
                />
              </Sentry.ErrorBoundary>
              <OverviewQuestionSet
                questions={questions}
                activeQuestionIndex={activeQuestionIndex}
                onChangeQuestion={onChangeQuestion}
                hasAnsweredChecking={playMode === PLAY_MODE.PRACTICE}
                ref={QuestionsRef}
                onTimeUp={onTimeUp}
                limitedTime={limitedTime}
                isComplete={false}
              />
              <Footer
                isButtonNext={isButtonNext}
                onNextQuestion={onNextQuestion}
                handleCheckAnswer={handleCheckAnswer}
                onConfirmSubmit={onConfirmSubmit}
                isDone={isDone}
                isModeExam={playMode === PLAY_MODE.EXAM}
                isLastQuestion={activeQuestionIndex === questions?.length - 1}
                onRefresh={onRefresh}
                toggle={toggle}
              />
            </>
          ) : step === 2 ? (
            <ResultComplete
              questions={questions}
              onRefresh={onRefresh}
              timeEnd={timeEnd}
              timeStart={timeStart}
              limitedTime={limitedTime}
              setShowListAnswer={setShowListAnswer}
              setStep={setStep}
              result={result}
            />
          ) : (
            <ResultQuestion
              showListAnswer={showListAnswer}
              setShowListAnswer={setShowListAnswer}
              onRefresh={onRefresh}
              listQuestion={questions}
              limitedTime={limitedTime}
              result={result}
              originQuestions={originQuestions}
            />
          )}
        </Wrapper>
      </>

      <ReportError
        activeQuestionReportError={activeQuestionReportError}
        typeQuestion={playMode}
      />
    </>
  );
}

const Wrapper = styled.div`
  height: calc(100vh - 58px);
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 200px);
  }
`;
