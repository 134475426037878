import * as types from "../../constants/actionsType";

export const onDispatchShowLoading = (status) => {
  return {
    type: types.PA_SHOW_POPUP_LOADING,
    payload: { status },
  };
};



export const onDispatchSrcAudio = (data) => {
  return {
    type: types.PA_SET_SRC_AUDIO,
    data,
  };
};

export const onDispatchSetUserLocation = (data) => {
  return {
    type: types.PA_SET_USER_LOCATION,
    data,
  };
};

export const onDispatchResetApp = () => {
  return {
    type: types.PA_RESET_APP,
  };
};
