import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import classNames from "classnames";
import styled from "styled-components";
import { INNER_WIDTH } from "edu_lms/constants/type";
import { TYPE_DATA } from "../../constants";
import {
  GRAPH_TITLE,
  GRAPH_ANSWER,
  GRAPH_OBJECT,
  GRAPH_TYPE,
} from "../../constants/Graph";
import { ANSWER_COLOR } from "../../constants/styles";
import { Typography } from "../../components";
import InputCoordinates from "./InputCoordinates";
import { createBoard, getEquationGraph } from "../../helpers/graph";
import { getCoordinatesCorrectAnswer, getNumberOfCorrectPoints } from "../../helpers/Graph002";
import "./styles.scss";

const Graph002 = (
  {
    gameData,
    hideResultAnswer = false,
    selectedAnswerProp = null,
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const {
    graphType,
    coefficient,
    negativeCoorX,
    negativeCoorY,
    positiveCoorX,
    positiveCoorY,
  } = gameData;
  const [boardIdState, setBoardId] = useState(null);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  const [pointA, setPointA] = useState({ x: "", y: "" });
  const [pointB, setPointB] = useState({ x: "", y: "" });
  const [pointC, setPointC] = useState({ x: "", y: "" });

  const handleChangePointA = ({ x, y }) => {
    changePoints();
    setPointA({ x, y });
  };
  const handleChangePointB = ({ x, y }) => {
    changePoints();
    setPointB({ x, y });
  };
  const handleChangePointC = ({ x, y }) => {
    changePoints();
    setPointC({ x, y });
  };

  const changePoints = () => {
    if (isCheckedAnswer) {
      setPointA({ ...pointA, isCorrect: null  });
      setPointB({ ...pointB, isCorrect: null  });
      setPointC({ ...pointC, isCorrect: null  });
      setIsCheckedAnswer(false);
    }
  };

  const getConditionIsFilledAllInputs = () => {
    if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
      return pointA.x && pointA.y && pointB.x && pointB.y;
    }
    if (graphType === GRAPH_TYPE.QuadraticEquation) {
      return pointA.x && pointA.y && pointB.x && pointB.y && pointC.x && pointC.y;
    }
    return false;
  }

  useEffect(() => {
    if (!isCheckedAnswer) {
      const isFilledAllInputs = getConditionIsFilledAllInputs();
      onPlaying(isFilledAllInputs);
    }
  }, [pointA, pointB, pointC]);

  useEffect(() => {
    if (selectedAnswerProp) {
      const { pointA, pointB, pointC } = selectedAnswerProp;
      let isCorrect = pointA.isCorrect && pointB.isCorrect;
      setPointA(pointA);
      setPointB(pointB);
      if (graphType === GRAPH_TYPE.QuadraticEquation) {
        isCorrect = isCorrect && pointC.isCorrect;
        setPointC(pointC);
      }

      if (isCorrect && !hideResultAnswer) {
        drawGraphBoard();
      }
    }
  }, [selectedAnswerProp]);

  useEffect(() => {
    if (showCorrectAnswer) {
      const { xA, yA, xB, yB, xC, yC } = getCoordinatesCorrectAnswer(coefficient, graphType);
      setPointA({ x: xA, y: yA, isCorrect: true });
      setPointB({ x: xB, y: yB, isCorrect: true });
      setPointC({ x: xC, y: yC, isCorrect: true });
      drawGraphBoard();
    }
  }, [showCorrectAnswer]);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    const xA = Number(pointA.x);
    const yA = Number(pointA.y);
    const xB = Number(pointB.x);
    const yB = Number(pointB.y);
    const xC = Number(pointC.x);
    const yC = Number(pointC.y);

    let isCorrect = false;
    let selectedAnswer = {
      pointA: null,
      pointB: null,
      pointC: null,
    };
    const { a, b, c } = coefficient;
    if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
      const isPointA_OnGraph = yA === a * xA + b;
      setPointA({ ...pointA, isCorrect: isPointA_OnGraph });
      const isPointB_OnGraph = yB === a * xB + b;
      setPointB({ ...pointB, isCorrect: isPointB_OnGraph });
      isCorrect = isPointA_OnGraph && isPointB_OnGraph;
      selectedAnswer = {
        pointA: { ...pointA, isCorrect: isPointA_OnGraph },
        pointB: { ...pointB, isCorrect: isPointB_OnGraph },
      };
    }
    if (graphType === GRAPH_TYPE.QuadraticEquation) {
      const isPointA_OnGraph = yA === a * xA * xA + b * xA + c;
      setPointA({  ...pointA, isCorrect: isPointA_OnGraph });
      const isPointB_OnGraph = yB === a * xB * xB + b * xB + c;
      setPointB({  ...pointB, isCorrect: isPointB_OnGraph });
      const isPointC_OnGraph = yC === a * xC * xC + b * xC + c;
      setPointC({  ...pointC, isCorrect: isPointC_OnGraph });
      isCorrect = isPointA_OnGraph && isPointB_OnGraph && isPointC_OnGraph;
      selectedAnswer = {
        pointA: { ...pointA, isCorrect: isPointA_OnGraph },
        pointB: { ...pointB, isCorrect: isPointB_OnGraph },
        pointC: { ...pointC, isCorrect: isPointC_OnGraph },
      };

    }
    onComplete({ isCorrect, selectedAnswer });

    if (isCorrect && !hideResultAnswer) {
      drawGraphBoard();
    }
  };

  const drawGraphBoard = () => {
    const boardId = `graph002-board-${Math.random()}`;
    setBoardId(boardId);
  };

  // Draw graph board when have boardId
  useEffect(() => {
    if (!boardIdState) return;
    const board = createBoard({
      boardId: boardIdState,
      boundingBox: [negativeCoorX, positiveCoorY, positiveCoorX, negativeCoorY],
      showNavigation: true,
      enabledDrag: true,
    });

    // Create point input A, B, C
    if (pointA.isCorrect) {
      board.create(GRAPH_OBJECT.Point, [pointA.x, pointA.y], { name: "" });
    }
    if (pointB.isCorrect) {
      board.create(GRAPH_OBJECT.Point, [pointB.x, pointB.y], { name: "" });
    }
    if (pointC.isCorrect) {
      board.create(GRAPH_OBJECT.Point, [pointC.x, pointC.y], { name: "" });
    }

    const numberOfCorrectPoints = getNumberOfCorrectPoints(pointA, pointB, pointC);
    let pointA1 = pointA, pointB1 = pointB;
    if (numberOfCorrectPoints < 2) {
      const { xA, yA, xB, yB } = getCoordinatesCorrectAnswer(coefficient, graphType);
      pointB1 = board.create(GRAPH_OBJECT.Point, [xB, yB], { name: "" });
      if (numberOfCorrectPoints === 0) {
        pointA1 = board.create(GRAPH_OBJECT.Point, [xA, yA], { name: "" });
      }
    }

    if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
      const line = board.create(GRAPH_OBJECT.Line, [pointA1, pointB1], {
        strokeColor: ANSWER_COLOR.CORRECT,
      });
      line.isDraggable = false;
    }
    if (graphType === GRAPH_TYPE.QuadraticEquation) {
      const parabol = board.create(
        GRAPH_OBJECT.Parabol,
        (x) => {
          const { a, b, c } = coefficient;
          return a * x * x + b * x + c
        },
        {
          strokeColor: ANSWER_COLOR.CORRECT,
        }
      );
      parabol.isDraggable = false;
    }
  }, [boardIdState]);

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
    setPointA({ x: "", y: "" });
    setPointB({ x: "", y: "" });
    setPointC({ x: "", y: "" });
    setBoardId(null);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  return (
    <div
      className={classNames("graph-002-container position-relative", {
        "pl-5": window.innerWidth > INNER_WIDTH.MOBILE,
      })}
    >
      <div className="header-title monkey-f-header monkey-fz-20">
        <h2>{GRAPH_TITLE}</h2>
        <Typography
          typeText={[TYPE_DATA.LATEX]}
          text={getEquationGraph(graphType, coefficient)}
        />
      </div>
      <div
        className={classNames("content monkey-fz-24", {
          "has-board": !!boardIdState,
        })}
      >
        <h2>{GRAPH_ANSWER[graphType]}</h2>
        <InputCoordinates
          isView={isReadOnly || isViewOnly}
          label="A"
          point={{
            ...pointA,
            isCorrect: hideResultAnswer ? null : pointA.isCorrect,
          }}
          onChange={handleChangePointA}
        />
        <InputCoordinates
          isView={isReadOnly || isViewOnly}
          label="B"
          point={{
            ...pointB,
            isCorrect: hideResultAnswer ? null : pointB.isCorrect,
          }}
          onChange={handleChangePointB}
        />
        {graphType === GRAPH_TYPE.QuadraticEquation && (
          <InputCoordinates
            isView={isReadOnly || isViewOnly}
            label="C"
            point={{
              ...pointC,
              isCorrect: hideResultAnswer ? null : pointC.isCorrect,
            }}
            onChange={handleChangePointC}
          />
        )}
        <GraphBoard
          id={boardIdState}
          className={`graph-board__graph002 ${boardIdState ? "show-board" : ""}`}
        />
      </div>
    </div>
  );
};

export default forwardRef(Graph002);

const GraphBoard = styled.div`
  position: absolute !important;
  top: ${window.innerWidth > INNER_WIDTH.MOBILE ? "0" : "100%"};
  right: ${window.innerWidth > INNER_WIDTH.MOBILE ? "40px" : "0"};
  width: ${window.innerWidth > INNER_WIDTH.MOBILE ? "400px" : "100%"};
  height: ${window.innerWidth > INNER_WIDTH.MOBILE
    ? "400px"
    : `${window.innerWidth}px`};
  display: none;
  &.show-board {
    display: block;
  }
`;
