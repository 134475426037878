import { combineReducers } from "redux";
import app from "../modules/App/reducers";
import signInReducers from "../modules/SignIn/reducers";
import questionWareHouseSlideReducer from "../modules/QuestionSet/reducers";
import questionReducer from "../modules/Question/reducers";
import questionSetReducer from "../modules/QuestionSet/reducers";
import teachingReducer from "edu_lms_v2/modules/TeachingManager/reducers";
import mockTestReducers from "edu_lms_v2/modules/MockTest/reducers";

const rootReducer = combineReducers({
  app,
  signInReducers,
  questionWareHouseSlideReducer,
  questionReducer,
  questionSetReducer,
  teachingReducer,
  mockTestReducers,
});
export default rootReducer;
