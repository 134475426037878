import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import styles from "../teachingManager.module.scss";
import danger_img from "edu_lms_v2/assets/img/img-error.svg";

export default function PopupConfirmDelClassroom({
  id,
  show,
  setShow,
  title,
  onDelete,
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName={styles.modalDialog}
      backdrop="static"
    >
      <Modal.Header closeButton className="text-center border-0" />
      <Modal.Body className="p-4 text-center">
        <div className="img-wrapper my-3">
          <img src={danger_img} alt="Nội dung" className="d-inline" />
        </div>
        <p className="h4 font-weight-bold">{title}</p>
        <div className="justify-content-center mt-5 mb-3">
          <button
            className={`mr-3 ${styles.buttonOrange}`}
            onClick={handleClose}
          >
            {t("back")}
          </button>
          <button
            type="button"
            className={styles.buttonOrange}
            onClick={() => onDelete(id)}
          >
            {t("confirm")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
