import AxiosService from "edu_lms/services/axiosService";

const urlListBook = "v1/list-book";
const urlGrades = "v1/list-grade";
const urlSubjects = "list-subject";
const urlSupplementDocs = "v1/list-work-sheet";
const urlListSchoolLevel = "list-school-level?";
const urlDetailClassroom = "get-detail-classroom-by-id?";

export const getListBook = (
  gradeId,
  subjectId,
  typeBookId,
  dataSearch,
  currentPage,
  totalPerPage
) => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlListBook}`,
    {
      grade_id: gradeId,
      subject_id: subjectId,
      book_type_id: typeBookId || "1,3,5",
      title: dataSearch,
      page: currentPage || 1,
      limit: totalPerPage,
    }
  );
};

export const getListBookV4 = (gradeId, subjectId, bookTypeId = "1,3,5") => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlListBook}`,
    {
      grade_id: gradeId,
      subject_id: subjectId,
      book_type_id: bookTypeId,
    }
  );
};

export const getListTextBook = (gradeId, subjectId) => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlListBook}`,
    {
      grade_id: gradeId,
      subject_id: subjectId,
      book_type_id: 1,
    }
  );
};

export const getGrades = (gradeId) => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlGrades}`,
    {
      grade_id: gradeId,
    }
  );
};

export const getSubjects = (gradeId) => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlSubjects}`,
    {
      grade_id: gradeId,
    }
  );
};

export const getSupplementDocs = (
  gradeId,
  subjectId,
  category = "",
  typeDocument = ""
) => {
  const axiosService = new AxiosService();
  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlSupplementDocs}`,
    {
      category: category,
      grade_id: gradeId,
      subject_id: subjectId === "999" ? "" : subjectId,
      type_document: typeDocument,
      status: 1,
    }
  );
};

export const getSupplementDocsV3 = (gradeId, subjectId) => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlSupplementDocs}`,
    {
      category: 2,
      grade_id: gradeId,
      subject_id: subjectId,
      book_type_id: "1,3,5",
    }
  );
};

export const getLevelSchool = () => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${urlListSchoolLevel}`
  );
};

export const getDetailClassroom = (classroomId) => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL_QUESTION}${urlDetailClassroom}classroom_id=${classroomId}`
  );
};
