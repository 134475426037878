import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Typography, Image } from "edu_lms_v2/libraries/hoc10Game/components";
import { TYPE_DATA } from "edu_lms_v2/libraries/hoc10Game/constants";

const ContentAnswerNoIndex = ({
  answer,
  typeContentAnswer,
}) => {
  const { contentAnswer } = answer;

  return (
    <ContentAnswerWrapper className="content-answer__wrapper">
      {contentAnswer.text.contentText && (
        <div className="pl-1 w-100">
          <Typography
            text={String(contentAnswer.text.contentText)}
            typeText={contentAnswer.text.typeText}
            fontSize={contentAnswer.text.fontSize}
          />
        </div>
      )}
      {_.includes(typeContentAnswer, TYPE_DATA.IMAGE) && (
        <StyledImage src={contentAnswer.srcImage} />
      )}
    </ContentAnswerWrapper>
  );
};

export default ContentAnswerNoIndex;

const ContentAnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  text-align: left;
  height: 100%;
  width: 100%;
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  max-height: 250px;
  object-fit: contain;
`;
