import React, { useState } from "react";
import _ from "lodash";
import Select, { components } from "react-select";
import { TYPE_DATA } from "../../constants";
import { Typography, Video, Image, Audio, AudioType } from "../../components";
import { SPLIT_CHARS_ANSWER, INPUT_CHARS_REPLACE } from '../../constants/FIB';
import { COLOR } from "../../constants/styles";
import { getWidthOfText } from "../../helpers";
import { getBorderColor } from "../../helpers/FIB";
import styles from "./styles.module.scss";

const CustomOption = ({ children, innerRef, innerProps, selectProps }) => {
  return (
    <div className="select-custom-option" ref={innerRef} {...innerProps} >
      <Typography
        text={children}
        typeText={selectProps.typeText}
        fontSize={selectProps.fontSize}
      />
    </div>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <Typography
        text={children}
        typeText={props.selectProps.typeText}
        fontSize={props.selectProps.fontSize}
      />
    </components.SingleValue>
  );
};

const getPropertiesSelect = (selectionAreas = [], sentenceId, selectIndex) => {
  const select = selectionAreas.find(
    (selectArea) =>
      selectArea.sentenceId === sentenceId &&
      selectArea.selectIndex === selectIndex
  );
  if (!select) return null;
  return select;
};

const SELECT_OPTION_MAX_WIDTH = 400;
const WIDTH_OF_ARROW_DROPDOWN = 60;

const Sentence = ({ typeAnswer, selectionAreas, sentence, isCheckedAnswer, hideResultAnswer, onSelectAnswer, question = [] }) => {
  const [selectedText, setSelectedText] = useState({});

  const handleSelectAnswer = (e, selectIndex, sentenceId) => {
    setSelectedText({
      ...selectedText,
      [selectIndex]: e.label
    });
    onSelectAnswer({ ...e, selectIndex, sentenceId });
  };

  const { sentenceId, blankAnswers, contentSentence } = sentence;
  const sentenceItems = contentSentence.text.contentText.split(SPLIT_CHARS_ANSWER);
  let sentenceSelectIndex = 0;
  const isTypeText = _.includes(typeAnswer, TYPE_DATA.TEXT);
  const isTypeImg = _.includes(typeAnswer, TYPE_DATA.IMAGE);
  const isTypeAudio = _.includes(typeAnswer, TYPE_DATA.AUDIO);

  return (
    <div className="sentence__wrapper" style={{ textAlign: `${((isTypeText && isTypeImg) || (isTypeText && isTypeAudio)) && "center"}`}}>
      {/* ------------------------------------------ */}
      {_.includes(typeAnswer, TYPE_DATA.VIDEO) && (
        <div className="mb-3">
          <Video src={sentence.contentSentence.srcVideo} />
        </div>
      )}
      {/* ------------------------------------------ */}
      {(_.includes(typeAnswer, TYPE_DATA.IMAGE) ||
        (!typeAnswer && sentence.contentSentence.srcImage)) && (
        <div className="mb-3" style={{maxWidth: "300px", margin: "0 auto"}}>
          <Image
            src={sentence.contentSentence.srcImage}
            alt="FIB_003-sentence-image"
            className="w-100"
          />
        </div>
      )}
      {/* ------------------------------------------ */}
      <div className="d-flex">
        {contentSentence.srcAudio && (
            <div className="pl-5 position-relative">
              <div className={`position-absolute ${styles.audio}`}>
                <Audio variant={AudioType.Primary} src={contentSentence.srcAudio} autoPlay={false} />
              </div>
            </div>
          )}
        {sentenceItems.map((sentenceItem, index) => {
          const selectIndex = sentenceSelectIndex;
          if (sentenceItem === INPUT_CHARS_REPLACE) {
            const selectOptions = blankAnswers[sentenceSelectIndex].map(
              (option) => ({
                ...option,
                label: option.answerText,
              })
            );
            sentenceSelectIndex++;

            return (
              <div
                className={`form-group position-relative ${styles.select}`}
                key={index}
              >
                <Select
                  components={{ Option: CustomOption, SingleValue }}
                  options={selectOptions}
                  onChange={(e) => handleSelectAnswer(e, selectIndex, sentenceId)}
                  value={getPropertiesSelect(
                    selectionAreas,
                    sentenceId,
                    selectIndex
                  )}
                  styles={customStyles({
                    isCorrect: hideResultAnswer
                      ? null
                      : getPropertiesSelect(
                          selectionAreas,
                          sentenceId,
                          selectIndex
                        )?.isCorrect,
                    isCheckedAnswer,
                    selectOptions,
                    fontSize: contentSentence.text.fontSize,
                  })}
                  classNamePrefix="select"
                  placeholder="?"
                  typeText={contentSentence.text.typeText}
                  fontSize={contentSentence.text.fontSize}
                />
              </div>
            );
          }

          return (
            <Typography
              key={index}
              text={sentenceItem}
              typeText={contentSentence.text.typeText}
              fontSize={contentSentence.text.fontSize}
            />
          );
        })}
      </div>
      {/* ------------------------------------------ */}
      {hasLongTextInSentence(selectedText, contentSentence.text.fontSize) && (
        <>
          <br />
          <div className={styles["your-choice"]}>
            <span className={styles["label"]}>Lựa chọn của bạn:{" "}</span>
            {Object.values(selectedText).map((text, textIdx) => (
              <>
                <Typography
                  key={textIdx}
                  text={text}
                  typeText={contentSentence.text.typeText}
                  fontSize={20}
                />
                {textIdx !== Object.values(selectedText).length - 1 && ", "}
              </>
            ))}
          </div>
        </>
      )}
      {/* ------------------------------------------ */}
    </div>
  );
};

export default Sentence;

const hasLongTextInSentence = (selectedText, fontSize) => {
  return Object.values(selectedText).some(text => {
    const widthText = getWidthOfText(text, fontSize, "quicksand-medium");
    if (widthText + WIDTH_OF_ARROW_DROPDOWN > SELECT_OPTION_MAX_WIDTH) return true;
    return false;
  });
};

const getWidthOfSelect = (selectOptions, fontSize) => {
  let maxLengthText = 0;
  selectOptions.forEach((option) => {
    if (option.answerText.length > maxLengthText) {
      maxLengthText = option.answerText.length;
    }
  });
  const elementHasMaxLengthText = selectOptions.find(option => option.answerText.length === maxLengthText);
  let width = getWidthOfText(elementHasMaxLengthText.answerText, fontSize, "quicksand-medium");
  width = width + WIDTH_OF_ARROW_DROPDOWN
  if (width > SELECT_OPTION_MAX_WIDTH) {
    width = SELECT_OPTION_MAX_WIDTH;
  }
  return width;
};

const customStyles = ({
  isCorrect,
  isCheckedAnswer = false,
  selectOptions,
  fontSize,
}) => {
  const width = getWidthOfSelect(selectOptions, fontSize);

  return {
    menu: (provided) => ({
      ...provided,
      textAlign: "center",
    }),
    control: (base) => ({
      ...base,
      height: 45,
      minHeight: 35,
      width,
      border: `2px solid ${
        isCheckedAnswer ? getBorderColor(isCorrect) : COLOR.Default
      } !important`,
      cursor: "pointer",
      verticalAlign: "middle",
    }),
    valueContainer: (base) => ({
      ...base,
      justifyContent: "center",
    }),
    singleValue: (base) => ({
      ...base,
      borderRadius: 5,
      display: "flex",
      margin: "auto",
      top: "45%",
    }),
    input: () => ({
      pointerEvents: "none",
    }),
    placeholder: () => ({
      margin: "auto",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      height: "100%",
      paddingTop: "15px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      visibility: "hidden",
      paddingBottom: "10%",
      height: "85%",
    }),
  };
};