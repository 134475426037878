import React, { forwardRef, useImperativeHandle, useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Audio, AudioType, Image } from "../../../components";
import { TYPE_DATA } from "../../../constants";
import { IMAGE_WIDTH, SPLIT_ANSWER_RESULT } from "../../../constants/FIB";
import InputPositionV1 from "./InputPositionV1";
import { getCorrectWrongInputAnswers, getColorInputAnswer, getDefaultWidthInputByFontSize } from "../../../helpers/FIB_BG";
import "../styles.scss";

const FIB_BG_V1 = (
  {
    gameData,
    hideResultAnswer = false,
    inputtedAreasProp = null,
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { question, answer, fontSize } = gameData;
  const [resultAnswers, setResultAnswers] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [widthForm, setWidthForm] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    mode: "onChange",
  });

  const fibWrapperRef = useCallback((node) => {
    if (!node) return;
    let widthWrapper = node.getBoundingClientRect().width;
    if (widthWrapper > IMAGE_WIDTH.WebView) {
      widthWrapper = IMAGE_WIDTH.WebView;
    }
    setWidthForm(widthWrapper);
  }, []);

  /**---------- Show inputted answers result ----------**/
  useEffect(() => {
    if (inputtedAreasProp) {
      Object.keys(inputtedAreasProp).forEach((key) => {
        setValue(key, inputtedAreasProp[key]);
      });
      const resultInputAnswers = getCorrectWrongInputAnswers(
        answer.answers,
        Object.values(inputtedAreasProp)
      );
      setResultAnswers(resultInputAnswers);
    }
  }, [inputtedAreasProp]);

  /**---------- Show all correct answers result ----------**/
  useEffect(() => {
    if (showCorrectAnswer) {
      const correctInputAnswers = answer.answers.map((answerItem) => {
        return answerItem.answer_result.split(SPLIT_ANSWER_RESULT)[0];
      });
      correctInputAnswers.forEach((correctAnswer, index) => {
        setValue(`${index}`, correctAnswer);
      });
      const resultInputAnswers = getCorrectWrongInputAnswers(
        answer.answers,
        correctInputAnswers
      );
      setResultAnswers(resultInputAnswers);
    }
  }, [showCorrectAnswer]);

  useImperativeHandle(ref, () => ({
    handleCheck: () => {
      handleSubmit(handleCheckAnswer)();
    },
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));
  
  const handleCheckAnswer = (dataForm) => {
    setIsCheckedAnswer(true);
  
    const resultInputAnswers = getCorrectWrongInputAnswers(answer.answers, Object.values(dataForm));
    setResultAnswers(resultInputAnswers);

    const totalCorrectAnswer = resultInputAnswers.filter(resultAnswer => resultAnswer.isCorrect).length;
    const isCorrect = totalCorrectAnswer === resultInputAnswers.length;
    onComplete({ isCorrect, totalCorrectAnswer, inputtedAreas: dataForm });
  };

  const handleResetAnswer = () => {
    reset();
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
    setResultAnswers([]);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const onChangeValue = (e) => {
    if (e.target.value.length === 0) {
      e.target.style.width =  `${getDefaultWidthInputByFontSize(fontSize)}px`;
    }
    if (e.target.value.length >= 1) {
      e.target.style.width = e.target.offsetWidth + 15 + "px";
    }

    if (isCheckedAnswer) {
      setIsCheckedAnswer(false);
      setResultAnswers([]);
    }
    const isFilledAllInputs = Object.keys(dirtyFields).length === question.contentQuestion.positions.length;
    onPlaying(isFilledAllInputs);
  };

  return (
    <div ref={fibWrapperRef} className="FIB_BG__wrapper">
      {/* -------------------------------------------- */}
      {question.contentQuestion.srcAudio && (
        <div className="question-audio__wrapper">
          <Audio
            variant={AudioType.Secondary}
            src={question.contentQuestion.srcAudio}
          />
        </div>
      )}
      {/* -------------------------------------------- */}
      {question.typeQuestion.includes(TYPE_DATA.IMAGE) && (
        <div className="image__wrapper" style={{ width: `${widthForm}px` }}>
          <Image
            src={question.contentQuestion.srcImage}
            alt="FIB_BG__background-image"
            className="w-100"
          />
          <form
            className={`form-inputs ${
              isReadOnly || isViewOnly ? "view-only" : ""
            }`}
          >
            {question.contentQuestion.positions.map((position, index) => {
              return (
                <InputPositionV1
                  key={index}
                  name={index}
                  position={position}
                  fontSize={fontSize}
                  widthForm={widthForm}
                  color={getColorInputAnswer(
                    hideResultAnswer ? null : resultAnswers[index]?.isCorrect
                  )}
                  ref={register()}
                  onChange={onChangeValue}
                />
              );
            })}
          </form>
        </div>
      )}
      {/* -------------------------------------------- */}
    </div>
  );
};

export default forwardRef(FIB_BG_V1);
