import React, { useState } from "react";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";
import _ from "lodash";
import { INPUT_CHARS_REPLACE } from "../../constants/FIB";
import { TYPE_DATA } from "../../constants";
import { htmlDecode } from "../../helpers";
import { getBorderColor } from "../../helpers/FIB";
import { Typography, Video, Image, Audio, AudioType } from "../../components";
import { URL_IMAGE_QUESTION } from "edu_lms/constants/type";

const ContentAnswer = ({
  answer,
  inputAreas = [],
  isView = false,
  hideResultAnswer = false,
  onChangeInput = () => {},
}) => {
  const { answers, typeAnswer, fontSize, typeText, numberOfAnswersInRow } =
    answer;
  const [audioAnswer, setAudioAnswer] = useState({ answerId: "", src: "" });
  const _typeImage = _.includes(typeAnswer, TYPE_DATA.IMAGE);
  const _typeText = _.includes(typeAnswer, TYPE_DATA.TEXT);
  const _typeAudio = _.includes(typeAnswer, TYPE_DATA.AUDIO);
  const _typeVideo= _.includes(typeAnswer, TYPE_DATA.VIDEO)
  
  const handleChangeInput = (e, lineAnswerId, answerIndex) => {
    const { value } = e.target;
    onChangeInput({ lineAnswerId, answerIndex, value: htmlDecode(value) });
  };

  const getPropertiesInput = (lineAnswerId, answerIndex) => {
    const input = inputAreas.find(
      (inputArea) =>
        inputArea.lineAnswerId === lineAnswerId &&
        inputArea.answerIndex === answerIndex
    );
    if (!input) {
      return { isCorrect: null, value: "" };
    }
    return input;
  };

  const handleClickAudio = (answerId, srcAudio) => {
    setAudioAnswer({ answerId, src: srcAudio });
  };
  const handleResetAudioAnswer = () => {
    setAudioAnswer({ answerId: "", src: "" });
  };

  return (
    <ContentAnswerWrapper
      columns={numberOfAnswersInRow}
      className="quicksand-medium monkey-color-black"
    >
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      {answers.map((answerLine, lineIndex) => {
        const isPlayingAudio = audioAnswer.answerId === answerLine.answerId;
        const hasMedia =
          _.intersection(typeAnswer, [TYPE_DATA.IMAGE, TYPE_DATA.VIDEO])
            .length > 0;

        return (
          <div
            key={lineIndex}
            className={`answer-items__wrapper ${hasMedia ? "hasMedia" : ""}`}
          >
            {_typeVideo && (
              <div className="mb-3">
                <Video src={answerLine.contentAnswer.srcVideo} />
              </div>
            )}
            {_typeAudio && (
              <ButtonAudio
                className="mr-3"
                onClick={() =>
                  handleClickAudio(
                    answerLine.answerId,
                    answerLine.contentAnswer.srcAudio
                  )
                }
              >
                <i
                  style={{ color: isPlayingAudio ? "red" : "dodgerblue" }}
                  className={
                    isPlayingAudio ? "fa fa-pause-circle" : "fa fa-play-circle"
                  }
                />
              </ButtonAudio>
            )}
            {_typeImage && (
              <div className="mb-3 d-flex justify-content-center mt-2">
                <img
                  style={{ maxHeight: "160px", borderRadius: "7px" }}
                  src={`${URL_IMAGE_QUESTION}${answerLine.contentAnswer.srcImage}`}
                  alt="FIB_001-answer-image"
                />
              </div>
            )}
            <div
              className={`${
                ((_typeImage && _typeText) || (_typeAudio && _typeText)) &&
                "d-flex justify-content-center align-items-center"
              } `}
            >
              <div className="game_fib001">
                {answerLine.answerItems.map((answerItem, index) => {
                  if (answerItem === INPUT_CHARS_REPLACE) {
                    const focusInput = getPropertiesInput(
                      answerLine.answerId,
                      index
                    );

                    return (
                      <ContentEditable
                        key={index}
                        disabled={isView}
                        autoComplete="off"
                        style={{
                          fontSize: `${parseInt(fontSize)}px`,
                          borderColor: getBorderColor(
                            hideResultAnswer ? null : focusInput.isCorrect
                          ),
                        }}
                        className="form-control content-editable"
                        html={focusInput.value}
                        placeholder="?"
                        onChange={(e) =>
                          handleChangeInput(e, answerLine.answerId, index)
                        }
                      />
                    );
                  }

                  return (
                    <Typography
                      key={index}
                      text={answerItem}
                      typeText={typeText}
                      fontSize={fontSize}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      <Audio
        src={audioAnswer.src}
        variant={AudioType.Secondary}
        autoPlay
        onEnded={handleResetAudioAnswer}
        className="d-none"
      />
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
    </ContentAnswerWrapper>
  );
};

export default ContentAnswer;

const ContentAnswerWrapper = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 1}, 1fr);
  grid-gap: 1rem;
`;

const ButtonAudio = styled.button`
  display: block;
  border: none;
  background: none;

  i {
    font-size: 40px;
  }
`;
