import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useHistory } from "react-router";
import * as PATH from "edu_lms/constants/path";
import * as types from "edu_lms/constants/type";
import styled from "styled-components";
import bookApprove from "edu_lms/assets/images/approve.png";
import {
  getListClassroomById,
  postAssignHomeWork,
} from "edu_lms/services/teachingManager";
import { onResultUserInfo } from "edu_lms/modules/selection";
import PopUpAssignHomeWork from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignHomeWork";
import PopUpAssignSuccess from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignSuccess";

const Item = ({ item, isStyleUI = true }) => {
  const { t } = useTranslation();

  const [question, setQuestion] = useState("");
  const [classroom, setClassroom] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [checked, setChecked] = useState([]);
  const [listClassroom, setListClassroom] = useState([]);
  const [questionSetId, setQuestionSetId] = useState();
  const [showModalAssignSuccess, setShowModalAssignSuccess] = useState(false);

  const userInfo = onResultUserInfo();
  const jobId = userInfo?.job_id;

  const history = useHistory();
  const onReadingSlideLibrary = (id) => {
    history.push(`${PATH.ROUTE_PATH_QUESTION}/${id}`);
  };
  const onDoingExercise = (id) => {
    history.push(`${PATH.ROUTE_PATH_EXERCISE}/${id}`);
  };

  const onSubmit = (data) => {
    const dataForm = {
      deadline: dayjs(data.time_finish).unix(),
      question_set_id: questionSetId,
      class_room_ids: checked.toString(),
    };
    postAssignHomeWork(dataForm)
      .then((res) => {
        if (res.data.status === "success") {
          setClassroom(res.data.data.class_room);
          setQuestion(res.data.data.question_set);
          setShowModalAssignSuccess(true);
          setShowPopup(false);
        }
      })
      .finally(() => {
        setChecked([]);
      });
  };

  const showGradeName = (gradeId) => {
    const gradeName = types.gradeOptions.find((item) => item.value === gradeId);
    return gradeName?.name;
  };

  const getListClassroom = () => {
    const data = {
      title: "",
      roleId: `${types.ROLE_CREATED_ROOM},${types.ROLE_TEACHER}`,
      langId: types.TYPE_VIETNAMESE,
      appId: types.APP_ID,
    };
    getListClassroomById(data)
      .then((res) => {
        if (res.data.status === "success") {
          const list = res.data.data.list;
          const nodes = list.map((item) => ({
            value: item.class_room_id,
            label: showGradeName(item.grade_id)
              ? `${showGradeName(item.grade_id)}${item.title}`
              : item.title,
          }));
          setListClassroom(nodes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={`${isStyleUI ? "col-12" : ""}`}>
      <IntroQuestion className="mb-4">
        <div className="image-container">
          <div
            className="content-video monkey-f-medium monkey-color-gray monkey-box-shadow p-3 monkey-bg-white br-16"
            style={{ height: 180 }}
          >
            <div
              className="monkey-fz-16  d-flex align-items-center"
              style={{ height: "30px" }}
            >
              {item.grade_name} | Bài tập | Chương 1
            </div>

            <hr />
            <div className="d-flex">
              <LabelGrade className="font-weight-bold monkey-fz-20">
                {item.title}
              </LabelGrade>
            </div>
            <ListButton className="d-flex justify-content-end">
              <button
                className="button-opacity btn d-flex justify-content-between align-items-center mr-2 monkey-bg-question"
                onClick={() => {
                  onDoingExercise(item.id);
                }}
              >
                <img
                  src={bookApprove}
                  alt=""
                  style={{ width: 20, marginRight: 5 }}
                />
                <span className="text-white">{t("practice")}</span>
              </button>
              {jobId === types.JOB_TEACHER && (
                <button
                  onClick={() => {
                    setQuestionSetId(item.id);
                    setShowPopup(true);
                    getListClassroom();
                  }}
                  className="button-opacity text-white monkey-bg-orange px-md-3 px-2 rounded mr-2"
                >
                  {t("assign")}
                </button>
              )}
              {item.created_cms && (
                <button
                  className="btn mr-2 border-orange"
                  onClick={() => {
                    onReadingSlideLibrary(item.id);
                  }}
                >
                  <i className="fa fa-pencil monkey-color-orange" />
                </button>
              )}
              <button className="btn border-orange">
                <i className="fa fa-share-alt monkey-color-orange" />
              </button>
            </ListButton>
          </div>
        </div>
      </IntroQuestion>
      <PopUpAssignHomeWork
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        listClassroom={listClassroom}
        onSubmit={onSubmit}
        checked={checked}
        setChecked={setChecked}
      />
      <PopUpAssignSuccess
        question={question}
        classRoom={classroom}
        showGradeName={showGradeName}
        open={showModalAssignSuccess}
        onClose={() => setShowModalAssignSuccess(false)}
      />
    </div>
  );
};

const IntroQuestion = styled.div`
  .br-16 {
    border-radius: 16px;
  }
`;
const LabelGrade = styled.div`
  color: #212529;
  width: 100%;
`;

const ListButton = styled.div`
  .border-orange {
    border: 1px solid #ff7707;
  }

  .button-opacity:hover {
    opacity: 0.8;
  }
`;

export default Item;
