import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import "bootstrap/dist/js/bootstrap.min.js";
import "edu_lms_v2/assets/css/bootstrap.min.scss";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";

import "react-toastify/dist/ReactToastify.css";
import "edu_lms_v2/assets/css/style.scss";
import "edu_lms_v2/hoc10.scss";

import App from "edu_lms/modules/App";
import store from "edu_lms/redux/configureStore";
import reportWebVitals from "reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { isSSR } from "edu_lms_v2/utils";
import 'edu_lms_v2/i18n/i18n'
const rootElement = document.getElementById("root");

const Hoc10 = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

Sentry.init({
  // dsn: "https://dec7e9954abb4f30ae93423cde3cb9f7@o293835.ingest.sentry.io/6610281",
  dsn: "https://2e6af3d3467388bb07334f0848301ceb@sentry.monkeyenglish.net/39",
  integrations: [new BrowserTracing()],
  enabled:
    !isSSR &&
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_ENVIROMENT === "production",
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
});

render(<Hoc10 />, rootElement);

reportWebVitals();
