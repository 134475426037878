import ContentEditable from "react-contenteditable";
import { onChangeColor } from "../../helpers/FindCorrectMistakes";
import { formatText, onColorBorder } from "../../helpers/FindCorrectMistakes";

export default function FindCorrectMistakesWrapper({
  data,
  gameConfig = [],
  onActiveText,
  handleSubmit,
  setValue,
  getValues,
  isReadOnly,
  hideResultAnswer,
  onPlaying
}) {
  const { font_size } = gameConfig.game_config;
  const onChangeValue = (e, name) => {
    setValue(name, e?.target?.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    onPlaying(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="find-words-body p-3 quicksand-medium"
        style={{ pointerEvents: `${isReadOnly && "none"}` }}
      >
        {data?.answers.map((item, index) => {
          return (
            <div
              key={index}
              className="d-inline-flex justify-content-between w-100 pt-2 pb-2 game-finding-correct-answer"
            >
              <div className="d-flex flex-wrap">
                {item?.answer_text.map((itemText, indexText) => {
                  return (
                    <div key={indexText}>
                      <span
                        className={`${onChangeColor(
                          itemText.status,
                          hideResultAnswer
                        )} pl-1 pr-1 cursor rounded-pill hvr-registration`}
                        style={{
                          fontSize: font_size ? `${font_size}px` : "",
                          borderStyle: "solid",
                          lineHeight: "1.5",
                          borderWidth: "2px",
                        }}
                        onClick={() => onActiveText(itemText, item.answer_id)}
                      >
                        {formatText(itemText.value)}
                      </span>
                      <span>&nbsp;</span>
                    </div>
                  );
                })}
              </div>
              {item.input.show && (
                <ContentEditable
                  index={item.answer_id}
                  autoComplete="off"
                  style={{
                    fontSize: font_size ? `${font_size}px` : "",
                    width: "auto",
                    border: `1px solid ${onColorBorder(
                      item.input.status,
                      hideResultAnswer
                    )}`,
                    borderRadius: "5px",
                    maxWidth: "30%",
                  }}
                  className="form-control h-100"
                  html={getValues(item.answer_id) || item.input.text || ""}
                  name={item.answer_id}
                  disabled={isReadOnly}
                  onChange={(e) => onChangeValue(e, item.answer_id)}
                />
              )}
            </div>
          );
        })}
      </div>
    </form>
  );
}
