import React, { useEffect, useState, Fragment } from "react";
import BoxMatch from "./BoxMatch";
import { TYPE_DISPLAY, BOX_TYPE } from "../../constants/MAT_001";
import { getClassNameItemsMatchWrapper } from "../../helpers/MAT_001";
import { P } from "./styles";

const QuestionContent = ({
  questionsMatch,
  typeDisplay,
  typeGame,
  typeText,
  fontSizeContent,
  columnTitleLeft,
  matchedCouplePoints = [],
  isCheckedAnswer = false,
  hideResultAnswer = false,
  isReadOnly = false,
  onSelectQuestion = () => {},
}) => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleClickBox = (e, questionId) => {
    setActiveQuestion(questionId);
    onSelectQuestion(e, questionId);
  };

  useEffect(() => {
    if (isCheckedAnswer) {
      setActiveQuestion(null);
    }
  }, [isCheckedAnswer]);

  const correctMatchedCouplePoints = matchedCouplePoints.filter(couple => couple.isCorrect);

  return (
    <div className={`d-flex justify-content-center question-wrapper quicksand-medium ${getClassNameItemsMatchWrapper(typeDisplay)}`}>
      {questionsMatch.map((question, index) => {
        const isCorrect = isCheckedAnswer ? correctMatchedCouplePoints.some(couple => couple.questionId === question.id) : null;
        return (
          <Fragment key={index}>
            {/* {typeDisplay === TYPE_DISPLAY.Vertical && index === 0 && (
              <P>{columnTitleLeft}</P>
            )} */}
            <BoxMatch
              typeDisplay={typeDisplay}
              typeGame={typeGame}
              boxType={BOX_TYPE.Question}
              boxContent={question}
              typeText={typeText}
              fontSizeContent={fontSizeContent}
              isActive={activeQuestion === question.id}
              isCorrect={hideResultAnswer ? null : isCorrect}
              disabled={isReadOnly}
              onClickBox={(e) => handleClickBox(e, question.id)}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default QuestionContent;
