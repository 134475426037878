import _ from "lodash";
import { COLOR_RED, COLOR_GRAY, COLOR_BLUE } from "edu_lms/constants/type";

export const TYPE_CHECK_RESULT = {
  default: 0,
  choose: 1,
  correct: 2,
  wrong: 3,
  correctQuestion: 4,
};

export const TYPE_RESULT_INPUT = {
  correct: 1,
  wrong: 2,
};

function onResultAnswerIcon(data, dataIcons, type = 2, id = "answer_id") {
  const result = data.map((item) => {
    const resultFilterIcon = dataIcons.filter(
      (icon) => icon.icon_id == item[`${id}`]
    )[0];
    return { ...item, ...resultFilterIcon, status: type === 1 ? 0 : false };
  });
  return result;
}

function onResultIcon(id, iconList) {
  let result = {};
  if (iconList) {
    result = iconList.filter((item) => item?.icon_id == id)[0];
  }
  return result;
}

export function onChangeColor(status, hideResultAnswer) {
  let result = "monkey-color-black monkey-bc-transparent";
  const defaultChoose = "monkey-color-blue monkey-bc-blue";
  switch (Number(status)) {
    case TYPE_CHECK_RESULT.choose:
      return (result = defaultChoose);
    case TYPE_CHECK_RESULT.correct:
      return (result = !hideResultAnswer
        ? "monkey-bg-green monkey-bc-green monkey-color-white"
        : defaultChoose);
    case TYPE_CHECK_RESULT.wrong:
      return (result = !hideResultAnswer
        ? "monkey-bg-red monkey-bc-red monkey-color-white"
        : defaultChoose);
    default:
      return result;
  }
}

export function onColorBorder(status, hideResultAnswer) {
  if (hideResultAnswer) return COLOR_GRAY;

  switch (status) {
    case TYPE_RESULT_INPUT.correct:
      return COLOR_BLUE;
    case TYPE_RESULT_INPUT.wrong:
      return COLOR_RED;
    default:
      return COLOR_GRAY;
  }
}

export const TypeQuestion = {
  IMAGE: "image",
  AUDIO: "audio",
  TEXT: "text",
  VIDEO: "video",
  IMG_IMG: 1,
  IMG_TEXT_TEXT: 2,
  IMG_TEXT_IMAGE_TEXT: 3,
  TEXT_TEXT: 4,
  IMAGE_TEXT: 5,
};

export function formatACTGameFindCorrectMistakes(data = []) {
  const { game_config, selectedAnswers = null } = data;
  const dataConfig = game_config.data.map((dataAnswer) => {
    const answersAddIcon = onResultAnswerIcon(
      dataAnswer?.answers,
      data?.icon_list[0]?.icons,
      1
    );
    const dataConvert = answersAddIcon.map((item) => {
      const result = _.split(item.answer_text, "#");
      const answerText = result.map((item, index) => ({
        value: item,
        id: `${item}_${index}`,
        status: 0,
      }));
      const answerResult = item?.answer_result
        ? _.split(item.answer_result, "#")
        : [];
      return {
        ...item,
        answer_text: answerText,
        answer_result: answerResult,
        input: {
          show: false,
          text: "",
          status: 0,
        },
      };
    });
    const question = onResultIcon(
      dataAnswer?.question,
      data?.icon_list[0]?.icons
    );

    return {
      ...dataAnswer,
      answers: dataConvert,
      question: question,
      game_config: data.game_config,
      selectedAnswers,
    };
  });
  return dataConfig;
}

export const formatText = (str) => {
  return str
    ?.replace("|1|", "")
    ?.replace("|2|", "")
    ?.replace("|3|", "")
    ?.replace("|4|", "")
    ?.replace("|5|", "")
    ?.replace("|6|", "")
    ?.replace("|7|", "")
    ?.replace("|8|", "")
    ?.replace("|9|", "")
    ?.replace("|10|", "");
};

export function formatResultGame(dataGame = []) {
  const convertDataAnswer = dataGame.map((dataACT) => {
    const { answers } = dataACT;
    const dataResult = answers.map((item) => {
      const answer_text = item.answer_text.map((questionText) => {
        if (_.includes(item.answer_result, questionText.value)) {
          return { ...questionText, status: TYPE_CHECK_RESULT.correct };
        }
        return { ...questionText };
      });
      const firstAnswerText = item.answer_correct.split("|") || [];
      const input = {
        show: true,
        text: `<span style=\\"font-family: consolas, &quot;lucida console&quot;, &quot;courier new&quot;, monospace; font-size: 12px; white-space: pre;\\">${
          firstAnswerText[0] || ""
        }</span>`,
        status: TYPE_RESULT_INPUT.correct,
      };
      return { ...item, input, answer_text };
    });
    return { ...dataACT, answers: dataResult };
  });
  return convertDataAnswer;
}
