import store from "../redux/configureStore";
import { onDispatchResetApp } from "../modules/App/actions";

export const IMAGE_DEFAULT = `${process.env.REACT_APP_MEDIA_URL_APP}upload/web/background-web/default-image.jpg`;

export const cleanLocalStorage = () => {
  const guestId = localStorage.getItem("guest_id");
  localStorage.clear();

  if (guestId) {
    localStorage.setItem("guest_id", guestId);
  }

  // Reset redux state
  store.dispatch(onDispatchResetApp());
};
