import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { URL_IMAGE_QUESTION } from "edu_lms/constants/type";
import QuestionMatrix from "./QuestionMatrix";
import { TypeGameMultipleChoice } from "..";

const Top = ({
  listResult,
  wordsCorrect,
  fontSizeResult,
  typeResult,
  girds,
  fontSizeAnswer,
  fontSizeAnswerPx,
  isFirework,
  onChooseCell,
  showCorrectAnswer,
}) => {
  return (
    <div>
      {question(listResult, wordsCorrect, fontSizeResult, typeResult)}
      <div className="row d-flex justify-content-center mt-3">
        <QuestionMatrix
          girds={girds}
          onChooseCell={onChooseCell}
          fontSizeAnswer={fontSizeAnswer}
          fontSizeAnswerPx={fontSizeAnswerPx}
          isFirework={isFirework}
          showCorrectAnswer={showCorrectAnswer}
        />
      </div>
    </div>
  );
};

const question = (listResult, wordsCorrect, fontSizeResult, typeResult) => {
  return (
    <div className="row justify-content-center mt-3">
      {listResult.map((result, index) =>
        wordsCorrect.includes(result.text.replace(" ", "")) ? (
          <LiStyle
            key={index}
            style={{
              textDecorationLine: "line-through",
              textDecorationThickness: "3px",
              fontSize: `${fontSizeResult ? fontSizeResult : "18px"}`,
            }}
          >
            {_.includes(typeResult, TypeGameMultipleChoice.TEXT) && result.text}
            {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) && (
              <img
                style={{ opacity: 0.3 }}
                src={`${URL_IMAGE_QUESTION}${result.image}`}
                width="100px"
                height="auto"
                alt=""
              />
            )}
          </LiStyle>
        ) : (
          <LiStyle
            key={index}
            style={{
              marginBottom: "20px",
              fontSize: `${fontSizeResult ? fontSizeResult : "18px"}`,
            }}
          >
            {_.includes(typeResult, TypeGameMultipleChoice.TEXT) && result.text}
            {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) && (
              <img
                src={`${URL_IMAGE_QUESTION}${result.image}`}
                width="100px"
                height="auto"
                alt=""
              />
            )}
          </LiStyle>
        )
      )}
    </div>
  );
};

const LiStyle = styled.li`
  margin-right: 25px;
  color: #14c6f4;
  font-size: 25px;
  list-style-type: none;
`;

export default Top;
