import { useState, useEffect } from "react";
import styles from "./ListExerciseResultDetail.module.scss";
import iconBack from "edu_lms_v2/assets/img/back1.svg";
import { useHistory } from "react-router-dom";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { getListExerciseResult } from "edu_lms/services/teachingManager";
import { displayDuration, PLAY_MODE } from "edu_lms/constants/type";
import dayjs from "edu_lms/libs/dayjs";
import * as PATH from "edu_lms/constants/path";
import Loading from "edu_lms_v2/components/Loading";
import { CSVLink } from "react-csv";
import { HEADERS } from "../../constant";

export default function ListExerciseResultDetail(props) {
  useScrollToTop();
  const history = useHistory();
  const [listExercise, setListExercise] = useState([]);
  const [typeExercise, setTypeExercise] = useState();
  const [deadline, setDeadline] = useState(0);
  const [dataExport, setDataExport] = useState([]);
  const questionSetId = props.match.params.questionSetId;
  const classroomId = props.match.params.idClassroom;
  useEffect(() => {
    getListExerciseResult({
      classroomId,
      questionSetId,
    })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.data;
          const _dataExport = handleDataExport(
            data.result_question,
            data.deadline
          );
          setListExercise(data.result_question);
          setDataExport(_dataExport);
          setDeadline(data.deadline);
          setTypeExercise(data.type);
        }
      })
      .catch((error) => console.log(error));
  }, [questionSetId, classroomId]);

  const handleDataExport = (dataExport, deadline) => {
    dataExport = dataExport.map((item, index) => {
      const timeEnd = dayjs.unix(item.time_end);
      const timeStart = dayjs.unix(item.time_start);
      const durationTime = dayjs.duration(timeEnd.diff(timeStart));

      return {
        stt: index + 1,
        name: item.user_name,
        time: displayDuration(durationTime),
        status: deadline > item.time_end ? "Đúng hạn" : "Quá hạn",
        score: ` ${item.total_correct}/${item.total_question}`,
      };
    });
    return dataExport;
  };

  const onClickBack = () => {
    history.push(`${PATH.ROUTE_PATH_CLASSROOM}${classroomId}`);
  };

  const onClickHistory = (userClassroomId) => {
    history.push(`${window.location.pathname}/${userClassroomId}`);
  };

  const handleShowScore = (infor) => {
    if (typeExercise === PLAY_MODE.PRACTICE) {
      return (
        <td className="position-relative">
          <span className={styles.total}>
            {infor.total_correct} /{infor.total_question}
          </span>
        </td>
      );
    }

    if (typeExercise === PLAY_MODE.EXAM) {
      return (
        <td className="position-relative">
          {infor.mark}
          <span className={styles.total}>
            ({infor.total_correct} /{infor.total_question})
          </span>
        </td>
      );
    }
  };

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr pb-5">
        <div className="container pt-3 pb-5">
          <div className="container position-relative">
            <button
              className={`position-absolute ${styles.btnBack}`}
              onClick={() => onClickBack()}
            >
              <img src={iconBack} alt="#" />
            </button>
          </div>
          <div className="row pt-3 pt-5">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div className="font-weight-bold monkey-color-back-reading-book hoc10-f-header monkey-fz-20 ">
                  {listExercise.length > 0 ? (
                    <p>Đã hoàn thành</p>
                  ) : (
                    <p>Chưa học sinh nào hoàn thành bài tập</p>
                  )}
                </div>
                <CSVLink
                  headers={HEADERS}
                  data={dataExport}
                  filename="danh-sach-ket-qua-lam-bai-hoc-sinh.csv"
                  separator=";"
                  className="monkey-bg-orange text-white px-4 py-2 rounded font-weight-bold"
                >
                  Xuất Excel
                </CSVLink>
              </div>
            </div>
            <table className={`table text-center ${styles.table}`}>
              <thead>
                <tr>
                  <th scope="col border-right border-dark">STT</th>
                  <th scope="col border-right border-dark">Tên học sinh</th>

                  <th scope="col border-right border-dark">
                    Thời gian làm bài
                  </th>
                  <th scope="col border-right border-dark">Trạng thái</th>
                  <th scope="col border-right border-dark">Điểm</th>
                  <th scope="col border-right border-dark">Chi tiết bài tập</th>
                </tr>
              </thead>
              <tbody>
                {listExercise.map((infor, index) => {
                  const timeEnd = dayjs.unix(infor.time_end);
                  const timeStart = dayjs.unix(infor.time_start);
                  const durationTime = dayjs.duration(timeEnd.diff(timeStart));
                  return (
                    <tr key={index}>
                      <th className="th" scope="row">
                        {index + 1}
                      </th>
                      <td>{infor.user_name}</td>
                      <td>{displayDuration(durationTime)}s</td>
                      {deadline > infor.time_end ? (
                        <td>Đúng hạn</td>
                      ) : (
                        <td>Quá hạn</td>
                      )}
                      {handleShowScore(infor)}
                      <td
                        className={`position-relative ${styles.ExerciseDetails}`}
                      >
                        <button
                          className="btn-pr"
                          onClick={() =>
                            onClickHistory(infor.user_class_room_id)
                          }
                        >
                          Chi tiết
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {listExercise.length === 0 && (
              <div className="w-100">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
