import { useTranslation } from "react-i18next";
import _ from "lodash";
import styled from "styled-components";
import DoingExerciseWrapper from "edu_lms/modules/DoingExercise/DoingExerciseWrapper";
import { converDataDefault } from "./selections";
import "./styles.scss";
import { TYPE_GAME } from "edu_lms_v2/libraries/hoc10Game/constants";

export default function ItemQuestion({ listQuestion, originQuestions = [] }) {
  const { t } = useTranslation();

  const defaultQuestions = converDataDefault(
    !_.isEmpty(originQuestions) ? originQuestions : listQuestion
  );

  return (
    <div className="doing-exercise__result__wrapper">
      {listQuestion?.map((question, number) => {
        return (
            <div className="item" id={number} key={number}>
              <div className=" position-relative">
                <Anwers className="h3 flex-lc txt-2 mb-3 w-100">
                  {t('sentence')} {number + 1}
                </Anwers>
                <div>
                  <DoingExerciseWrapper
                    data={question}
                    hasAnsweredChecking
                    isComplete={true}
                    isReadOnly={true}
                  />
                </div>
                {question.isCorrect !== true && question.game_id !== TYPE_GAME.AITTR &&
                  defaultQuestions &&
                  defaultQuestions[number] && (
                    <div>
                      <p className="ml-4 font-weight-bold monkey-color-orange monkey-fz-20">
                        {t('answer')}:
                      </p>
                      <DoingExerciseWrapper
                        data={defaultQuestions[number]}
                        hasAnsweredChecking
                        showCorrectAnswer={true}
                        isComplete={true}
                        isReadOnly={true}
                      />
                    </div>
                  )}
              </div>
            </div>
        );
      })}
    </div>
  );
}
const Anwers = styled.h3`
  border-bottom: 1px solid #eaeced;
  padding: 22px 24px;
`;
