import DatePicker from "react-datepicker";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { ROLE } from "./constant";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email không đúng định dạng")
    .required("Vui lòng nhập email giáo viên!"),
  start_time: yup
    .string()
    .required("Vui lòng nhập thời gian giáo viên bắt đầu phụ trách"),
  end_time: yup
    .string()
    .required("Vui lòng nhập thời gian giáo viên kết thúc phụ trách"),
});

export default function PopupAddTeacher({
  show,
  setShow,
  role,
  addTeacherClassroom,
  updateTeacherClassroom,
}) {
  const { register, handleSubmit, errors, reset, control } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const onHandleSubmit = (dataForm) => {
    if (role === ROLE.ADD) {
      addTeacherClassroom(dataForm);
    }

    if (role === ROLE.UPDATE) {
      updateTeacherClassroom(dataForm);
    }
  };

  const onHandleClose = () => {
    setShow(false);
    reset();
  };

  return (
    <Modal show={show} onHide={onHandleClose} centered>
      <Modal.Header closeButton className="text-center">
        <p className="w-100 text-fsize font-weight-bold">
          {role === ROLE.ADD ? "Thêm giáo viên" : "Thay đổi giáo viên"}
        </p>
      </Modal.Header>

      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Modal.Body className="p-3">
          <div>
            <span className="text-danger font-weight-bold"> * </span>
            Nhập thông tin của giáo viên.
          </div>
          <div className="form-group mt-2">
            <input
              className="form-control"
              type="email"
              name="email"
              ref={register}
              placeholder="Email của giáo viên"
            />
            {errors.email && (
              <p className="text-danger">{errors.email?.message}</p>
            )}
          </div>
          <StyleDatePicker>
            <Controller
              control={control}
              name="start_time"
              render={({ onChange, value, onBlur }) => (
                <DatePicker
                  onChange={onChange}
                  selected={value}
                  onBlur={onBlur}
                  showTimeSelect
                  dateFormat="dd/MM/yyyy, h:mm aa"
                  minDate={new Date()}
                  placeholderText="Thời gian bắt đầu"
                  wrapperClassName="w-100 d-flex"
                />
              )}
            />
            {errors.start_time && (
              <p className="text-danger">{errors.start_time.message}</p>
            )}
          </StyleDatePicker>

          <StyleDatePicker className="mt-3">
            <Controller
              control={control}
              name="end_time"
              render={({ onChange, value, onBlur }) => (
                <DatePicker
                  onChange={onChange}
                  selected={value}
                  onBlur={onBlur}
                  showTimeSelect
                  dateFormat="dd/MM/yyyy, h:mm aa"
                  minDate={new Date()}
                  placeholderText="Thời gian kết thúc"
                  wrapperClassName="w-100 d-flex"
                />
              )}
            />
            {errors.end_time && (
              <p className="text-danger">{errors.end_time.message}</p>
            )}
          </StyleDatePicker>
          <div className="mt-4 mb-3 d-flex justify-content-center">
            <button
              className="monkey-bg-orange text-white py-2 px-5 rounded"
              type="submit"
            >
              {role === ROLE.ADD ? "Thêm" : "Thay đổi"}
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
}

const StyleDatePicker = styled.div`
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .react-datepicker {
    display: flex;
  }
`;
