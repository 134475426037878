import React, { useState } from "react";
import styled from "styled-components";
import { Alert } from "reactstrap";
import ReactAudioPlayer from "react-audio-player";
import CTAIcon from "./CTAIcon";
import CTAMessage from "./CTAMessage";
import {
  AUDIO_ERROR,
  AUDIO_VICTORY,
  ALERT_GAME,
  TYPE_ENGLISH,
} from "edu_lms/constants/type";

export default function AlertReportYesNo({
  alertType,
  languageBook,
}) {
  const [showAlert, setShowAlert] = useState(true);

  return (
    <Alert
      isOpen={showAlert}
      color={alertType === ALERT_GAME.success ? "info" : "danger"}
      toggle={() => setShowAlert(false)}
      className="p-0"
    >
      <ContentReport className="d-flex justify-content-start align-items-center w-100 pl-3">
        <div className="icon-alert">
          <CTAIcon alertType={alertType} />
        </div>
        <CTAMessage alertType={alertType} languageBook={TYPE_ENGLISH} />
      </ContentReport>
      <ReactAudioPlayer
        src={alertType === ALERT_GAME.success ? AUDIO_VICTORY : AUDIO_ERROR}
        className="d-none"
        autoPlay
        controls
      />
    </Alert>
  );
}

const ContentReport = styled.div`
  padding: 0 50px;
  .icon-alert {
    width: 60px;
  }
`;
