import {
  getContentAudioVideoImageTextFromIconActData,
  getIconsListByLanguage,
} from ".";

// For AITTr
const formatActivityDataAITTR = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);
  const {
    type_question: typeQuestion,
    font_size_question: fontSize,
  } = gameConfig;

    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      gameConfig.title
    );
    const questionQ = {
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          fontSize,
          contentText: questionContentIcon.contentText
        },
      },
    };
    const questionIcon = getContentAudioVideoImageTextFromIconActData(
        iconsList,
        gameConfig.icon_question
      );
    const questionContent = {
        typeQuestion,
        contentQuestionType: {
        srcAudio: questionIcon.srcAudio,
        srcVideo: questionIcon.srcVideo,
        srcImage: questionIcon.srcImage,
        text: {
          fontSize,
          contentText: questionIcon.contentText
        },
      },
    }
    const answer = {
      answers: gameConfig.result,
    };
    const activityId = activity.activity_id;
    const resultHistory = activity?.result;
    const typeGame = { typeGame: gameConfig.type_game };
    const dataGame = { ...questionQ, ...answer, ...typeGame, ...questionContent,  resultHistory, activityId};
    
    return dataGame;
};

export { formatActivityDataAITTR };
