import { forwardRef, useState, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import _ from "lodash";
import { TYPE_SPLIT_MUL_ANSWER } from "edu_lms/constants/type";
import FindCorrectMistakesWrapper from "./FindCorrectMistakesWrapper";
import {
  formatResultGame,
  TYPE_CHECK_RESULT,
  TYPE_RESULT_INPUT,
} from "../../helpers/FindCorrectMistakes";
import TitleQuestion from "./TitleQuestion";

const FindCorrectMistakes = (
  {
    gameData,
    hideResultAnswer = false,
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { register, setValue, getValues } = useForm({
    mode: "onChange",
  });
  const [dataAnswers, setStateDataAnswers] = useState(gameData);

  useEffect(() => {
    const dataSelected = gameData[0].selectedAnswers || gameData;
    setStateDataAnswers(dataSelected);
  }, [gameData[0].game_config]);

  useEffect(() => {
    if (showCorrectAnswer) {
      const convertDataAnswer = formatResultGame(gameData);
      setStateDataAnswers(convertDataAnswer);
    }
  }, [hideResultAnswer, showCorrectAnswer]);

  const onActiveText = (data, answerId) => {
    const newData = dataAnswers.map((dataItem) => {
      dataItem.answers.map((item) => {
        if (item.answer_id === answerId) {
          item.answer_text.map((answerItem) => {
            if (answerItem.id === data.id) {
              answerItem.status =
                answerItem.status === TYPE_CHECK_RESULT.choose
                  ? TYPE_CHECK_RESULT.default
                  : TYPE_CHECK_RESULT.choose;
              if (answerItem.status) {
                item.input.show = true;
              } else {
                item.input.show = false;
              }
            } else {
              answerItem.status = TYPE_CHECK_RESULT.default;
            }
            return { ...answerItem };
          });
        }

        return { ...item };
      });

      return { ...dataItem };
    });
    onPlaying(false);
    setStateDataAnswers(newData);
  };

  const onCheckAnswers = () => {
    let isCorrect = true;
    const dataConfig = dataAnswers.map((answerItem) => {
      const dataAnswers = answerItem.answers.map((data) => {
        let newData;
        let statusData;
        let findCorrect = true;
        let listAnswer = data?.answer_correct?.split(TYPE_SPLIT_MUL_ANSWER);
        if (listAnswer) {
          let value = htmlDecode(getValues(data.answer_id))
            .trim()
            .replace(/ +(?= )/g, "");
          if (!isNaN(value)) {
            value = String(parseInt(data.answer_id, 10));
          }

          if (listAnswer?.includes(value)) {
            statusData = TYPE_RESULT_INPUT.correct;
          } else {
            statusData = TYPE_RESULT_INPUT.wrong;
            findCorrect = false;
            isCorrect = false;
          }
        } else {
          statusData = TYPE_RESULT_INPUT.correct;
        }

        if (data.answer_result.length > 0) {
          newData = data.answer_text.map((item) => {
            let status = TYPE_CHECK_RESULT.default;
            if (
              _.includes(
                data?.answer_result,
                item?.value?.replace(/[&\/\\#,+()$~%.":*?<>{}]/g, "")
              )
            ) {
              switch (item.status) {
                case 0:
                  status = 4;
                  break;
                case 1:
                  status = 2;
                  break;
                case 2:
                  status = 2;
                  break;
                case 4:
                  status = 4;
                  break;
                default:
                  return false;
              }
            } else {
              if (item.status !== 0) {
                status = 3;
                isCorrect = false;
                findCorrect = false;
              }
            }
            return { ...item, status: status };
          });
        } else {
          newData = data.answer_text.map((item) => {
            let status;
            if (item.status !== 0) {
              status = 3;
              isCorrect = false;
              findCorrect = false;
            } else {
              status = 0;
            }
            return { ...item, status: status };
          });
        }
        return {
          ...data,
          answer_text: newData,
          input: {
            ...data.input,
            status: findCorrect && statusData ? statusData : 2,
            text: getValues(data.answer_id),
          },
        };
      });
      return { ...answerItem, answers: dataAnswers };
    });
    onComplete({ isCorrect, selectedAnswers: dataConfig });
    setStateDataAnswers(dataConfig);
  };

  useImperativeHandle(ref, () => ({
    handleCheck: onCheckAnswers,
  }));

  const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  useEffect(() => {
    if (dataAnswers) {
      dataAnswers[0]?.answers?.map((answer) => {
        register(
          {
            name: `${answer.answer_id}`,
          },
          { required: true }
        );
      });
    }
  }, [register, dataAnswers]);

  return (
    <FindCorrectMistakesContainer>
      <TitleQuestion data={dataAnswers[0]?.question} gameConfig={gameData[0]} />
      <FindCorrectMistakesWrapper
        data={dataAnswers[0]}
        gameConfig={gameData[0]}
        onActiveText={onActiveText}
        onCheckAnswers={onCheckAnswers}
        setValue={setValue}
        getValues={getValues}
        register={register}
        isReadOnly={isReadOnly}
        hideResultAnswer={hideResultAnswer && !showCorrectAnswer}
        onPlaying={onPlaying}
      />
    </FindCorrectMistakesContainer>
  );
};

export default forwardRef(FindCorrectMistakes);

const FindCorrectMistakesContainer = styled.div`
  height: 92%;
  overflow-y: auto;
`;
