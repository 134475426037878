import { useTranslation } from "react-i18next";
import { RESULT } from "edu_lms/constants/type";

export default function BoxQuestions({ listQuestion }) {
  const { t } = useTranslation();

  return (
    <div className="box-qs-list">
      <h3 className="txt-4">{t("list of questions")}</h3>
      <ul className="list flex">
        {listQuestion?.map((question, index) => (
          <a href={`#${index}`} key={index}>
            <li
              className={`${
                question.isCorrect === RESULT._TRUE ||
                question.isCorrect === true
                  ? "true"
                  : "false"
              } cursor`}
            >
              <span>{index + 1}</span>
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
}
