import React from "react";
import Modal from "react-bootstrap/Modal";

export default function SuccessPw(props) {
  const onHide = (event) => {
    event?.preventDefault();
    props.onHide();
  };

  const onActiveLogin = (event) => {
    event?.preventDefault();
    props.onActiveLogin();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal fade modal-success modal-active modal-active-book"
      animation="true"
    >
      <div className="box-success">
        <div className="txt-2 border-bottom text-center pb-4 text-fsize">
          Đặt lại mật khẩu
        </div>
        <span>
          {/* <button
            type="button"
            className="close close-modal-active"
            aria-label="Close"
            onClick={(e) => onHide(e)}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </span>
        <form className="text-center mt-4">
          <div className="img-wrapper text-center">
            <p className="m-4 fsize">
              Thầy cô đã thay đổi mật khẩu thành công!
            </p>
            <p className="m-4 fsize">
              Để đăng nhập vào hoc10.vn, Thầy cô vui lòng click{" "}
              <span
                onClick={(e) => onActiveLogin(e)}
                className="text-orange cursor"
              >
                vào đây
              </span>
            </p>
          </div>
        </form>
      </div>
    </Modal>
  );
}
