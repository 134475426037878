import { GRADES } from "edu_lms/constants/type";
import {
  APP_ID as HOC10_APP_ID,
  TUTORING_APP_ID,
} from "edu_lms/constants/type";
import styled from "styled-components";

export let SCHOOL_LEVEL_DEFAULT = [];
export let DEFAULT_GRADE_ID;
export let DEFAULT_SUBJECT_ID;
export let DEFAULT_VALUE_BLOCK;
const product = Number(process.env.REACT_APP_APP_ID);

if (product === HOC10_APP_ID) {
  DEFAULT_GRADE_ID = 4;
  DEFAULT_SUBJECT_ID = 32;
  DEFAULT_VALUE_BLOCK = 1;
  SCHOOL_LEVEL_DEFAULT = [
    {
      schoolLevel: 1,
      gradeIdDefault: GRADES.lop1,
      subjectIdDefault: 32,
    },
    {
      schoolLevel: 2,
      gradeIdDefault: GRADES.lop6,
      subjectIdDefault: 42,
    },
    {
      schoolLevel: 3,
      // gradeIdDefault: GRADES.lop10,
      // subjectIdDefault: 54
    },
  ];
}

if (product === TUTORING_APP_ID) {
  DEFAULT_GRADE_ID = 19;
  DEFAULT_SUBJECT_ID = 74;
  DEFAULT_VALUE_BLOCK = 16;
  SCHOOL_LEVEL_DEFAULT = [
    {
      schoolLevel: 16,
      gradeIdDefault: 19,
      subjectIdDefault: 74,
    },
    {
      schoolLevel: 17,
      gradeIdDefault: 22,
      subjectIdDefault: 75,
    },
    {
      schoolLevel: 18,
      gradeIdDefault: 24,
      subjectIdDefault: 76,
    },
  ];
}

export const TYPE_MOCK_TEST = 2;
export const LICENSE_FREE = 1;
export const LICENSE_LOGIN = 2;

const optionSchool = [{ label: "Starters", value: 16 }];

export const currentSchoolId = (id) => {
  return optionSchool.find((school) => school.value === id);
};

export const ListCheckbox = styled.label`
  &.disable {
    pointer-events: none;
    color: #eee;
  }
`;

export const defaultSchoolId = 1;
export const defaultSchool = optionSchool.find(
  (school) => school.value === defaultSchoolId
);
export const defaultGradeId = 1;
export const defaultSubjectId = 1;