import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Typography, Image } from "edu_lms_v2/libraries/hoc10Game/components";
import { TYPE_DATA } from "edu_lms_v2/libraries/hoc10Game/constants";

const ContentAnswer = ({
  audioAnswer,
  answer,
  typeContentAnswer,
  onClickAnswer = () => {},
  onToggleAudioAnswer = () => {},
}) => {
  const { answerId, contentAnswer } = answer;

  const handleClickAudio = () => {
    onToggleAudioAnswer(answerId, contentAnswer.srcAudio);
  };

  const isPlayingAudio = audioAnswer.answerId === answerId;

  return (
    <ContentAnswerWrapper className="content-answer__wrapper">
      {_.includes(typeContentAnswer, TYPE_DATA.AUDIO) && (
        <ButtonAudio onClick={handleClickAudio}>
          <i
            style={{ color: isPlayingAudio ? "red" : "dodgerblue" }}
            className={
              isPlayingAudio ? "fa fa-pause-circle" : "fa fa-play-circle"
            }
          />
        </ButtonAudio>
      )}
      {_.includes(typeContentAnswer, TYPE_DATA.TEXT) && (
        <div className="pl-1" onClick={onClickAnswer}>
          <Typography
            text={String(contentAnswer.text.contentText)}
            typeText={contentAnswer.text.typeText}
            fontSize={contentAnswer.text.fontSize}
          />
        </div>
      )}
      {_.includes(typeContentAnswer, TYPE_DATA.IMAGE) && (
        <StyledImage src={contentAnswer.srcImage} onClick={onClickAnswer} />
      )}
    </ContentAnswerWrapper>
  );
};

export default ContentAnswer;

const ContentAnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 15px;
  text-align: left;
  height: 100%;
`;

const ButtonAudio = styled.button`
  display: block;
  border: none;
  background: none;

  i {
    font-size: 40px;
  }
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  object-fit: contain;
`;
