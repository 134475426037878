import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Studentlistclass from "./Studentlistclass.module.scss";
import * as types from "edu_lms/constants/type";
import { useSelector, useDispatch } from "react-redux";
import { animateScroll } from "react-scroll";
import {
  onDispatchSetCurrentPageClassroom,
  onDispatchSetSelectOptionSchoolYear,
} from "../TeachingManager/actions";
import { getListStudentClassroom } from "edu_lms/services/teachingManager";
import Pagination from "edu_lms_v2/components/Pagination";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH_CLASSROOM } from "edu_lms/constants/path";
import styled from "styled-components";

export default function Classroom() {
  const { t } = useTranslation();
  const history = useHistory();
  const [listClassroom, setListClassroom] = useState([]);
  const [totalClass, setTotalClass] = useState(0);
  const dispatch = useDispatch();
  const selectSchoolYear = useSelector(
    (state) => state.teachingReducer.schoolYearSelect
  );
  const searchKey = useSelector(
    (state) => state.teachingReducer.searchKeyClassroom
  );
  const handleChangePage = (page) => {
    dispatch(onDispatchSetCurrentPageClassroom(page));
  };
  const currentPage = useSelector(
    (state) => state.teachingReducer.currentPageClassroom
  );
  const handleChangeSchoolYear = (select) => {
    dispatch(onDispatchSetSelectOptionSchoolYear(select));
  };
  const getListClassroom = () => {
    const data = {
      roleId: types.ROLE_STUDENT,
      limit: types.TOTAL_CLASSROOM_PER_PAGE,
      langId: types.TYPE_VIETNAMESE,
      page: currentPage,
    };
    getListStudentClassroom(data)
      .then((res) => {
        if (res.data.status === "success") {
          setListClassroom(res.data.data.list);
          setTotalClass(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    animateScroll.scrollToTop();
    getListClassroom();
  }, [currentPage, searchKey, selectSchoolYear]);
  useEffect(() => {
    if (listClassroom.length === 0) {
      handleChangePage(1);
    }
  }, [listClassroom]);

  const handleClick = (id) => {
    const url = `${ROUTE_PATH_CLASSROOM}${id}`;
    history.push(url);
  };

  return (
    <div>
      <div className="list-class mb-4 row">
        {listClassroom.length > 0 ? (
          listClassroom.map((item, index) => (
            <div
              className="col-lg-3 col-md-4 col-xs-12 col-sm-6 cursor "
              key={index}
            >
              <p onClick={() => handleClick(item.id)}>
                <div
                  className={`p-3 my-3 text-center ${Studentlistclass.listClass}`}
                >
                  <div className="border-bottom pb-2 position-relative">
                    <p className="text-fsize font-weight-bold monkey-color-black cursor">
                      {item.title}
                    </p>
                    {/* <p className="font-italic fsize">
                      {item.school_name || "Chưa chọn trường"}
                    </p> */}
                    <div
                      className={`position-absolute ${Studentlistclass.setting}`}
                    >
                      <button
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-cog text-fsize"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <div
                        className={`dropdown-menu dropdown-menu-right p-0 border-0 ${Studentlistclass.menu}`}
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="pt-2">
                          <button className="dropdown-item border-bottom">
                            <i className="fa fa-eye" aria-hidden="true"></i>
                            &nbsp; Xem nhóm
                          </button>
                          <button className="dropdown-item border-bottom">
                            <i className="fa fa-users" aria-hidden="true"></i>
                            &nbsp;Thêm thành viên
                          </button>
                          <button className="dropdown-item border-bottom">
                            <i className="fa fa-sign-out" aria-hidden="true"></i>
                            &nbsp; Rời nhóm
                          </button>
                          <button className="dropdown-item">
                            <i className="fa fa-link" aria-hidden="true"></i>
                            &nbsp; Link liên kết nhóm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-2">
                    <p>
                      <i
                        className="fa fa-graduation-cap"
                        aria-hidden="true"
                      ></i>
                      &nbsp;{item.students_count} {t("student")}
                    </p>
                    <p>
                      <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                      &nbsp;{item.teachers_count} {t("teacher")}
                    </p>
                  </div>
                </div>
              </p>
            </div>
          ))
        ) : (
          <div className={`mx-auto my-5  ${Studentlistclass.addClass}`}>
            <p className="font-weight-bold">Bạn chưa có lớp học nào</p>
            <button className="btn-submit flex-center btn-pr">
              Thêm Lớp học
            </button>
          </div>
        )}
      </div>
      <div className="mb-4">
        {totalClass > types.TOTAL_CLASSROOM_PER_PAGE && (
          <Pagination
            total={totalClass}
            totalPerPage={types.TOTAL_CLASSROOM_PER_PAGE}
            setCurrentPage={handleChangePage}
            currentPage={currentPage}
          />
        )}
      </div>
    </div>
  );
}

const SelectWrapper = styled.div`
  width: 200px;
`;
