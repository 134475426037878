import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const RollBack = styled.div`
  i {
    background-color: rgba(42, 64, 79, 0.3);
  }
`;

export default function Header({ onRefresh }) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="monkey-bg-gray1 mt-5 mt-lg-3 p-3 rounded d-flex justify-content-between">
      <RollBack className="">
        <i
          className="fa fa-long-arrow-left monkey-color-back-reading-book monkey-fz-28 p-2 rounded cursor"
          aria-hidden="true"
          onClick={() => history.goBack()}
        />
      </RollBack>
      <div className="text-center">
        <h1 className="monkey-color-back-reading-book monkey-fz-28 font-weight-bold monkey-f-header">
          {t("you have finished the test")}!
        </h1>
      </div>
      <button
        className="btn monkey-bg-orange text-white px-4"
        onClick={onRefresh}
      >
        <span>{t("try again")}</span>
      </button>
    </div>
  );
}