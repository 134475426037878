import { Modal } from "react-bootstrap";
import danger_img from "edu_lms_v2/assets/img/img-error.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function PopupConfirmSubmit({
  show,
  setShow,
  onSubmitExam,
  isComplete,
}) {
  const handleClose = () => {
    setShow(false);
  };
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={true}
      className="monkey-color-black"
    >
      <Modal.Header closeButton className="border-0">
      </Modal.Header>
      <Modal.Body className="border-0 text-center p-0">
        <div className="d-flex justify-content-center mb-3">
          <img src={danger_img} />
        </div>
        <p>
          {isComplete
            ? t('are you sure you want to submit')
            : t('you have not finished the test. Are you sure you want to submit')}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center">
        <Button className="border-0 btn-pr" onClick={handleClose}>
          {t('close')}
        </Button>
        <Button className="btn-sub " onClick={() => onSubmitExam()}>
          {t('submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Button = styled.button`
  width: 120px;
  height: 40px;
  border-radius: 7px;
`;
