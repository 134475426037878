import dayjs from "dayjs";

export const CLASS_TYPE = [
  {
    value: 1,
    label: "Native",
  },
  {
    value: 2,
    label: "Phil",
  },
  {
    value: 3,
    label: "Viet",
  },
];

export const ROLE = {
  ADD: "addTeacher",
  UPDATE: "updateTeacher",
};

export const handleFormatTitle = (level, classType, openingDay) => {
  const date = dayjs(openingDay);
  const newTextLevel = level ? `${level[0]}${level[level.length - 1]}` : "";
  const newClassType = classType ? `_${classType.slice(0, 2)}` : "";
  const newTextDate = openingDay ? `_${date.format("DD.MM.YYYY")}` : "";

  return `${newTextLevel}${newClassType}${newTextDate}`;
};
