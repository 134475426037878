import React from "react";
import styled from "styled-components";
import { onChangeColor } from "../../helpers/SenFinding";

export default function Answer({
  dataAnswers,
  fontSize = 26,
  isReadOnly,
  hideResultAnswer,
  onActiveText = () => {},
}) {

  return (
    <div className="find-words-body p-2 quicksand-medium">
      <div className="mb-3 d-flex flex-wrap pr-3 pl-3 monkey-fz-18">
        {dataAnswers.map((data, indexText) => {
          const pathAudio = data.props[0]?.audio[0]?.path;
          return (
            <div key={indexText} className="position-relative w-100">
              <div className={pathAudio ? "pl-5" : ""}>
                <ContentAnswer
                  className={`${onChangeColor(
                    data.status, hideResultAnswer
                  )} cursor rounded-pill hvr-registration`}
                  fontSize={fontSize}
                  isReadOnly={isReadOnly}
                  onClick={() => onActiveText(data.sentence_id)}
                >
                  {data?.props[0]?.text}
                </ContentAnswer>
                <span>&nbsp;</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const ContentAnswer = styled.span`
  border-style: solid;
  line-height: 1.5;
  border-width: 2px;
  pointer-events: ${(props) => (props.isReadOnly ? "none" : "inherit")};
  font-size: ${(props) => `${props.fontSize}px`};
`;
