import _ from "lodash";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import CreateQuestion from "./CreateQuestion";
import Item from "./Item";
import IconBack from "edu_lms_v2/assets/img/back1.svg";
import { toast } from "react-toastify";
import {
  INNER_WIDTH,
  OPTIONS_GRADE,
  OPTIONS_LEVEL_TUTORING,
  SHOW_PAGINATE_TOPICS_QUESTION_WAREHOUSE,
} from "edu_lms/constants/type";
import {
  getListQuestionSet,
  getListQuestionSetLevelTutoring,
  postCreateQuestionSet,
} from "edu_lms/services/question";
import {
  onDispatchUpdateParamterQuestionSet,
  onDispathSetInitialPageExercise,
} from "edu_lms/modules/QuestionSet/actions";
import { HOC10_QUESTION, MY_QUESTION } from "./constant";
import { useTranslation } from "react-i18next";
import Loading from "edu_lms_v2/components/Loading";

const QuestionSetWrapper = (props) => {
  const { t } = useTranslation();

  const [showModalCreate, setShowModalCreate] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();

  const [optionsLevel] = useState(OPTIONS_LEVEL_TUTORING);
  const [selectedOptionLevel, setStateSelectedOptionLevel] = useState();

  const [listItem, setListItem] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [typeDisplay, setTypeDisplay] = useState(MY_QUESTION);
  const [loading, setLoading] = useState(false);

  const selectLevel = useSelector(
    (state) => state.questionSetReducer.paramterQuestionSet.level
  );

  const titleSearch = useSelector(
    (state) => state.questionSetReducer.paramterQuestionSet.title
  );
  const initialPageExercise = useSelector(
    (state) => state.questionSetReducer.initialPageExercise
  );
  const config = useSelector((state) => state.app.config);

  const onGetData = (data) => {
    let source = 1;
    if (typeDisplay === MY_QUESTION) source = 2;
    if (typeDisplay === HOC10_QUESTION) source = 1;
    data = { ...data, source };
    setPage(data.page ? data.page - 1 : 0);
	  setLoading(true);
    getListQuestionSetLevelTutoring(data)
      .then((res) => {
        if (res.data.status === "success") {
          setListItem(res.data.data.data);
          setTotal(
            Math.ceil(
              res.data.data.total / SHOW_PAGINATE_TOPICS_QUESTION_WAREHOUSE
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeLevel = (level) => {
    setStateSelectedOptionLevel(level);
    const data = {
      level: level?.id,
      title: titleSearch,
    };
    dispatch(
      onDispatchUpdateParamterQuestionSet({
        level: level.id
      })
    );
    onGetData(data);
  }

  const onSubmit = (dataForm) => {
    const data = {
      level: selectedOptionLevel?.id,
      title: dataForm.search,
    };
    onGetData(data);
    dispatch(
      onDispatchUpdateParamterQuestionSet({
        title: dataForm.search,
      })
    );
    dispatch(
      onDispathSetInitialPageExercise({
        initialPageExercise: 0,
      })
    );
  };

  const currentPage = (page) => {
    const data = {
      level: selectedOptionLevel?.id || "",
      title: titleSearch,
      page: page,
    };
    onGetData(data);
    dispatch(
      onDispathSetInitialPageExercise({
        initialPageExercise: page - 1,
      })
    );
  };
  useEffect(() => {
    const data = {
      level: selectLevel ?? "",
      title: titleSearch,
      page: initialPageExercise + 1 ?? 1,
    };
    onGetData(data);
  }, [typeDisplay]);

  useEffect(() => {

    setValue("search", titleSearch);
  }, []);

  const showModalCreateQuestion = () => {
    setShowModalCreate(!showModalCreate);
  };

  return (
    <>
      <div
        style={{ backgroundColor: "rgba(255,190,136, 0.08)" }}
        className="electronic_courseware_wrapper pb-5 mt-4 pt-5"
      >
        <div className="container-fluid container-xl">
          <div className="row">
            <div className="col-12 mt-5">
              <div className="w-100 position-relative">
                <GoBackPage className="p-2 monkey-bg-white position-absolute cursor monkey-box-shadow">
                  <img src={IconBack} alt="" />
                </GoBackPage>
                <Title className="m-auto d-flex justify-content-center monkey-bg-white p-2 monkey-box-shadow">
                  <div
                    className={classNames(
                      "d-inline font-weight-bold pl-4 pr-4 cursor",
                      {
                        "active-type": typeDisplay === MY_QUESTION,
                      }
                    )}
                    onClick={() => setTypeDisplay(MY_QUESTION)}
                  >
                    {t("my exercise")}
                  </div>
                  <div className="monkey-f-header monkey-color-orange">|</div>
                  <span
                    className={classNames("pl-4 pr-4 cursor", {
                      "active-type": typeDisplay === HOC10_QUESTION,
                    })}
                    onClick={() => setTypeDisplay(HOC10_QUESTION)}
                  >
                    {t("shared exercises")}
                  </span>
                </Title>
              </div>
              <div className="introduce-header monkey-pb-20 monkey-mb-30 mt-4 mb-4 pb-3">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <div className="d-flex align-items-center">
                      {config.createQuestionSet && (
                        <ButtonStyle
                          className="mt-2 d-flex align-items-center monkey-bg-question"
                          onClick={() => showModalCreateQuestion()}
                        >
                          <i
                            style={{ marginRight: 10 }}
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                          />
                          <p title="Tạo mới">Tạo mới</p>
                        </ButtonStyle>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-9">
                    <div className="row d-flex justify-content-start">
                      <div className="col-12 col-sm-4 mt-2">
                        <Select
                          placeholder={t("select level")}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 5 }),
                          }}
                          onChange={handleChangeLevel}
                          options={optionsLevel}
                          value={selectedOptionLevel}
                        />
                      </div>

                      <div className="col-12 col-sm-4 mt-2">
                        <form
                          name="formSearch"
                          className="form_search"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="form-group mb-0 input-search">
                            <input
                              style={{ width: "95%" }}
                              className="form-control rounded-pill"
                              type="text"
                              autoComplete="off"
                              placeholder={t("search practice exercises")}
                              name="search"
                              ref={register({ required: false })}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!loading ? <div className="introduce-content">
                {!_.isEmpty(listItem) ? (
                  listItem.map((item, index) => (
                    <Item key={index} item={item} />
                  ))
                ) : (
                  <p className="monkey-fz-24 text-center font-weight-bold my-5">
                    {t("no topic")} 
                  </p>
                )}

                {total > 1 && (
                  <ReactPaginate
                    previousLabel={""}
                    previousClassName={"icon icon-prev"}
                    nextLabel={""}
                    nextClassName={"icon icon-next"}
                    breakLabel={"..."}
                    pageCount={total}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={(data) => currentPage(data.selected + 1)}
                    forcePage={initialPageExercise ?? page}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    previousLinkClassName={`page-link page-link--prev ${
                      initialPageExercise + 1 === 1 && "btn disabled"
                    }`}
                    nextLinkClassName={`page-link page-link--next ${
                      initialPageExercise + 1 === total && "btn disabled"
                    }`}
                    renderOnZeroPageCount={null}
                    hrefAllControls
                  />
                )}
              </div> : <Loading />}
            </div>
          </div>
        </div>
      </div>

      {/* <CreateQuestion
        show={showModalCreate}
        onHide={() => setShowModalCreate(false)}
        onSaveQuestionSet={onSaveQuestionSet}
      /> */}
    </>
  );
};

export default QuestionSetWrapper;

const ButtonStyle = styled.button`
  border: none;
  margin-left: 14px;
  border-radius: 8px;
  padding: 8px 24px;
  color: #fff;
`;

const Title = styled.div`
  width: max-content;
  border-radius: 10px;
  .active-type {
    color: #ff7707;
  }
`;

const GoBackPage = styled.div`
  border-radius: 10px;
  ${window.innerWidth > INNER_WIDTH.DESKTOP
    ? "transform: translateX(-100%);"
    : "transform: translate(0, -130%);"}
`;
