import { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import styled from "styled-components";
import Top from "./Top";
import Left from "./Left";
import LeftAndRight from "./LeftAndRight";
import TitleQuestion from "./TitleQuestion";
import { TYPE_ANSWER, TYPE_TEMPLATE } from "../../helpers/Word_Search";

const WordSearch = (
  {
    gameData,
    hideResultAnswer = false,
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const {
    words,
    answers,
    questionTitle,
    listImage,
    listResult,
    historyAnswer,
  } = gameData;
  const {
    fontSizeTitle,
    font_size_answer,
    font_size_result,
    template_type,
    type_result,
  } = gameData.game_config;

  const [girds, setGirds] = useState(answers);
  const [isFirework, setFirework] = useState(false);
  const [wordsCorrect, setWordCorrect] = useState([]);
  const [lengthRow, setLengthRow] = useState(0);

  useEffect(() => {
    setGirds(historyAnswer || answers);
  }, [answers, historyAnswer]);

  const reverseString = (str) => {
    return [...str].reverse().join("");
  };
  const checkPosition = (listPosition) => {
    //row === true
    let startPositionCheck = 0;
    let statusCheck = true;
    listPosition.forEach((check, _check) => {
      if (_check !== 0) {
        if (check - 1 !== startPositionCheck) {
          statusCheck = false;
          return false;
        }
      }
      startPositionCheck = check;
    });
    return statusCheck;
  };

  const checkPositionCell = (listPosition) => {
    //col === true
    let startPositionCheck = 0;
    let statusCheck = true;
    listPosition.forEach((check, _check) => {
      if (_check !== 0) {
        if (check !== startPositionCheck + lengthRow) {
          statusCheck = false;
          return false;
        }
      }
      startPositionCheck = check;
    });
    return statusCheck;
  };

  const checkPositionCross = (listPosition) => {
    let startPositionCheck = 0;
    let statusCheck = true;
    listPosition.forEach((check, _check) => {
      if (_check !== 0) {
        if (check !== startPositionCheck + lengthRow - 1) {
          statusCheck = false;
          return false;
        }
      }
      startPositionCheck = check;
    });
    return statusCheck;
  };
  const chooseCell = (iRow, iCell) => {
    const dataGirds = { ...girds };
    if (+dataGirds[iRow][iCell].status === TYPE_ANSWER.choose) {
      dataGirds[iRow][iCell].status = 0;
      dataGirds[iRow][iCell].check = false;
    } else {
      dataGirds[iRow][iCell].status = TYPE_ANSWER.choose;
      dataGirds[iRow][iCell].check = true;
    }

    setGirds(Object.values(dataGirds));
    let dataChooseWord = [];
    let text = "";
    Object.values(dataGirds).forEach((row, _iRow) => {
      row.forEach((cell, _iCell) => {
        if (cell.status === TYPE_ANSWER.choose) {
          dataChooseWord.push({
            text: cell.text,
            row: _iRow,
            cell: _iCell,
            position: row.length * _iRow + _iCell + 1,
          });
          setLengthRow(row.length);
          text += cell.text;
        }
      });
    });

    let listPosition = [];
    dataChooseWord.forEach((position, _indexPosition) => {
      listPosition.push(position.position);
    });

    let checkPointPosition = checkPosition(listPosition);
    let checkPointPositionCell = checkPositionCell(listPosition);
    let checkPointPositionCross = checkPositionCross(listPosition);

    let revertText = reverseString(text);

    if (
      (words.includes(text) || words.includes(revertText)) && // true (case nối đúng nhưng sai vị trí)
      (checkPointPosition || checkPointPositionCell || checkPointPositionCross) // false
    ) {
      const dataGirds = { ...girds };
      dataChooseWord.forEach((word) => {
        dataGirds[word.row][word.cell].status = TYPE_ANSWER.default;
        setGirds(Object.values(dataGirds));
      });
      setFirework(true);
      setTimeout(() => {
        setFirework(false);
        const dataGirds = { ...girds };
        dataChooseWord.forEach((word) => {
          dataGirds[word.row][word.cell].status = TYPE_ANSWER.correct;
          dataGirds[word.row][word.cell].check = false;
          setGirds(Object.values(dataGirds));
        });
      }, 1000);

      setWordCorrect([...wordsCorrect, text]);
    }
    onPlaying(false);
  };

  const handCheckAnswer = () => {
    let isCorrect = wordsCorrect.length === listResult.length;
    girds.forEach((gird) => {
      gird.forEach((item) => {
        if (item.status === TYPE_ANSWER.choose) {
          isCorrect = false;
          item.status = TYPE_ANSWER.wrong;
        }
        return item;
      });
    });
    onComplete({ isCorrect, historyAnswer: girds });
  };

  useImperativeHandle(ref, () => ({
    handleCheck: handCheckAnswer,
  }));

  return (
    <GameContentWrapper isReadOnly={isReadOnly}>
      <TitleQuestion
        questionTitle={questionTitle}
        fontSizeTitle={fontSizeTitle}
      />
      {+template_type === TYPE_TEMPLATE.top && (
        <Top
          listResult={listResult}
          wordsCorrect={wordsCorrect}
          fontSizeResult={font_size_result}
          typeResult={type_result}
          girds={girds}
          fontSizeAnswer={
            font_size_answer ? font_size_answer.replace("px", "") : 20
          }
          fontSizeAnswerPx={font_size_answer}
          isFirework={isFirework}
          onChooseCell={chooseCell}
          showCorrectAnswer={showCorrectAnswer}
        />
      )}
      {+template_type === TYPE_TEMPLATE.left && (
        <Left
          listResult={listResult}
          wordsCorrect={wordsCorrect}
          fontSizeResult={font_size_result}
          typeResult={type_result}
          girds={girds}
          fontSizeAnswer={
            font_size_answer ? font_size_answer.replace("px", "") : 20
          }
          fontSizeAnswerPx={font_size_answer}
          isFirework={isFirework}
          onChooseCell={chooseCell}
          showCorrectAnswer={showCorrectAnswer}
        />
      )}
      {+template_type === TYPE_TEMPLATE.leftAndRight && (
        <LeftAndRight
          listResult={listResult}
          wordsCorrect={wordsCorrect}
          fontSizeResult={font_size_result}
          typeResult={type_result}
          girds={girds}
          fontSizeAnswer={
            font_size_answer ? font_size_answer.replace("px", "") : 20
          }
          fontSizeAnswerPx={font_size_answer}
          isFirework={isFirework}
          onChooseCell={chooseCell}
          listImage={listImage}
          showCorrectAnswer={showCorrectAnswer}
        />
      )}
    </GameContentWrapper>
  );
};

export default forwardRef(WordSearch);

const GameContentWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-height: 92%;
  pointer-events: ${(props) => props.isReadOnly && "none"};
`;
