import _ from "lodash";
import { URL_IMAGE_QUESTION } from "edu_lms/constants/type";
import QuestionMatrix from "./QuestionMatrix";
import { TypeGameMultipleChoice } from "..";

const LeftAndRight = ({
  wordsCorrect,
  typeResult,
  girds,
  fontSizeAnswer,
  fontSizeAnswerPx,
  isFirework,
  onChooseCell,
  listImage,
  showCorrectAnswer,
}) => {
  const reverseString = (str) => {
    return [...str].reverse().join("");
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3 mt-5">
          <div className="row">
            {listImage[0].map((image) => (
              <div className="col-md-6 cursor">
                {wordsCorrect.includes(image.text.replace(" ", "")) ||
                wordsCorrect.includes(
                  reverseString(image.text).replace(" ", "")
                ) ? (
                  <>
                    {_.includes(typeResult, TypeGameMultipleChoice.TEXT) &&
                      image.text}
                    {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) &&
                      getImage({ image, opacity: 0.3 })}
                  </>
                ) : (
                  <>
                    {_.includes(typeResult, TypeGameMultipleChoice.TEXT) &&
                      image.text}
                    {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) &&
                      getImage({ image })}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <div className="row d-flex justify-content-center mt-5">
            <QuestionMatrix
              girds={girds}
              onChooseCell={onChooseCell}
              fontSizeAnswer={fontSizeAnswer}
              fontSizeAnswerPx={fontSizeAnswerPx}
              isFirework={isFirework}
              showCorrectAnswer={showCorrectAnswer}
            />
          </div>
        </div>
        <div className="col-md-3 mt-5">
          <div className="row">
            {listImage[1].map((image) => (
              <div className="col-md-6 cursor">
                {wordsCorrect.includes(image.text.replace(" ", "")) ||
                wordsCorrect.includes(
                  reverseString(image.text).replace(" ", "")
                ) ? (
                  <>
                    {_.includes(typeResult, TypeGameMultipleChoice.TEXT) &&
                      image.text}
                    {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) &&
                      getImage({ image, opacity: 0.3 })}
                  </>
                ) : (
                  <>
                    {_.includes(typeResult, TypeGameMultipleChoice.TEXT) &&
                      image.text}
                    {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) &&
                      getImage({ image })}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftAndRight;

const getImage = ({ image = {}, opacity = 1 }) => {
  if (!image.image) return;
  return (
    <img
      style={{ opacity: opacity }}
      src={`${URL_IMAGE_QUESTION}${image.image}`}
      width="100px"
      height="auto"
      alt="img-game-word-search"
    />
  );
};
