import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import EN from "../locales/en/home.json";
import VI from "../locales/vn/home.json";

export const locales = {
  vi: "VIE",
  en: "ENG",
};

export const sourceFlag = {
  vi: "https://monkeymedia.vcdn.com.vn/upload/web/img_default/vietnam.svg",
  en: "https://monkeymedia.vcdn.com.vn/upload/web/img_default/us-flag.jpg",
};

export const resources = {
  vi: {
    home: VI,
  },
  en: {
    home: EN,
  },
};
const currentLanguage = localStorage.getItem("countryCode")
i18n.use(initReactI18next).init({
  resources,
  lng: currentLanguage == "VN" ? "vi" : "en",
  ns: ['home'],
  fallbackLng: 'vi',
  interpolation: {
    escapeValue: false // react already safes from xss
  }
})
