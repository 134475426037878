import * as TYPES from "../../constants/actionsType";
import * as PATH from "../../constants/path";
import * as TEXT from "../../constants/text";
import {
  TUTORING_APP_ID,
} from "edu_lms/constants/type";

const product = process.env.REACT_APP_APP_ID;

const configTutoring = {
  showMenu: false, // show menu page assign list student
  showCores: false, // show cores page detail result doing exercises
  showSubject: false, // show subject on header doing exercises
  showGrade: false, // show grade on header doing exercises
  createQuestionSet: false, // button create question set page hoc-lieu-cua-toi
  editQuestionSet: false, // button edit question set page hoc-lieu-cua-toi
};



let config = {};

if (Number(product) === TUTORING_APP_ID) config = configTutoring;

let initialState = {
  isShowLoading: false,
  typePopup: 1,
  srcAudio: "",
  listGrade: [],
  listGradeAll: [],

  currentStep: 1,
  userLocation: {},
  config: configTutoring,
};

let myReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PA_SHOW_POPUP_LOADING:
      const isShowLoading = action.payload.status;
      return { ...state, isShowLoading };
    case TYPES.PA_LIST_GRADE_ALL:
      const listGradeAll = action.data.map((item, index) => {
        const value = `lop${index + 1}`;
        const label = item.name;
        return { ...item, value, label };
      });
      return { ...state, listGradeAll };
    case TYPES.PA_CURRENT_STEP:
      const currentStep = action.data;
      return { ...state, currentStep };
    case TYPES.PA_SET_SRC_AUDIO:
      const srcAudio = action.data;
      return { ...state, srcAudio };
    case TYPES.PA_SET_IP_ADDRESS:
      const ipAddress = action.data;
      return { ...state, ipAddress };
    case TYPES.PA_RESET_APP:
      return { ...initialState };
    case TYPES.PA_SET_USER_LOCATION:
      const userLocation = action.data;
      return { ...state, userLocation };
    default:
      return state;
  }
};
export default myReducer;
