import React, { useState } from "react";
import styled from "styled-components";
import { Alert } from "reactstrap";
import ReactAudioPlayer from "react-audio-player";
import CTAIcon from "./CTAIcon";
import CTAMessage from "./CTAMessage";
import {
  AUDIO_ERROR,
  AUDIO_VICTORY,
  ALERT_GAME,
  BOOK_LANGUAGE,
  TYPE_ENGLISH,
} from "edu_lms/constants/type";

export default function AlertReportRangeMultipleGame({
  total,
  correctTotal,
  chooseTotal,
  alertType,
  languageBook,
}) {
  const [showAlert, setShowAlert] = useState(true);
  const textBookLanguage =
    BOOK_LANGUAGE.find((lang) => lang.id === Number(languageBook)) || {};

  return (
    <Alert
      isOpen={showAlert}
      color={alertType === ALERT_GAME.success ? "info" : "danger"}
      toggle={() => setShowAlert(false)}
      className="pl-2 pr-5"
    >
      <div className="d-flex">
        <ContentAlert>
          <div className="icon-alert">
            <CTAIcon alertType={alertType} />
          </div>
        </ContentAlert>
        <AnswerAlert>
          <li>
            {textBookLanguage.yourCorrect}{" "}
            <span className="monkey-color-blue">{correctTotal}</span>
          </li>
          <li>
            {textBookLanguage.yourSelected}{" "}
            <span className="monkey-color-blue">{chooseTotal}</span>
          </li>
          <li>
            {textBookLanguage.totalCorrect}{" "}
            <span className="monkey-color-blue">{total}</span>
          </li>
        </AnswerAlert>
      </div>
      <div className="pl-2 text-align-left">
        <CTAMessage alertType={alertType} languageBook={TYPE_ENGLISH} />
      </div>
      <ReactAudioPlayer
        src={alertType === ALERT_GAME.success ? AUDIO_VICTORY : AUDIO_ERROR}
        className="d-none"
        autoPlay
        controls
      />
    </Alert>
  );
}

const ContentAlert = styled.div`
  .icon-alert {
    width: 60px;
  }
`;
const AnswerAlert = styled.ul`
  text-align: left;
  li {
    list-style: none;
  }
`;
