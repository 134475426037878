
export const TypeGame = {
  FILL_THE_BLANK_WITH_NUMBER: "FILL_THE_BLANK_WITH_NUMBER",
  FILL_THE_BLANK_WITH_IMAGES: "FILL_THE_BLANK_WITH_IMAGES",
  FILL_THE_BLANK_WITH_COMPARE: "FILL_THE_BLANK_WITH_COMPARE",
  FILL_THE_BLANK_WITH_NUMBER_RANDOM: "FILL_THE_BLANK_WITH_NUMBER_RANDOM",
  SEQUENCE: 438,
  MULTIPLE_CHOICE: 439,
  MULTIPLE_CHOICE_CHECKBOX: 436,
  PRONUNCIATION_EXERCISES: 446,
  DRAG_DROP_EXERCISES: "DRAG_DROP_EXERCISES",
  MATCH: 429,
  FILL_THE_BLANK: 431,
  FILL_THE_BLANK_TABLE: 437,
  FILL_THE_BLANK_MATH: 471,
  FILL_THE_BLANK_WITH_DROPDOWN: 458,
  FILL_THE_BLANK_DYNAMICALLY: 461,
  DRAG_DROP: 429,
  DRAG_DROP_2: 430,
  DRAG_DROP_3: 466,
  DRAG_DROP_SHOW_ANSWER: 453,
  PREVIEW: 448,
  WORD_FINDING: 449,
  WORD_FINDING_2: 468,
  SEND_FINDING: 450,
  KARAOKE: 467,
  COLOR: 462,
  MATCHING_BACKGROUND: 463,
  MULTIPLE_CHOICE_BACKGROUND: 464,
  AUDIO_BACKGROUND: 470,
  FIND_CORRECT_MISTAKES: 475,
  MULTIPLE_CHOICE_BACKGROUND_TV: 474,
  DRAG_DROP_TEXT: 476,
  DRAG_DROP_IMAGE: 477,
  DRAG_DROP_BACK_GROUND: 10004,
  DRAG_DROP_BACK_GROUND_TV: 10005,
  DRAG_DROP_GRAPH: 10006,
  FILL_THE_BLANK_GRAPH: 10007,
  MATH_INPUT: 1482,
  WORD_SEARCH: 1483,
  REGION_OF_THE_INEQUALITY: 1000013,
  AITTR: 1000014
};
