import styled from "styled-components";
import _ from "lodash";
import { URL_IMAGE_QUESTION } from "edu_lms/constants/type";
import QuestionMatrix from "./QuestionMatrix";
import { TypeGameMultipleChoice } from "..";

const Left = ({
  listResult,
  wordsCorrect,
  fontSizeResult,
  typeResult,
  girds,
  fontSizeAnswer,
  fontSizeAnswerPx,
  isFirework,
  onChooseCell,
  showCorrectAnswer,
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-4 mt-5 d-flex justify-content-center align-items-center">
          <div
            className="row"
            style={{ display: "block", textAlign: "center" }}
          >
            {listResult.map((result, index) =>
              wordsCorrect.includes(result.text.replace(" ", "")) ? (
                <LiStyle
                  key={index}
                  style={{
                    marginBottom: "20px",
                    textDecorationLine: "line-through",
                    textDecorationThickness: "3px",
                    fontSize: `${fontSizeResult ? fontSizeResult : "18px"}`,
                  }}
                >
                  {_.includes(typeResult, TypeGameMultipleChoice.TEXT) &&
                    result.text}
                  {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) && (
                    <img
                      style={{ opacity: 0.3 }}
                      src={`${URL_IMAGE_QUESTION}${result.image}`}
                      width="100px"
                      height="auto"
                      alt=""
                    />
                  )}
                </LiStyle>
              ) : (
                <LiStyle
                  key={index}
                  style={{
                    marginBottom: "20px",
                    fontSize: `${fontSizeResult ? fontSizeResult : "18px"}`,
                  }}
                >
                  {_.includes(typeResult, TypeGameMultipleChoice.TEXT) &&
                    result.text}
                  {_.includes(typeResult, TypeGameMultipleChoice.IMAGE) && (
                    <img
                      src={`${URL_IMAGE_QUESTION}${result.image}`}
                      width="100px"
                      height="auto"
                      alt=""
                    />
                  )}
                </LiStyle>
              )
            )}
          </div>
        </div>
        <div className="col-md-8">
          <div className="row d-flex justify-content-start mt-5">
            <QuestionMatrix
              girds={girds}
              onChooseCell={onChooseCell}
              fontSizeAnswer={fontSizeAnswer}
              fontSizeAnswerPx={fontSizeAnswerPx}
              isFirework={isFirework}
              showCorrectAnswer={showCorrectAnswer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LiStyle = styled.li`
  margin-right: 25px;
  color: #14c6f4;
  font-size: 25px;
  list-style-type: none;
`;

export default Left;
