import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { postLogin } from "edu_lms/services/signIn/signIn";
import { onListGradeSubject } from "edu_lms/modules/UpdateInformation/config";
import { onAddLocalStorage, setItemUserInfo } from "edu_lms/modules/selection";
import {
    PHONE_TYPE,
    GMAIL_TYPE,
    APP_ID,
} from "../../../edu_lms/constants/type";
import LoginSuccessModal from "./LoginSuccessModal";
import ForgotPassword from "../ForgotPassword";

export default function LoginPage(props) {
  useEffect(() => {
    setMess("");
  }, [props.show]);
  const [check, setCheck] = useState(false);
  const [mess, setMess] = useState("");
  const [isPassword, setStatePassword] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [showModalLoginSuccess, setShowModalLoginSuccess] = useState(false);
  const [isActiveForgotPw, setActiveForgotPw] = useState(false);

  async function onSubmit(data) {
    const userName = data.userName.replace(/\s+/g, "");
    let reg = /^[0-9]*[.]?[0-9]*$/;
    let dataInput = {};
    if (reg.test(userName)) {
      dataInput = {
        type: PHONE_TYPE,
        phone: userName,
        email: "",
        userNameCrm: "",
        is_web: 1,
        app_id: APP_ID,
        password: data.password,
      };
    } else if (userName.includes("@")) {
      dataInput = {
        type: GMAIL_TYPE,
        email: userName,
        phone: "",
        userNameCrm: "",
        is_web: 1,
        app_id: APP_ID,
        password: data.password,
        isGoogle: false,
      };
    } else {
      dataInput = {
        userNameCrm: userName,
        email: "",
        phone: "",
        is_web: 1,
        app_id: APP_ID,
        password: data.password,
      };
    }

    data = dataInput;
    onPostLogin(data);
  }

  // const responseFace = (data) => {
  //   data = {
  //     type: FACEBOOK_TYPE,
  //     email: data.email ?? data.email,
  //     name: data.name ?? data.name,
  //     access_token: data.accessToken,
  //     is_web: 1,
  //     app_id: APP_ID,
  //     avatar: data.picture.data.url,
  //   };
  //   onPostLogin(data);
  // };

  const responseGoogle = (data) => {
    data = {
      type: GMAIL_TYPE,
      email: data.email ?? data.profileObj.email,
      name: data.name ?? data.profileObj.name,
      access_token: data.accessToken,
      is_web: 1,
      isGoogle: true,
      avatar: data.profileObj.imageUrl,
    };
    onPostLogin(data);
  };

  let location = useLocation();
  let history = useHistory();

  const onPostLogin = (data) => {
    const dataBfLogin = {
      ...data,
    };
    postLogin(data).then((response) => {
      if (response.data.status === "success") {
        const listGradeSubject = onListGradeSubject(
          response.data.data.user_info.list_grade_subject
        );
        const userInfo = {
          ...response.data.data.user_info,
          full_name:
              response.data.data.user_info.full_name ||
            dataBfLogin.name,
          avatar:
              response.data.data.user_info.avatar ||
            dataBfLogin.avatar,
          list_grade_subject: listGradeSubject,
        };
        setItemUserInfo(userInfo);
        onAddLocalStorage(
          response.data.data.access_token,
          response.data.data.user_id,
          1
        );
        if (location?.state?.from) {
          history.push(location.state.from);
        } else {
          setShowModalLoginSuccess(true);
        }
      } else {
        setCheck(response.data.status);
        setMess(response.data.message);
      }
    });
  };

  return (
      <div className="d-flex align-items-center justify-content-center ">
          <div className="modal-login show" id="modal-login">
              <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
              >
                  <div className="modal-content h5Seo">
                      <h1 className="title txt-3">Đăng nhập</h1>
                      <div className="note">
                          <span>*</span> Nếu đã sử dụng ứng dụng học tập
                          Monkey, bạn có thể <strong>Đăng nhập </strong>
                          ngay bằng số điện thoại đã đăng ký sử dụng ứng dụng
                      </div>
                      <form
                          action=""
                          method=""
                          className="form-h10"
                          onSubmit={handleSubmit(onSubmit)}
                      >
                          <div className="form-group">
                              <label>
                                  Tài khoản <sup>*</sup>
                              </label>
                              <input
                                  type="text"
                                  placeholder="Nhập tên đăng nhập, số điện thoại, email"
                                  name="userName"
                                  ref={register({
                                      required: true,
                                      maxLength: 80,
                                  })}
                              />
                              <p className="text-danger mt-2">
                                  {errors.userName &&
                                      "Vui lòng nhập tên đăng nhập hoặc số điện thoại hoặc email"}
                              </p>
                          </div>
                          <div className="form-group position-relative">
                              <label>
                                  Mật khẩu <sup>*</sup>
                              </label>
                              <input
                                  placeholder="Nhập mật khẩu"
                                  name="password"
                                  type={isPassword ? "text" : "password"}
                                  ref={register({
                                      required: true,
                                      maxLength: 20,
                                  })}
                              />
                              <i
                                  style={{
                                      position: "absolute",
                                      top: "38px",
                                      right: "15px",
                                      zIndex: "20",
                                      fontSize: "16px",
                                  }}
                                  onClick={() =>
                                      setStatePassword(!isPassword)
                                  }
                                  className={`fa cursor ${
                                      isPassword
                                          ? "fa-eye monkey-bc-black"
                                          : "fa-eye-slash"
                                  }`}
                                  aria-hidden="true"
                              ></i>
                              <p className="text-danger mt-2">
                                  {errors.password &&
                                      "Vui lòng nhập mật khẩu"}
                              </p>
                          </div>
                          <div className="flex-lc">
                              <a
                                href="#!"
                                className="forgot-password cursor"
                                onClick={() => setActiveForgotPw(true)}
                              >
                                Quên mật khẩu?
                              </a>
                          </div>
                          {check && (
                              <p className="text-danger mt-2">{mess}</p>
                          )}
                          <button
                              type="submit"
                              className="btn-pr btn-submit flex-center"
                          >
                              Đăng nhập
                          </button>
                          {/*<div className="register txt-5">*/}
                          {/*    Bạn chưa có tài khoản?{" "}*/}
                          {/*    <Link to={PATH.ROUTE_PATH_V3_REGISTER}>*/}
                          {/*        Đăng ký ngay*/}
                          {/*    </Link>*/}
                          {/*</div>*/}
                          <div className="text">
                              <p>
                                  Bằng việc đăng nhập, bạn đã đồng ý với các
                                  điều khoản dịch vụ và các chính sách riêng
                                  tư của chúng tôi
                              </p>
                          </div>
                          <div className="box-login">
                              <h4 className="flex-center">
                                  <span>Hoặc đăng nhập bằng</span>
                              </h4>
                              <div className="login-list flex-center flex-column">
                                  <GoogleLogin
                                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                      render={(renderProps) => (
                                          <a
                                              href="#!"
                                              onClick={renderProps.onClick}
                                              disabled={renderProps.disabled}
                                              className="flex-center google txt-4"
                                          >
                                              <i className="icon icon-gg"></i>
                                              Google
                                          </a>
                                      )}
                                      onSuccess={responseGoogle}
                                  />
                              </div>
                          </div>
                          <LoginSuccessModal
                              show={showModalLoginSuccess}
                              onHide={() => setShowModalLoginSuccess(false)}
                          />
                      </form>
                      <ForgotPassword
                          isActiveForgotPw={isActiveForgotPw}
                          setActiveForgotPw={setActiveForgotPw}
                          isPopupLogin={false}
                      />
                  </div>
              </div>
          </div>
      </div>
  );
}
