export const SIGN_IN = "sign_in";
export const FORGOT_PASSWORD = "forgot_password";
export const SIGN_UP = "sign_up";
export const UPDATE_USER_INFORMATION = "update_user_information";
export const BOOK_GUIDING = "book_guiding";
export const OPEN_BOOK = "open_book";
export const CLICK_EXERCISE = "click_exercise";
export const REPORT_ERROR = "report_error";
export const CLICK_LECTURE_LINK = "click_lecture_link";
export const SEARCH_LECTURE = "search_lecture";
export const CHOOSE_GRADE_LECTURE = "choose_grade_lecture";
export const CHOOSE_SUBJECT_LECTURE = "choose_subject_lecture";
export const OPEN_LECTURE = "open_lecture";
export const OPEN_LECTURE_INSTRUCTIONS = "open_lecture_instructions";
export const CLICK_CHATBOX = "click_chatbox";
export const CLICK_RECENT_BOOK = "click_recent_book";
export const CLICK_MOCK_TEST_LINK = "click_mock_test_link";
export const CHOOSE_GRADE_MOCK_TEST = "choose_grade_mock_test";
export const CHOOSE_SUBJECT_MOCK_TEST = "choose_subject_mock_test";
export const PLAY_MOCK_TEST = "play_mock_test";
export const ALTER_PLAY_MOCK_TEST = "after_play_mock_test";
export const OPEN_TEST = "open_test";
export const SUBMIT_TEST = "submit_test";
export const SHARE_TEST = "share_test";
export const OPEN_TRAINING_INSTRUCTIONS = "open_training_instructions";
export const CONFIRM_SO_GD = "confirm_SoGD";
export const CONFIRM_PERSONAL_INFO = "confirm_personal_info";
export const ADD_TRAINING_SUBJECT = "add_training_subject";
export const OB_ACCOUNT_TYPE = "ob_account_type";
export const OB_PERSONAL_INFO = "ob_personal_info";
export const OB_CLASS_INFO = "ob_class_info";
