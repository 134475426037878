import { SPLIT_ANSWER_RESULT } from "edu_lms_v2/libraries/hoc10Game/constants/FIB";

// Keep logic handle check correct/ wrong input with old data before (copy from old code)
function getCorrectWrongInputAnswersOldVersion(dataAnswers, dataForm) {
  dataAnswers.answers.forEach((value, index, array) => {
    if (!Array.isArray(value.answer_result)) {
      array[index].answer_result = value.answer_result.split(
        SPLIT_ANSWER_RESULT
      );
    }
  });

  const resultData = [];
  dataAnswers.answers.forEach((answerItem, index) => {
    let data = [];
    if (!answerItem.index) {
      if (answerItem.answer_result.includes(dataForm[index])) {
        data = { ...data, ...answerItem, isCorrect: true };
      } else {
        data = { ...data, ...answerItem, isCorrect: false };
      }
      resultData[index] = { ...answerItem, ...data };
    }
  });

  let correctAnswer = JSON.parse(JSON.stringify(dataAnswers.answers));

  for (let i = 0; i < dataForm.length; i++) {
    let data = [];
    if (correctAnswer[i].index) {
      if (correctAnswer[i].answer_result.includes(dataForm[i])) {
        data = { ...data, ...dataAnswers.answers[i], isCorrect: true };

        for (let j = 0; j < correctAnswer.length; j++) {
          if (
            correctAnswer[j]?.index &&
            correctAnswer[j]?.index == correctAnswer[i].index
          ) {
            let position_j = correctAnswer[j]?.answer_result.indexOf(
              dataForm[i]
            );
            if (position_j > -1) {
              correctAnswer[j]?.answer_result.splice(position_j, 1);
            }
          }
        }
      } else {
        data = { ...data, ...dataAnswers.answers[i], isCorrect: false };
      }
      resultData[i] = { ...dataAnswers.answers[i], ...data };
    }
  }
  return resultData;
}

export { getCorrectWrongInputAnswersOldVersion }
