export function formatACTGameWordSearch(data) {
  const answers = formatDataAnswer(data.game_config.answer);
  const words = formatDataResult(data);
  const questionTitle = formatQuestionTitle(data);
  const listImage = formatShowDataResultImage(data);
  const listResult = formatShowDataListResult(data);
  const results = words.map((item) => item.split(""));
  let answersGame = [];
  answers.forEach((_answer) => {
    const _indexWords = _answer.length;
    _answer.forEach(
      (item) => (answersGame = [...answersGame, { ...item, _indexWords }])
    );
  });

  let firstResults = [];
  answersGame.forEach((item) => {
    results.forEach((_result) => {
      if (item.text === _result[0]) {
        firstResults = [...firstResults, item];
      }
    });
  });
  getResults(answersGame, firstResults, words, answers, results);

  return {
    ...data,
    words,
    questionTitle,
    answers,
    listImage,
    listResult,
  };
}

function checkAnswerText(data = [], words) {
  const resultAnswer = data.map((answer) => {
    let textResult = { text: "", indexResult: [] };
    answer.forEach((item) => {
      textResult = {
        ...textResult,
        text: `${textResult.text}${item.text}`,
        indexResult: [...textResult.indexResult, item],
      };
    });
    words.forEach((items) => {
      if (textResult.text.includes(items)) {
        const start = textResult.text.indexOf(items);
        const end = start + items.length;
        for (let i = start; i < end; i++) {
          textResult.indexResult[i].showResult = true;
        }
      }
    });
    return textResult;
  });
  return resultAnswer;
}

function getResults(answersGame, firstResults, words, answers = [], results) {
  // get answer row
  const answerRow = checkAnswerText(answers, words);

  // get answer col
  let answerCol = [];
  for (let i = 0; i < answers[0].length; i++) {
    let textCol = [];
    for (let j = 0; j < answers.length; j++) {
      textCol = [...textCol, answers[j][i]];
    }
    answerCol.push(textCol);
  }

  const resultAnswerCol = checkAnswerText(answerCol, words);
  return { answerRow, resultAnswerCol };
}

function formatQuestionTitle(data) {
  const dataIcon = data.icon_list[0]?.icons.filter(
    (icon) => icon.icon_id == data.game_config.question?.icon_id
  );
  return dataIcon;
}

function formatDataAnswer(data) {
  let arrayListWord = [];
  data.forEach((icon, _index) => {
    let arrayWord = [];
    let dataIcon = icon.icon_id.split("*");
    const position = _index * dataIcon.length;
    dataIcon.forEach((word, _indexWord) => {
      arrayWord.push({
        text: word,
        status: 0,
        check: false,
        position: position + _indexWord,
      });
    });
    arrayListWord.push(arrayWord);
  });
  return arrayListWord;
}

function formatDataResult(data) {
  const dataResult = data.game_config.result.map((result) => {
    let iconId = data.icon_list[0].icons.filter(
      (icon) => icon.icon_id == result.icon_id
    );
    return { ...result, icon: iconId };
  });
  let arrayWord = [];
  dataResult.forEach((word) => {
    arrayWord.push(word.icon[0].props[0].text.replace(" ", ""));
  });
  return arrayWord;
}

function formatShowDataListResult(data) {
  const dataResult = data.game_config.result.map((result) => {
    let iconId = data.icon_list[0].icons.filter(
      (icon) => icon.icon_id == result.icon_id
    );
    return { ...result, icon: iconId };
  });
  let arrayResult = [];
  dataResult.forEach((word) => {
    arrayResult.push({
      text: word.icon[0].props[0].text,
      image: word.icon[0].path,
    });
  });
  return arrayResult;
}

function formatShowDataResultImage(data) {
  const dataResult = data.game_config.result.map((result) => {
    let iconId = data.icon_list[0].icons.filter(
      (icon) => icon.icon_id == result.icon_id
    );
    return { ...result, icon: iconId };
  });
  let arrayWord = [];
  dataResult.forEach((word) => {
    arrayWord.push({
      text: word.icon[0].props[0].text,
      image: word.icon[0].path,
    });
  });
  let imageLeft = arrayWord.slice(0, arrayWord.length / 2);
  let imageRight = arrayWord.slice(arrayWord.length / 2, arrayWord.length);

  return [imageLeft, imageRight];
}

export const TYPE_ANSWER = {
  choose: 1,
  correct: 2,
  default: 3,
  wrong: 4,
};

export const TYPE_TEMPLATE = {
  top: 1,
  left: 2,
  leftAndRight: 4,
};

export const onChangeColor = (cell = {}, showCorrectAnswer) => {
  if (showCorrectAnswer && cell.showResult) return "#23BF2D";
  switch (cell.status) {
    case TYPE_ANSWER.choose:
      return "#14C6F4";
    case TYPE_ANSWER.correct:
      return "#23BF2D";
    case TYPE_ANSWER.default:
      return "#fff";
    case TYPE_ANSWER.wrong:
      return "red";
    default:
      return "";
  }
};
