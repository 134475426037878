import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { sendOtpVerifyPw } from "edu_lms/services/forgotPw/forgotPw";
import {
  SEND_OTP_PW_BY_EMAIL,
  SEND_OTP_PW_BY_PHONE,
  REG_EXP_EMAIL,
  APP_ID
} from "edu_lms/constants/type";

export default function ForgotPassword(props) {
  const [messageError, onSetMessageError] = useState("");
  const { register, handleSubmit, watch, errors, reset } = useForm();
  const onSubmit = (data) => {
    if (!errors?.emailOrPhone) {
      const emailOrPhone = data.emailOrPhone.replace(/\s+/g, "");
      let reg = /^[0-9]*[.]?[0-9]*$/;
      let dataPost = { type: "", is_web: 1 };

      if (reg.test(emailOrPhone)) {
        dataPost = {
          ...dataPost,
          type: SEND_OTP_PW_BY_PHONE,
          phone: emailOrPhone,
          app_id: APP_ID,
        };
      }
      if (emailOrPhone.includes("@")) {
        dataPost = {
          ...dataPost,
          type: SEND_OTP_PW_BY_EMAIL,
          email: emailOrPhone,
          app_id: APP_ID,
        };
      }
      sendOtpVerifyPw(dataPost)
        .then((res) => {
          if (res.data.status === "fail") {
            onSetMessageError(
              res.data.message
                .replace("Monkey", "Hoc10")
                .replace("Ba mẹ", "Bạn")
            );
          }
          if (res.data.status === "success") {
            props.setCurrentInput(data.emailOrPhone);
            props.onActiveMessPhonePw(dataPost);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  };

  const onValidateEmail = (value) => {
    const userName = value.replace(/\s+/g, "");
    onSetMessageError("");
    let reg = /^[0-9]*[.]?[0-9]*$/;
    if (userName.includes("@")) {
      return !REG_EXP_EMAIL.test(userName)
        ? "Email không đúng định dạng. Vui lòng kiểm tra lại"
        : true;
    } else {
      return !reg.test(userName)
        ? "Số điện thoại không đúng định dạng. Vui lòng kiểm tra lại"
        : true;
    }
  };
  useEffect(() => {
    onSetMessageError("");
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal fade modal-success modal-active modal-active-book"
      animation="true"
      backdrop="static"
    >
      <div className="box-success">
        <div className="txt-2 border-bottom text-center pb-4 text-fsize">
          Đặt lại mật khẩu
        </div>
        <span>
          {/* <button
            type="button"
            className="close close-modal-active"
            aria-label="Close"
            onClick={props.onHide}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </span>
        <form onSubmit={handleSubmit(onSubmit)} className="text-center mt-4">
          <div className="img-wrapper text-center">
            <p className="m-4 fsize">Xin vui lòng nhập email/số điện thoại</p>
            <input
              type="text"
              className="form-control w-90  mx-auto mb-5 fsize"
              placeholder="Nhập email/số điện thoại tại đây"
              name="emailOrPhone"
              ref={register({
                required: "Xin vui lòng nhập email/số điện thoại",
                validate: (value) => onValidateEmail(value),
              })}
            />
            {errors.emailOrPhone?.message ? (
              <span className="monkey-color-red">
                {errors.emailOrPhone.message}
              </span>
            ) : messageError ? (
              <span className="monkey-color-red">{messageError}</span>
            ) : (
              ""
            )}
          </div>
          <button
            title=""
            className="btn-pr btn-access w-25 mr-3 bg-white text-orange border-orange fsize"
            type="button"
            onClick={props.onHide}
          >
            Hủy
          </button>
          <button
            title=""
            type="submit"
            className="btn-pr btn-access w-25 fsize"
          >
            Gửi mã
          </button>
        </form>
      </div>
    </Modal>
  );
}
