import React from "react";
import _ from "lodash";
import { Audio, AudioType, Typography, Video, ImageWithFullScreen } from "../../../components";
import { TYPE_DATA } from "../../../constants";
import THEMES from "edu_lms_v2/libraries/hoc10Game/constants/themes";
import styles from "./styles.module.scss";

const HeaderQuestion = ({ question, theme, isReadOnly = false }) => {
  const { typeQuestion, contentQuestion } = question;

  if (typeQuestion) {
    return (
      <>
        {_.includes(typeQuestion, TYPE_DATA.TEXT) && (
          <div
            className={`pr-3 pl-4 quicksand-medium ${styles["header__text-wrapper"]}`}
          >
            <Typography
              fontSize={contentQuestion.text.fontSize}
              typeText={contentQuestion.text.typeText}
              text={contentQuestion.text.contentText}
            />
          </div>
        )}
        {_.includes(typeQuestion, TYPE_DATA.VIDEO) && (
          <Video src={contentQuestion.srcVideo} />
        )}
        {_.includes(typeQuestion, TYPE_DATA.IMAGE) && (
          <div className="d-flex text-center justify-content-center">
          <ImageWithFullScreen
            src={contentQuestion.srcImage}
            alt="FIB-header-question-image"
            hasFullScreen={theme === THEMES.FIB.Primary}
            maxHeight="300px"
          /></div>
        )}
        {_.includes(typeQuestion, TYPE_DATA.AUDIO) && (
          <div className={styles["header__audio-wrapper"]}>
            <Audio variant={AudioType.Secondary} src={contentQuestion.srcAudio} isReadOnly={isReadOnly}/>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {contentQuestion.text.contentText && (
        <div className={`pr-3 pl-4 quicksand-medium ${styles["header__text-wrapper"]}`}>
          <Typography
            fontSize={contentQuestion.text.fontSize}
            typeText={contentQuestion.text.typeText}
            text={contentQuestion.text.contentText}
          />
        </div>
      )}
      {contentQuestion.srcImage && (
        <div className="d-flex text-center justify-content-center">
        <ImageWithFullScreen
          src={contentQuestion.srcImage}
          alt="FIB-header-question-image"
          hasFullScreen={theme === THEMES.FIB.Primary}
        /></div>
      )}
      {contentQuestion.srcAudio && (
        <div className={styles["header__audio-wrapper"]}>
          <Audio variant={AudioType.Primary} src={contentQuestion.srcAudio} />
        </div>
      )}
    </>
  );
};

export default HeaderQuestion;
