import React from "react";
import styled from "styled-components";
import { ALERT_GAME, TYPE_ENGLISH } from "edu_lms/constants/type";
import { CTA_POPUP_TYPE, ANSWER_REPORT_RANGE } from "../../constants";
import AlertReportYesNo from "./AlertReportYesNo";
import AlertReportRangeOneGame from "./AlertReportRangeOneGame";
import AlertReportRangeMultipleGame from "./AlertReportRangeMultipleGame";
import FinalReport from "./FinalReport";

const getTypeAlert = (result) => {
  let alertType = ALERT_GAME.error;
  if (
    ANSWER_REPORT_RANGE.weak <= result &&
    result < ANSWER_REPORT_RANGE.average
  ) {
    alertType = ALERT_GAME.rather;
  }
  if (
    ANSWER_REPORT_RANGE.average <= result &&
    result < ANSWER_REPORT_RANGE.pretty
  ) {
    alertType = ALERT_GAME.medium;
  }
  if (result === ANSWER_REPORT_RANGE.good) {
    alertType = ALERT_GAME.success;
  }
  return alertType;
};

const GameReportAnswer = ({
  alertType,
  total,
  correctTotal,
  chooseTotal,
  typeReport,
  onResetAnswer,
}) => {

  let result = (correctTotal / total) * 100;
  if (chooseTotal && total < chooseTotal) {
    result = (correctTotal / chooseTotal) * 100;
  }

  const renderReport = () => {
    switch (typeReport) {
      case CTA_POPUP_TYPE.yesNo:
        return (
          <AlertReportYesNo alertType={alertType} languageBook={TYPE_ENGLISH} />
        );
      case CTA_POPUP_TYPE.rangeOneGame:
        return (
          <AlertReportRangeOneGame
            alertType={getTypeAlert(result)}
            total={total}
            correctTotal={correctTotal}
          />
        );
      case CTA_POPUP_TYPE.rangeMultipleGame:
        return (
          <AlertReportRangeMultipleGame
            alertType={getTypeAlert(result)}
            languageBook={TYPE_ENGLISH}
            total={total}
            correctTotal={correctTotal}
            chooseTotal={chooseTotal}
          />
        );
      case CTA_POPUP_TYPE.finalReport:
        return (
          <FinalReport
            alertType={getTypeAlert(result)}
            languageBook={TYPE_ENGLISH}
            total={total}
            correctTotal={correctTotal}
            onResetAnswer={onResetAnswer}
          />
        );
      default:
        return null;
    }
  };

  return <ReportWrapper>{renderReport()}</ReportWrapper>;
};

export default GameReportAnswer;

const ReportWrapper = styled.div`
  .alert {
    position: absolute;
    bottom: 50px;
    right: 15px;
    z-index: 1000;
  }
`;
