import React, { useState } from "react";
import styled from "styled-components";
import {
  BOX_SIZE_WRAPPER,
  TYPE_DISPLAY,
  TYPE_GAME_MAT_001,
  BOX_TYPE,
} from "../../constants/MAT_001";
import { getColorBackground } from "../../helpers/MAT_001";
import { Image, Typography } from "../../components";
import styles from "./styles.module.scss";

const BoxMatch = ({
  boxType,
  boxContent,
  typeDisplay,
  typeGame,
  typeText,
  fontSizeContent,
  isActive = false,
  isCorrect = null,
  disabled = false,
  onClickBox = () => {},
}) => {
  const getBoxSizeWidth = () => {
    if (typeDisplay === TYPE_DISPLAY.Horizontal)
      return BOX_SIZE_WRAPPER.Default;
    if (typeDisplay === TYPE_DISPLAY.Vertical)
      return BOX_SIZE_WRAPPER.Vertical.Width;
    if (typeDisplay === TYPE_DISPLAY.Vertical3Columns) {
      return boxType === BOX_TYPE.Question
        ? BOX_SIZE_WRAPPER.Default
        : BOX_SIZE_WRAPPER.Vertical3Columns.Width;
    }
    return 0;
  };

  const getBoxSizeHeight = () => {
    if (typeDisplay === TYPE_DISPLAY.Horizontal)
      return BOX_SIZE_WRAPPER.Default;
    if (typeDisplay === TYPE_DISPLAY.Vertical) {
      if (
        [
          TYPE_GAME_MAT_001.Image_Text,
          TYPE_GAME_MAT_001.Image_Image,
          TYPE_GAME_MAT_001.ImageText_Text,
        ].includes(typeGame)
      ) {
        return BOX_SIZE_WRAPPER.Vertical.HeightWithImage;
      }
      return BOX_SIZE_WRAPPER.Vertical.Height;
    }
    if (typeDisplay === TYPE_DISPLAY.Vertical3Columns) {
      return boxType === BOX_TYPE.Question
        ? BOX_SIZE_WRAPPER.Default
        : BOX_SIZE_WRAPPER.Vertical3Columns.Height;
    }
    return 0;
  };

  const getBoxSizeClassName = () => {
    if (typeDisplay === TYPE_DISPLAY.Horizontal) return "mr-3 ml-3";
    if (typeDisplay === TYPE_DISPLAY.Vertical) return "mb-5";
    if (typeDisplay === TYPE_DISPLAY.Vertical3Columns) return "mb-1";
    return "";
  };

  const isNoConfigFontSize =
    !fontSizeContent || isNaN(parseInt(fontSizeContent));
  return (
    <Wrapper
      id={`box-match-${boxContent.id}`}
      style={{
        width: `${getBoxSizeWidth()}px`,
        height: `${getBoxSizeHeight()}px`,
        background: getColorBackground(isCorrect),
        pointerEvents: `${disabled ? "none" : "initial"}`,
      }}
      className={`${
        isActive ? "active" : "un-active"
      } box-item cursor text-center d-flex justify-content-center align-items-center ${getBoxSizeClassName()}`}
      onClick={onClickBox}
    >
      <ViewContentByType
        typeGame={typeGame}
        boxType={boxType}
        item={boxContent}
        typeText={typeText}
        fontSizeContent={isNoConfigFontSize ? 18 : fontSizeContent}
      />
    </Wrapper>
  );
};

export default BoxMatch;

function ViewContentByType({
  typeGame,
  boxType,
  item,
  typeText,
  fontSizeContent,
}) {
  const [imgClassName, setImgClassName] = useState("w-100");

  const handleLoadImage = (e) => {
    const { width, height } = e.target;
    const ratioImg = width / height;

    const boxMatchTarget = document.getElementById(`box-match-${item.id}`);
    const ratioBox = boxMatchTarget.offsetWidth / boxMatchTarget.offsetHeight;

    if (ratioImg >= ratioBox) {
      setImgClassName("w-100");
    } else {
      setImgClassName("h-100");
    }
  };

  if (boxType === BOX_TYPE.Question) {
    switch (typeGame) {
      case TYPE_GAME_MAT_001.Image_Text:
        return renderImageText(item, typeText, fontSizeContent);
      case TYPE_GAME_MAT_001.Image_Image:
      case TYPE_GAME_MAT_001.ImageText_Text:
        return (
          <Image
            className={`${imgClassName} ${styles["only-view"]}`}
            src={item.srcImage}
            alt="MAT_001-image"
            onLoad={handleLoadImage}
          />
        );
      case TYPE_GAME_MAT_001.Text_Text:
        return (
          <div
            className={`d-flex justify-content-center align-items-center w-100 h-100 ${styles["only-view"]}`}
          >
            <Typography
              text={item.contentText}
              typeText={typeText}
              fontSize={fontSizeContent}
            />
          </div>
        );
      default:
        return null;
    }
  }

  if (boxType === BOX_TYPE.Answer) {
    switch (typeGame) {
      case TYPE_GAME_MAT_001.Image_Text:
        return renderImageText(item, typeText, fontSizeContent);
      case TYPE_GAME_MAT_001.ImageText_Text:
      case TYPE_GAME_MAT_001.Text_Text:
        return (
          <div
            className={`d-flex justify-content-center align-items-center w-100 h-100 ${styles["only-view"]}`}
          >
            <Typography
              text={item.contentText}
              typeText={typeText}
              fontSize={fontSizeContent}
            />
          </div>
        );
      case TYPE_GAME_MAT_001.Image_Image:
        return (
          <Image
            className={`${imgClassName} ${styles["only-view"]}`}
            src={item.srcImage}
            alt="MAT_001-image"
            onLoad={handleLoadImage}
          />
        );
      default:
        return null;
    }
  }

  return null;
}

const renderImageText = (item, typeText, fontSizeContent) => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center img-text" >
      {item.srcImage ? (
        <Image
          className="h-100"
          src={item.srcImage}
          alt="MAT_001-image"
          maxWidth="100%"
        />
      ) : (
        <p
          className="h-100 d-flex align-items-center"
          style={{ fontSize: `${fontSizeContent || 24}px` }}
        >
          {item.contentText}
        </p>
      )}
    </div>
  );
};
const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  background-color: #ff7707;
  box-shadow: 2px 4px 10px 4px #d7d7d7;
  &.active {
    border: 2px solid #ff7707;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  &.un-active {
    border: 2px solid white;
  }
  .img-text{
    pointer-events: none;
  }
`;
