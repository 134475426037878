import React from "react";
import _ from "lodash";
import { Typography, ImageWithFullScreen, Video } from "../../components";
import { TYPE_DATA } from "../../constants";
import AudioPlayer from "react-h5-audio-player";
import { URL_AUDIO } from "edu_lms/constants/type";

export default function TitleQuestion({ data = [], gameConfig = [] }) {
  const { font_size, type_question } = gameConfig.game_config;
  return (
    <div className="title pr-3 pl-3 quicksand-bold">
      {_.includes(type_question, TYPE_DATA.TEXT) && (
        <Typography text={data.props[0].text} fontSize={font_size} />
      )}
      {_.includes(type_question, TYPE_DATA.IMAGE) && (
        <div className="d-flex justify-content-center">
          <ImageWithFullScreen
            src={data.path}
            alt="img-game-Find-coorect-mistakes"
            hasFullScreen
            maxHeight="300px"
          />
        </div>
      )}
      {_.includes(type_question, TYPE_DATA.AUDIO) &&
        data?.props[0]?.audio[0]?.path && (
          <div style={{ width: "35%", margin: "auto" }}>
            <AudioPlayer
              src={`${URL_AUDIO}${data?.props[0]?.audio[0]?.path}`}
              autoPlay={false}
            />
          </div>
        )}
      {_.includes(type_question, TYPE_DATA.VIDEO) && <Video src={data.path} />}
    </div>
  );
}
