import React from "react";
import { Redirect } from "react-router";
import * as PATH from "../../constants/path";
import NotFoundContainer from "../../modules/NotFound/containers";
import ExerciseContainer from "../../modules/DoingExercise/container";
import LoginPage from "edu_lms_v2/modules/Login/LoginPage";
import QuestionSetWrapper from "edu_lms_v2/modules/ControlDoingExercise";
import { ListStudentDoingHomeWork } from "edu_lms_v2/modules/TeachingManager/ListStudentDoingHomeWork/ListStudentDoingHomeWork";
import QuestionLibraryV2 from "edu_lms_v2/modules/QuestionLibraryV2";
import MockTest from "edu_lms_v2/modules/MockTest";
import ListExerciseResultDetail from "edu_lms_v2/modules/TeachingManager/ClassroomManager/ListExerciseResultDetail";
import HistoryExam from "edu_lms_v2/modules/HistoryExam";
import Classroom from "edu_lms_v2/modules/Classroom";
import ClassroomDetail from "edu_lms_v2/modules/Classroom/ClassroomDetail";

const env = process.env.REACT_APP_APP_ID;
const TUTORING_APP_ID = "69";

const commonRoutes = [
  {
    path: `${PATH.ROUTE_PATH_V3_SIGN_IN}`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: false,
    showFooter: false,
    main: ({ match }) => <LoginPage match={match} />,
  },
  {
    path: PATH.ROUTE_PATH_SIGN_IN,
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => (
      <Redirect
        to={{
          pathname: `${PATH.ROUTE_PATH_V3_SIGN_IN}`,
        }}
      />
    ),
  },
  {
    path: PATH.ROUTE_PATH_MY_STUDY,
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => <QuestionSetWrapper />,
  },
  {
    path: `${PATH.ROUTE_PATH_CLASSROOM}:idClassroom${PATH.ROUTE_PATH_EXERCISE_CLASSROOL}:questionSetId/:userId`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: false,
    showFooter: false,
    main: ({ match }) => <HistoryExam match={match} />,
  },
  {
    path: `${PATH.ROUTE_PATH_REPORT_HOMEWORK}:questionSetId/:userId`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: false,
    showFooter: false,
    main: ({ match }) => <HistoryExam match={match} />,
  },
  {
    path: PATH.ROUTE_PATH_CLASSROOM,
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => <Classroom />,
  },
  {
    path: `${PATH.ROUTE_PATH_CLASSROOM}:id`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: ({ match }) => <ClassroomDetail match={match} />,
  },
  {
    path: `${PATH.ROUTE_PATH_CLASSROOM}:idClassroom${PATH.ROUTE_PATH_EXERCISE_CLASSROOL}:questionSetId`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: ({ match }) => <ListExerciseResultDetail match={match} />,
  },
  {
    path: PATH.ROUTE_PATH_QUESTION_SET_V2,
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => <QuestionLibraryV2 />,
  },
  {
    path: `${PATH.ROUTE_PATH_EXERCISE}/:id`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: false,
    showFooter: false,
    main: ({ match }) => <ExerciseContainer match={match} />,
  },
  {
    path: `${PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST}:id`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: false,
    showFooter: false,
    main: ({ match }) => <ExerciseContainer match={match} />,
  },
];


const tutoringRoutes = [
  ...commonRoutes,
  {
    path: `${PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST}:id/:studentId`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: false,
    showFooter: false,
    main: ({ match }) => <ExerciseContainer match={match} />,
  },
  {
    path: PATH.ROUTE_TUTORING_HOMEWORK,
    accept: true,
    key: 2,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => <MockTest />,
  },
  {
    path: PATH.ROUTE_TUTORING_HOME,
    accept: true,
    key: 2,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => <Classroom />,
  },
  {
    path: `${PATH.ROUTE_PATH_LIST_STUDENT_DOING_HOMEWORK}:id`,
    accept: true,
    key: 1,
    exact: true,
    showHeader: false,
    showFooter: true,
    main: () => <ListStudentDoingHomeWork />,
  },
  {
    path: PATH.ROUTE_PATH_V3_TRAINING,
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => (
      <Redirect
        to={{
          pathname: `${PATH.ROUTE_TUTORING_HOME}`,
        }}
      />
    ),
  },
  {
    path: "",
    accept: true,
    key: 1,
    exact: true,
    showHeader: true,
    showFooter: true,
    main: () => <NotFoundContainer />,
  },
];

const routers = {
  [TUTORING_APP_ID]: tutoringRoutes,
};

export default routers[env];
