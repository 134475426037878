import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

export default function PopupLoginSuccess(props) {
  const history = useHistory();
  const handleContinute = () => {
    props.onHide();
    history.push(props.path);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success "
      backdrop="static"
      animation="true"
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img src="/assets/img/img-success.svg" alt="Đăng nhập thành công" />
        </div>
        <div className="text">Đăng nhập thành công</div>

        <div
          title="Tiếp tục"
          className="btn-pr flex-center btn-access cursor"
          onClick={() => handleContinute()}
        >
          Tiếp tục
        </div>
      </div>
    </Modal>
  );
}
