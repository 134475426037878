import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "edu_lms_v2/components/Loading";
import {
  getGrades,
  getSubjects,
  getListBookV4,
} from "edu_lms_v2/services/listBook";
import {
  DISABLED_GRADE,
  DISABLED_SUBJECT,
  SCHOOL_LEVEL_DEFAULT,
} from "./constant";
import { getMenuBook } from "edu_lms/services/readingBook";
import QuestionLibraryDetail from "./QuestionLibraryDetail";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import {
  CHOOSE_GRADE_MOCK_TEST,
  CHOOSE_SUBJECT_MOCK_TEST,
} from "edu_lms/constants/eventNameGTM";
import {
  ROUTE_PATH_QUESTION_SET_V2,
  ROUTE_PATH_V3_STUDENT_MOCK_TEST,
} from "edu_lms/constants/path";
import ComboBox from "edu_lms_v2/components/ComboBox";
import { GRADES, SUBJECTS_ID } from "edu_lms/constants/type";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import BetaImg from "edu_lms_v2/assets/img/beta.svg";
import SchoolLevel from "edu_lms_v2/components/SchoolLevel";
import { defaultGradeId, defaultSchool } from "../MockTest/constant";

const Img_beta = styled.img`
  width: 50px;
  @media (max-width: 668px) {
    width: 35px;
  }
`;

export default function QuestionLibraryV2() {
  const [books, setBooks] = useState([]);
  const [noBook, setNoBook] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valueSchool, setValueSchool] = useState(defaultSchool);
  const [showDetailExercise, setShowDetailExercise] = useState(false);
  const [isShowFilterMobile, setIsShowFilterMobile] = useState(false);
  const [questionDetail, setQuestionDetail] = useState([]);
  const history = useHistory();
  const [grade, setGrade] = useState({
    id: [GRADES.lop1],
    currentGrade: "Lớp 1",
    listGrade: [],
  });
  const [subject, setSubject] = useState({
    id: [SUBJECTS_ID.tieng_viet],
    currentSubject: "Tiếng Việt",
    listSubject: [],
  });

  const handleShowBook = (gradeId, subjectId) => {
    setBooks([]);
    if (!gradeId && !subjectId) return setNoBook(true);

    setLoading(true);
    getListBookV4(gradeId.toString(), subjectId.toString())
      .then((res) => {
        const data = res.data.data;
        setBooks(data.total ? data.list_book : []);
        setNoBook(data.total ? false : true);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });

    setShowDetailExercise(false);
  };

  useEffect(() => {
    const block = SCHOOL_LEVEL_DEFAULT.find(
      (item) => item.schoolLevel === valueSchool.value
    );

    getGrades(valueSchool.value || defaultGradeId).then((res) => {
      const initGrade = res.data.data;
      const checkedGrade = initGrade.find(
        ({ id }) => id === block.gradeIdDefault
      );
      setGrade({
        id: [checkedGrade?.id],
        currentGrade: checkedGrade?.name,
        listGrade: initGrade,
      });
    });

    getSubjects(valueSchool.value).then((res) => {
      const initSubject = res.data?.data?.list_subject.filter(
        ({ id }) => !DISABLED_SUBJECT.includes(id)
      );
      const checkedSubject = initSubject.find(
        ({ id }) => id === block.subjectIdDefault
      );
      setSubject({
        id: [checkedSubject?.id],
        currentSubject: checkedSubject?.title,
        listSubject: initSubject,
      });
    });

    handleShowBook(block.gradeIdDefault, block.subjectIdDefault);
  }, [valueSchool]);



  const openDetailExercises = (book_id) => {
    setLoading(true);
    getMenuBook({ book_id })
      .then((res) => setQuestionDetail(res.data.data))
      .catch((e) => console.log(console.log(e)))
      .finally(() => {
        setLoading(false);
      });
    setShowDetailExercise(true);
  };
  const handleShowExam = (id) => {
    const currentURL = window.location.href;
    if (currentURL.includes("luyen-tap")) {
      history.push(`${ROUTE_PATH_QUESTION_SET_V2}${id}`);
    } else {
      history.push(`${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${id}`);
    }
  };

  const onChangeGrade = (gradeId) => {
    const currentGrade = grade.listGrade.find(({ id }) => id === gradeId);
    setGrade({
      ...grade,
      id: [currentGrade?.id],
      currentGrade: currentGrade?.name,
    });
    handleShowBook(gradeId, subject.id);

    setEventGTM({
      event: CHOOSE_GRADE_MOCK_TEST,
      grade_name: grade.currentGrade,
    });
  };

  const onChangeSubject = (subjectId) => {
    const currentSubject = subject.listSubject.find(
      ({ id }) => id === subjectId
    );
    setSubject({
      ...subject,
      id: [currentSubject?.id],
      currentSubject: currentSubject?.title,
    });
    handleShowBook(grade.id, subjectId);

    setEventGTM({
      event: CHOOSE_SUBJECT_MOCK_TEST,
      subject_name: subject.currentSubject,
    });
  };

  const controll = (
    <div className="filter">
      <SchoolLevel setValueSchool={setValueSchool} valueSchool={valueSchool} />
      <div className="filter__item">
        <h4>Lớp học</h4>
        <ComboBox
          data={grade.listGrade}
          onChange={onChangeGrade}
          checkedItems={grade.id}
          disabledItems={DISABLED_GRADE}
        />
      </div>

      <div className="filter__item">
        <h4>Môn học</h4>
        <ComboBox
          data={subject.listSubject}
          onChange={onChangeSubject}
          checkedItems={subject.id}
          disabledItems={DISABLED_SUBJECT}
        />
      </div>
    </div>
  );

  return (
    <main>
      <SEO data={pages.mock_test} />
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={""}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Luyện tập
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-search bg-gr">
        <div className="header pr">
          <h1 className="title d-flex justify-content-center">
            Luyện tập{" "}
            <span className="ml-2 ">
              <Img_beta src={BetaImg} alt="beta" />
            </span>
          </h1>

          <p className="txt-5 mb-2">
            Để giúp học sinh ôn luyện kiến thức chuẩn bị cho kỳ thi học kỳ 2,
            Hoc10 xin giới thiệu tính năng "Luyện tập". Hy vọng bản thử nghiệm
            "Luyện tập" sẽ đem đến cho thầy cô và học sinh những trải nghiệm tốt
            nhất để ôn tập lại kiến thức đã học. Hoc10 sẽ thường xuyên cập nhật
            nội dung để đa dạng thêm các loại hình ôn luyện. Rất mong sự đóng
            góp và ủng hộ của quý Thầy cô và các em học sinh.
          </p>
        </div>
      </div>
      <div className="hoc10-search-result">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">{controll}</div>
            {!showDetailExercise ? (
              <div className="col-lg-9 col-md-8">
                <div className="result">
                  <div
                    className="result__header flex"
                    style={{ margin: "0", border: "none" }}
                  >
                    <div className="fil fil--sort flex-lc">
                      <button
                        type="button"
                        className="btn_filter_m m-s flex-lc tf2 m-0"
                        onClick={() =>
                          setIsShowFilterMobile(!isShowFilterMobile)
                        }
                      >
                        Bộ lọc <i className="icon icon-filter"></i>
                      </button>
                    </div>
                  </div>
                  <p className="pb-3 text-uppercase h2 monkey-color-orange">
                    {subject.currentSubject} {grade.currentGrade}
                  </p>
                  {noBook && (
                    <h1 className="monkey-color-orange h4 text-center font-weight-bold d-block mt-5 pt-5">
                      ĐANG CẬP NHẬT
                    </h1>
                  )}
                  {!loading ? (
                    <div className="row">
                      
                    </div>
                  ) : (
                    <Loading />
                  )}
                </div>
              </div>
            ) : (
              <QuestionLibraryDetail
                questionDetail={questionDetail}
                handleShowExam={handleShowExam}
                listGrade={grade.listGrade}
                listSubject={subject.listSubject}
              />
            )}
          </div>
        </div>
      </div>
      {isShowFilterMobile && (
        <div className={`filter-fix-left ${isShowFilterMobile ? "show" : ""}`}>
          <div className="logo">
            <img src="/assets/img/updated_logo.png" alt="Logo Hoc10" />
          </div>
          <h3>Bộ lọc</h3>
          <button
            type="button"
            className="close"
            onClick={() => setIsShowFilterMobile(!isShowFilterMobile)}
          ></button>
          {controll}
          <button
            type="button"
            className="btn_apply btn-pr flex-center"
            onClick={() => setIsShowFilterMobile(!isShowFilterMobile)}
          >
            Áp Dụng
          </button>
        </div>
      )}
    </main>
  );
}
