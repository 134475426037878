import React from "react";
import { onChangeColor } from "../../helpers/Word_Search";

export default function QuestionMatrix({
  girds,
  onChooseCell,
  fontSizeAnswer,
  fontSizeAnswerPx,
  isFirework,
  showCorrectAnswer,
}) {
  return (
    <div className="gird-wrapper-v1">
      <table style={{ borderSpacing: "10px" }}>
        <tbody>
          {girds?.map((row, iRow) => (
            <tr key={iRow}>
              {row.map((cell, _iCell) => (
                <td
                  key={_iCell}
                  onClick={() => onChooseCell(iRow, _iCell)}
                  className="cell-v1 noselect"
                  style={{
                    width: `${fontSizeAnswer < 30 ? "45px" : "65px"}`,
                    height: `${fontSizeAnswer < 30 ? "45px" : "65px"}`,
                    background: onChangeColor(cell, showCorrectAnswer),
                  }}
                >
                  <span
                    style={{
                      color: `${cell.status === 3 ? "#fff" : "black"}`,
                      fontSize: `${
                        fontSizeAnswerPx ? fontSizeAnswerPx : "18px"
                      }`,
                    }}
                  >
                    {cell.text}
                  </span>
                  {isFirework && cell.check && (
                    <div className="pyro">
                      <div className="before" />
                      <div className="after" />
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
