import React from "react";
import styled from "styled-components";
import Sentence from "./Sentence";
import "./styles.scss";

const ContentAnswer = ({
  answer,
  question,
  selectionAreas,
  isCheckedAnswer = false,
  hideResultAnswer = false,
  onSelectAnswer = () => {},
}) => {
  const { typeAnswer, listSentences, numberOfAnswersInRow } = answer;

  return (
    <ContentAnswerWrapper
      columns={numberOfAnswersInRow}
      className="quicksand-medium p-4"
    >
      {listSentences.map((sentence, sentenceIndex) => {
        return (
          <Sentence
            key={sentenceIndex}
            sentence={sentence}
            question={question}
            typeAnswer={typeAnswer}
            selectionAreas={selectionAreas}
            isCheckedAnswer={isCheckedAnswer}
            hideResultAnswer={hideResultAnswer}
            onSelectAnswer={onSelectAnswer}
          />
        );
      })}
    </ContentAnswerWrapper>
  );
};

export default ContentAnswer;

const ContentAnswerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 1}, 1fr);
  grid-gap: 1rem;
`;
