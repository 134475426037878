import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { INNER_WIDTH } from "edu_lms/constants/type";
import {
  createBoard,
  getAnswerColor,
  getAnswerColorPoint,
  convertCoordinatesToInteger,
} from "../../helpers/graph";
import { drawRegionInequalityWithPointInput } from "../../helpers/SROI_001";
import { POINT_EVENT, GRAPH_OBJECT } from "../../constants/Graph";

const GraphShow = ({
  coefficients,
  selectedCoordinates,
  inputtedCoordinates,
  coor,
  hideResultAnswer = false,
  isView = false,
  onStopTouchPoints = () => {},
}) => {
  const boardId = `jxgBoxSROI-${Math.random()}`;
  const lineIndexRef = useRef(null);

  const drawGraphOfFirstDegreeEquation = ({
    selectedPoints,
    lineIndex,
    board,
  }) => {
    const { x: xA, y: yA, isCorrect: isCorrectA } = selectedPoints[lineIndex].pointA;
    const pointA = board.create(GRAPH_OBJECT.Point, [xA, yA], {
      name: "",
      strokeColor: getAnswerColorPoint(hideResultAnswer ? null : isCorrectA),
      color: getAnswerColorPoint(hideResultAnswer ? null : isCorrectA),
    });

    const { x: xB, y: yB, isCorrect: isCorrectB } = selectedPoints[lineIndex].pointB;
    const pointB = board.create(GRAPH_OBJECT.Point, [xB, yB], {
      name: "",
      strokeColor: getAnswerColorPoint(hideResultAnswer ? null : isCorrectB),
      color: getAnswerColorPoint(hideResultAnswer ? null : isCorrectB),
    });

    pointA.on(POINT_EVENT.Up, () => {
      const newCoorPointA = convertCoordinatesToInteger(pointA);
      pointA.moveTo(newCoorPointA);

      selectedPoints[lineIndex].pointA = { x: newCoorPointA[0], y: newCoorPointA[1] };
      lineIndexRef.current = lineIndex;

      onStopTouchPoints(lineIndex);
    });

    pointB.on(POINT_EVENT.Up, () => {
      const newCoorPointB = convertCoordinatesToInteger(pointB);
      pointB.moveTo(newCoorPointB);

      selectedPoints[lineIndex].pointB = { x: newCoorPointB[0], y: newCoorPointB[1] };
      lineIndexRef.current = lineIndex;

      onStopTouchPoints(lineIndex);
    });

    const lineName = coefficients.length > 1 ? `d${lineIndex + 1}` : "d";
    const isCorrect = selectedPoints[lineIndex].isCorrect;
    const line = board.create(GRAPH_OBJECT.Line, [pointA, pointB], {
      strokeColor: getAnswerColor(hideResultAnswer ? null : isCorrect),
      withLabel: true,
      name: lineName,
      label: { offset: [0, -3] },
    });
    line.isDraggable = false;
  };

  /**----------------------------------------------------------------------------**
   * Create board
   * Draw graph
   * Draw region of inequality
   ** --------------------------------------------------------------------------- **/
  useEffect(() => {
    const { negativeX, positiveY, positiveX, negativeY } = coor;
    // Create board
    const board = createBoard({
      boardId,
      boundingBox: [negativeX, positiveY, positiveX, negativeY],
      enabledDrag: true,
    });

    // Draw graph
    for (let i = 0; i < selectedCoordinates.length; i++) {
      drawGraphOfFirstDegreeEquation({
        selectedPoints: selectedCoordinates,
        lineIndex: i,
        board,
      });
    }

    // Draw region of inequality
    inputtedCoordinates.forEach((inputtedCoordinate, index) => {
      const isFillCoordinatesPoint = inputtedCoordinate.x && inputtedCoordinate.y;
      const selectedCoordinate = selectedCoordinates[index];
      if (isFillCoordinatesPoint && selectedCoordinate) {
        drawRegionInequalityWithPointInput({
          selectedCoordinate,
          inputtedCoordinate,
          coor,
          board,
          lineIndex: index,
          hideResultAnswer,
        });
      }
    });
  }, [coor, inputtedCoordinates, selectedCoordinates]);

  return (
    <GraphBoard>
      <div id={boardId} className="graph" />
      {isView && <div className="position-absolute w-100 h-100 overlay" />}
    </GraphBoard>
  );
};

export default GraphShow;

const GraphBoard = styled.div`
  position: relative;
  width: max-content;
  margin: 0 auto;
  .graph {
    width: ${window.innerWidth > INNER_WIDTH.MOBILE ? "400px" : "100%"};
    height: ${window.innerWidth > INNER_WIDTH.MOBILE
      ? "400px"
      : `${window.innerWidth}px`};
  }
  .overlay {
    background-color: transparent;
    top: 0;
  }
`;
