import SEO from "edu_lms/components/Seo";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "edu_lms_v2/components/Loading";
import { getGrades, getSubjects } from "edu_lms_v2/services/listBook";
import {
  DEFAULT_GRADE_ID,
  DEFAULT_SUBJECT_ID,
  SCHOOL_LEVEL_DEFAULT,
  TYPE_MOCK_TEST,
  currentSchoolId,
} from "./constant";
import { getListExam } from "edu_lms/services/readingBook";
import QuestionLibraryDetail from "./QuestionLibraryDetail";
import {  MODE_FILTER_HIDE } from "edu_lms/constants/type";
import { pages } from "edu_lms/components/Seo/meta";
import { ROUTE_PATH_V3_STUDENT_MOCK_TEST } from "edu_lms/constants/path";
import { onUpdateMockTestFilter } from "./actions";
import SchoolLevel from "edu_lms_v2/components/SchoolLevel";
import ComboBox from "edu_lms_v2/components/ComboBox";
import {
  DISABLED_GRADE,
  DISABLED_SUBJECT,
} from "../QuestionLibraryV2/constant";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { OPEN_TEST } from "edu_lms/constants/eventNameGTM";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function MockTest() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isShowFilterMobile, setIsShowFilterMobile] = useState(false);
  const [questionDetail, setQuestionDetail] = useState([]);
  const { list } = useSelector((state) => state.mockTestReducers);
  const dispatch = useDispatch();
  const history = useHistory();
  const [grade, setGrade] = useState({
    id: [DEFAULT_GRADE_ID],
    currentGrade: "Lớp 1",
    listGrade: [],
  });
  const [subject, setSubject] = useState({
    id: [DEFAULT_SUBJECT_ID],
    currentSubject: "Tiếng Việt",
    listSubject: [],
  });

  const onChangeBlock = (valueSchool, gradeId, subjectId) => {
    getGrades(valueSchool).then((res) => {
      const initGrade = res.data.data.map((item) => ({
        ...item,
        isChecked: Boolean(item.id === gradeId),
      }));

      const checkedGrade = initGrade.find(
        ({ isChecked }) => isChecked === true
      );

      setGrade({
        id: [checkedGrade?.id],
        currentGrade: checkedGrade?.name,
        listGrade: initGrade,
      });
    });

    getSubjects(valueSchool).then((res) => {
      const initSubject = res.data?.data?.list_subject.map((item) => ({
        ...item,
        isChecked: Boolean(item.id === subjectId),
      }));

      const checkedSubject = initSubject.find(
        ({ isChecked }) => isChecked === true
      );

      setSubject({
        id: [checkedSubject?.id],
        currentSubject: checkedSubject?.title,
        listSubject: res.data?.data?.list_subject,
      });
    });
  };

  const handleShowQuestion = (gradeId, subjectId) => {
    setQuestionDetail([]);
    if (gradeId && subjectId) {
      setLoading(true);
      getListExam({ type: TYPE_MOCK_TEST, gradeId, subjectId })
        .then((res) => setQuestionDetail(res.data.data))
        .catch((e) => console.log(console.log(e)))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    onChangeBlock(list.block, list.gradeId, list.subjectId);
    handleShowQuestion(list.gradeId, list.subjectId);
  }, []);

  const handleChangeValueSchool = (option) => {
    const block = SCHOOL_LEVEL_DEFAULT.find(
      (item) => item.schoolLevel === option.value
    );

    dispatch(
      onUpdateMockTestFilter({
        ...list,
        block: block.schoolLevel,
        gradeId: block.gradeIdDefault,
        subjectId: block.subjectIdDefault,
      })
    );
    onChangeBlock(option.value, block.gradeIdDefault, block.subjectIdDefault);
    handleShowQuestion(block.gradeIdDefault, block.subjectIdDefault);
  };

  const onChangeGrade = (gradeId) => {
    const currentGrade = grade.listGrade.find(({ id }) => id === gradeId);
    setGrade({
      ...grade,
      id: [currentGrade?.id],
      currentGrade: currentGrade?.name,
    });
    dispatch(onUpdateMockTestFilter({ ...list, gradeId }));
    handleShowQuestion(gradeId, list.subjectId);
  };

  const onChangeSubject = (subjectId) => {
    const currentSubject = subject.listSubject.find(
      ({ id }) => id === subjectId
    );
    setSubject({
      ...subject,
      id: [currentSubject?.id],
      currentSubject: currentSubject?.title,
    });

    dispatch(onUpdateMockTestFilter({ ...list, subjectId }));
    handleShowQuestion(list.gradeId, subjectId);
  };

  const schoolFilter = (
    <SchoolLevel
      setValueSchool={handleChangeValueSchool}
      valueSchool={currentSchoolId(list.block)}
    />
  );

  const gradeFilter = (
    <div className="filter__item">
      <h4>{t("classroom")}</h4>
      <ComboBox
        data={grade.listGrade}
        onChange={onChangeGrade}
        checkedItems={grade.id}
        disabledItems={DISABLED_GRADE}
        modeDisabled={MODE_FILTER_HIDE}
      />
    </div>
  );

  const subjectFilter = (
    <div className="filter__item">
      <h4>{t("subject")}</h4>
      <ComboBox
        data={subject.listSubject}
        onChange={onChangeSubject}
        checkedItems={subject.id}
        disabledItems={DISABLED_SUBJECT}
        modeDisabled={MODE_FILTER_HIDE}
      />
    </div>
  );

  const handleShowExam = ({ id, title }) => {
    history.push(`${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${id}`);
    setEventGTM({
      event: OPEN_TEST,
      test_name: title,
      test_grade: grade.currentGrade,
      test_subject: subject.currentSubject,
    });
  };

  useScrollToTop();


  return (
    <main id="test-preparation">
      <SEO data={pages.mock_test} />
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={""}>{t("home")}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {t("test preparation")}
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-search bg-gr">
        <div className="header pr">
          <h1 className="title d-flex justify-content-center">Luyện thi </h1>
          <p className="txt-5 mb-2">
            Luyện thi - tập hợp những bộ đề ôn luyện với nội dung bám sát chương
            trình sách giáo khoa Cánh Diều, được sắp xếp theo lộ trình học, giúp
            củng cố kiến thức cho học sinh. Học sinh có thể làm trực tuyến, biết
            kết quả chi tiết từng câu hỏi, xem được điểm số qua từng lần luyện
            tập để theo dõi được sự tiến bộ. Hoc10 hy vọng phần ôn luyện này sẽ
            giúp học sinh tối ưu thời gian để đạt được kết quả cao nhất.
          </p>
        </div>
      </div>
      <div className="hoc10-search-result">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="filter">
                {schoolFilter}
                {gradeFilter}
                {subjectFilter}
              </div>
              <div className="result">
                <div
                  className="result__header flex"
                  style={{ margin: "0", border: "none" }}
                >
                  <div className="fil fil--sort flex-lc">
                    <button
                      type="button"
                      className="btn_filter_m m-s flex-lc tf2 m-0"
                      onClick={() => setIsShowFilterMobile(true)}
                    >
                      Bộ lọc <i className="icon icon-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="filter-result">
                  <div><i className="fa fa-check icon"></i> {grade.currentGrade}</div>
                  <div><i className="fa fa-check icon"></i> {subject.currentSubject}</div>
                </div>
              </div>
            </div>
            {!loading ? (
              <QuestionLibraryDetail
                questionDetail={questionDetail}
                handleShowExam={handleShowExam}
                listGrade={grade.listGrade}
                listSubject={subject.listSubject}
              />
            ) : (
              <div className="col-lg-9 col-md-8 d-flex justify-content-center">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
      {isShowFilterMobile && (
        <div className={`filter-fix-left ${isShowFilterMobile ? "show" : ""}`}>
          <div className="logo">
            <img src="/assets/img/updated_logo.png" alt="Logo Hoc10" />
          </div>
          <h3>Bộ lọc</h3>
          <button
            type="button"
            className="close"
            onClick={() => {
              setIsShowFilterMobile(!isShowFilterMobile);
              onChangeBlock(list.block, list.gradeId, list.subjectId);
            }}
          ></button>
          <div className="filter-condition">
            {schoolFilter}
            {gradeFilter}
            {subjectFilter}
          </div>
          <button
            type="button"
            className="btn_apply btn-pr flex-center"
            onClick={() => {
              setIsShowFilterMobile(!isShowFilterMobile);
              onChangeBlock(list.block, list.gradeId, list.subjectId);
            }}
          >
            Áp Dụng
          </button>
        </div>
      )}
    </main>
  );
}
