import { useHistory } from "react-router-dom";
import { ROUTE_PATH_CLASSROOM } from "edu_lms/constants/path";
import Modal from "react-bootstrap/Modal";

export default function LoginSuccessModal(props) {
  const history = useHistory();

  const handleContinute = () => {
    props.onHide();
    history.push(ROUTE_PATH_CLASSROOM);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success "
      backdrop="static"
      animation="true"
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img src="/assets/img/img-success.svg" alt="Đăng nhập thành công" />
        </div>
        <div className="text">Đăng nhập thành công</div>
          <div
            title="Tiếp tục"
            className="btn-pr flex-center btn-access cursor"
            onClick={handleContinute}
          >
            Tiếp tục
          </div>
      </div>
    </Modal>
  );
}
