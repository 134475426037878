import { useState, useEffect } from "react";
import styles from "../StudentExercises.module.scss";
import * as types from "edu_lms/constants/type";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "edu_lms_v2/components/Pagination";
import { onDispatchSetCurrentPageClassroom } from "../../TeachingManager/actions";
import { getStudentExer } from "edu_lms/services/teachingManager";
import { getListSubject } from "edu_lms/services/app/app";
import { useHistory } from "react-router-dom";
import * as PATH from "edu_lms/constants/path";

export default function CompletedResult({ classroomInfo, classroomId }) {
  const [listSubjects, setlistSubjects] = useState([]);
  const [totalSubject, setTotalSubject] = useState(0);
  const [subjectSelect, setSubjectSelect] = useState();
  const [subjectOption, setSubjectOption] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectSchoolYear = useSelector(
    (state) => state.teachingReducer.schoolYearSelect
  );
  const handleChangePage = (page) => {
    dispatch(onDispatchSetCurrentPageClassroom(page));
  };

  const getStudentSubject = () => {
    const data = {
      classroomId: classroomId,
      roleId: types.ROLE_STUDENT,
      limit: types.TOTAL_EXERCISE,
      done: types.TYPE_VIETNAMESE,
      page: currentPage,
      subjectId: subjectSelect?.id || "",
      gradeId: classroomInfo?.grade_id,
    };
    getStudentExer(data)
      .then((res) => {
        if (res.data.status === "success") {
          setlistSubjects(res.data.data.list);
          setTotalSubject(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStudentSubject();
    setTotalNumber((currentPage - 1) * types.TOTAL_EXERCISE);
  }, [
    currentPage,
    selectSchoolYear,
    subjectSelect,
    classroomInfo,
    totalNumber,
  ]);

  useEffect(() => {
    if (listSubjects.length === 0) {
      handleChangePage(1);
    }
  }, [listSubjects]);
  useEffect(() => {
    getListSubject(classroomInfo.grade_id).then((res) => {
      if (res.data.status === "success") {
        const list = [];
        res.data.data.list_subject.forEach((value, index) => {
          list.push({
            id: value.id,
            label: value.title,
            value: `file_${value.id}`,
            type: value.id,
          });
        });
        setSubjectOption(list);
      }
    });
  }, [classroomInfo]);
  const onClickHistory = (userId, questionSetId) => {
    history.push(
      `${PATH.ROUTE_PATH_V3_HISTORY_EXAM}${userId}/${questionSetId}`
    );
  };

  return (
    <div className="row pt-3 pt-5">
      <div className="col-6">
        <div>
          <p className="font-weight-bold monkey-color-back-reading-book hoc10-f-header monkey-fz-20 ">
            {listSubjects.length > 0 ? (
              <div>
                Đã hoàn thành <span>{`(${totalSubject})`}</span>
              </div>
            ) : (
              <div>Đã hoàn thành (0)</div>
            )}
          </p>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-6">
        <div className={`mr-auto mb-2 float-right d-flex ${styles.select}`}>
          <Select
            placeholder="Chọn môn"
            options={subjectOption}
            value={subjectSelect}
            onChange={(e) => setSubjectSelect(e)}
          />
        </div>
      </div>
      <table className={`table text-center ${styles.table}`}>
        <thead>
          <tr>
            <th scope="col border-right border-dark">STT</th>
            <th scope="col border-right border-dark">Môn</th>
            <th scope="col border-right border-dark">Bài tập</th>
            <th scope="col border-right border-dark">Trạng thái</th>
            <th scope="col">Kết quả</th>
          </tr>
        </thead>
        <tbody>
          {listSubjects.length > 0 ? (
            listSubjects.map((infor, index) => {
              const userId = infor?.user_id;
              const questionSetId = infor?.id;
              return (
                <tr key={index}>
                  <th className="th" scope="row">
                    {totalNumber + index + 1}
                  </th>
                  <td>{infor.subject_name}</td>
                  <td>{infor.title}</td>
                  {infor.deadline > infor.date_done ? (
                    <td>Đúng hạn</td>
                  ) : (
                    <td>Quá hạn</td>
                  )}
                  <td className="position-relative">
                    {infor.total_correct}/{infor.activities_count}
                    <button
                      onClick={() => onClickHistory(userId, questionSetId)}
                    >
                      <span className={styles.detail}>Chi tiết</span>
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className={`mx-auto my-5  ${styles.addClass}`}>
              <td className="font-weight-bold"></td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={styles.page}>
        <div className="mb-4">
          {totalSubject > types.TOTAL_EXERCISE && (
            <Pagination
              total={totalSubject}
              totalPerPage={types.TOTAL_EXERCISE}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
