import React from "react";
import classNames from "classnames";
import _ from "lodash";
import {
  Audio,
  AudioType,
  Typography,
  ImageWithFullScreen,
} from "../../components";
import { TYPE_DATA } from "../../constants";

// For AI TTR
const HeaderQuestion = ({ question, isReadOnly }) => {
  const { typeQuestion, contentQuestion, contentQuestionType, typeGame } = question;
  return (
    <div
      className={classNames("mt-2 mb-3 quicksand-medium", {
        "pointer-none": isReadOnly,
      })}
    >
      <div className="d-flex justify-content-start mb-2">
        {_.includes(typeQuestion, TYPE_DATA.AUDIO) && (
          <div className="ml-2 d-flex justify-content-start header__audio-wrapper">
            <Audio variant={AudioType.Primary} src={contentQuestion.srcAudio} isReadOnly={isReadOnly}/>
          </div>
        )}
        {_.includes(typeQuestion, TYPE_DATA.TEXT) && (
          <div className="header__text-wrapper">
            <Typography
              fontSize={contentQuestion.text.fontSize}
              typeText={contentQuestion.text.typeText}
              text={contentQuestion.text.contentText}
            />
          </div>
        )}
      </div>
      {/* typeGame = 1: image + audio */}
      {typeGame == 1 && (
        <div className="d-flex justify-content-center align-items-start">
          <ImageWithFullScreen
            src={contentQuestionType.srcImage}
            alt="select answer image"
            hasFullScreen={true}
            isTTR={true}
            srcAudio={contentQuestionType.srcAudio}
            isReadOnly={isReadOnly}
          />
        </div>
      )}
      {/* typeGame = 2: text + audio */}

      {typeGame == 2 && (
        <div className="mr-5 monkey-fz-20 d-flex justify-content-center align-items-center">
          <Audio
            variant={AudioType.Primary}
            src={contentQuestionType.srcAudio}
            isReadOnly={isReadOnly}
          />
          <p className="monkey-fz-24 ml-3">
            {contentQuestionType.text.contentText}
          </p>
        </div>
      )}

      {/* typeGame = 3: audio */}

      {typeGame == 3 && (
        <div className="d-flex justify-content-center mr-4">
          <Audio
            variant={AudioType.Primary}
            src={contentQuestionType.srcAudio}
            isReadOnly={isReadOnly}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderQuestion;
