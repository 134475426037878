import classNames from "classnames";
import { MODE_FILTER_DISABLED, MODE_FILTER_HIDE } from "edu_lms/constants/type";
import styled from "styled-components";

const ListCheckbox = styled.label`
  &.disable {
    pointer-events: none;
    color: #eee;
  }
`;

export default function ComboBox({
  data,
  onChange,
  checkedItems,
  disabledItems = [],
  modeDisabled = MODE_FILTER_DISABLED,
}) {
  return (
    <div className="filter__item__list">
      {modeDisabled === MODE_FILTER_DISABLED &&
        data.map((item, index) => {
          return (
            <ListCheckbox
              key={index}
              className={classNames("flex-lc pr", {
                disable: disabledItems.includes(item.id),
              })}
            >
              <input
                id={item.id}
                type="radio"
                onChange={() => onChange(item.id)}
                checked={checkedItems.includes(item.id)}
              />
              <span className="checked"></span>
              <p>{item.name ?? item.title}</p>
            </ListCheckbox>
          );
        })}

      {modeDisabled === MODE_FILTER_HIDE &&
        data
          .filter((item) => !disabledItems.includes(item.id))
          .map((item, index) => {
            return (
              <ListCheckbox key={index} className="flex-lc pr">
                <input
                  id={item.id}
                  type="radio"
                  onChange={() => onChange(item.id)}
                  checked={checkedItems.includes(item.id)}
                />
                <span className="checked"></span>
                <p>{item.name ?? item.title}</p>
              </ListCheckbox>
            );
          })}
    </div>
  );
}
