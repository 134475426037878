import React from "react";
import { Typography } from "../../components";
import { TYPE_DATA } from "../../constants";

export default function HeaderQuestion({ gameConfig, content = {} }) {
  const { font_size, type_question } = gameConfig;

  return (
    <div className="pl-4">
      {type_question.includes(TYPE_DATA.TEXT) && (
        <Typography
          text={content.props[0].text}
          fontSize={font_size}
          className="quicksand-medium"
        />
      )}
    </div>
  );
}
