import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dayjs from "edu_lms/libs/dayjs";
import { displayDuration } from "edu_lms/constants/type";
import PopUpAssignHomeWork from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignHomeWork";
import styles from "./PopupShowHistory.module.scss";
import { ShareAndCopy } from "edu_lms_v2/components/ShareAndCopy";

export default function PopupShowHistoryExam({
  showHistoryExam,
  setShowHistoryExam,
  handleShowExam,
  keyPopup,
  defineAccount,
  checked,
  setChecked,
  listClassroom,
  onSubmit,
  getListClassroom,
}) {
  const { t } = useTranslation();

  const [exerciseId, setExerciseId] = useState(keyPopup.id);
  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => {
    setShowHistoryExam(false);
  };

  const handleClickAssign = () => {
    setExerciseId(exerciseId);
    setShowPopup(!showPopup);
    getListClassroom();
  };
  const url = window.location.href;
  const urlShareFb = `${url}${keyPopup.id}`;
  return (
    <>
      <Modal
        show={showHistoryExam}
        onHide={handleClose}
        centered={true}
        className="monkey-color-black"
        size="lg"
      >
        <Modal.Header closeButton className={`border-0 ${styles.title}`}>
          <p className="h3 w-100 text-center">{keyPopup.title}</p>
        </Modal.Header>
        <Modal.Body className="border-0 text-center p-0">
          <div
            className={`d-flex ${styles.titleGradeAndSubject}`}
            style={{ justifyContent: "space-evenly" }}
          >
            <div className="mr-5 monkey-fz-20 ">
              {keyPopup.currentGrade?.name}
            </div>
            <div className="monkey-fz-20">
              Môn: {keyPopup.currentSubject?.title}
            </div>
          </div>

          <>
            <div className="mt-3 mb-3 monkey-fz-20 ">{t("history")}</div>

            <div style={{ maxHeight: "380px", overflowY: "auto" }}>
              <div className={styles.table}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>{t("start doing")}</th>
                      <th>{t("time spent")}</th>
                      <th>{t("result")}</th>
                      <th>{t("score")}</th>
                    </tr>
                  </thead>

                  {keyPopup.dataHistory?.length > 0 ? (
                    keyPopup.dataHistory?.map((data, index) => {
                      const timeEnd = dayjs.unix(data?.time_end);
                      const timeStart = dayjs.unix(data?.time_start);
                      const durationTime = dayjs.duration(
                        timeEnd.diff(timeStart)
                      );
                      const dateTimeStart =
                        timeStart.format("DD-MM-YYYY, HH:mm");

                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{dateTimeStart}</td>
                            <td>{displayDuration(durationTime)}s</td>
                            <td>
                              {data.total_correct} / {data.total_question}
                            </td>
                            <td>{data.mark}</td>
                          </tr>
                        </tbody>
                      );
                    })
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={5}>{t("no history")}</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className=" btn-sub" onClick={handleClose}>
            {t("close")}
          </Button>
          <Button className=" btn-pr" onClick={() => handleShowExam(keyPopup)}>
            {t("do homework")}
          </Button>
          {defineAccount === 1 && (
            <Button className=" btn-pr" onClick={() => handleClickAssign()}>
              {t("assign")}
            </Button>
          )}

          <ShareAndCopy
            title={keyPopup.title}
            urlShareFb={urlShareFb}
            questionSetId={keyPopup.id}
          />
        </Modal.Footer>
      </Modal>

      <PopUpAssignHomeWork
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        listClassroom={listClassroom}
        onSubmit={onSubmit}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
}

const Button = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 7px;
  /* color: white; */
`;
