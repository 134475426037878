import { Modal } from "react-bootstrap";
import error_img from "edu_lms_v2/assets/img/img-error.svg";
import styled from "styled-components";

const ButtonStyle = styled.button`
  &:hover {
    transform: translateY(-4px);
    box-shadow: 4px 4px 10px 10px #eee;
  }

  &:active {
    transform: translateY(0);
  }
`

export default function PopupActiveQuestionSet({ show, setShow, setShowLogin }) {
  return (
    <Modal show={show} onHide={setShow} centered>
      <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0">
        <img src={error_img} alt="#" className="w-100" />
        <div className="mb-3 pt-5">
          <p className="text-center monkey-fz-18 font-weight-bold">
            Vui lòng đăng nhập để làm đề ôn luyện.
          </p>

          <div className="w-100 d-flex justify-content-center mt-3">
            <ButtonStyle
              onClick={() => {setShowLogin(true); setShow(false)}}
              className="font-weight-bold text-white monkey-bg-orange rounded px-4 py-2"
            >
              Đăng nhập
            </ButtonStyle>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
