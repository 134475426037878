import React from "react";
import styled from "styled-components";
import { URL_AUDIO } from "edu_lms/constants/type";
import { UseSound } from "../../components";

const TitleQuestion = ({ questionTitle = [], fontSizeTitle }) => {
  return (
    <Div>
      {questionTitle[0]?.props[0]?.audio[0]?.path && (
        <UseSound src={`${URL_AUDIO}${questionTitle[0]?.props[0]?.audio[0]?.path}`} />
      )}
      <span
        style={{ fontSize: fontSizeTitle ? fontSizeTitle : "25px" }}
        className="quicksand-bold"
      >
        {questionTitle[0]?.props[0]?.text}
      </span>
    </Div>
  );
};

export default TitleQuestion;

const Div = styled.div`
  border-radius: 10px;
  padding: 0.7rem 1.5rem;
  margin-right: 1rem;
  margin-bottom: 0.6rem;
  float: left;
  position: relative;
  margin-left: 30px;
`;
