import React, { useState, useRef } from "react";
import classNames from "classnames";
import FullScreen from "edu_lms_v2/components/FullScreen";
import { Audio, AudioType, Image } from "..";
import styles from "./styles.module.scss";

const ImageWithFullScreen = ({
  src,
  alt = "image with full screen",
  hasFullScreen,
  maxHeight = "auto",
  isTTR,
  srcAudio,
  isReadOnly
}) => {
  
  const refImgWrapper = useRef();
  const [isFullScreen, setIsFullScreen] = useState(false);

  if (!src) return null;

  if (hasFullScreen) {
    return (
      <div
        className={classNames(styles["image-fullscreen__wrapper"], {
          "mw-100 mh-100": isFullScreen,
        })}
        ref={refImgWrapper}
      >
        {isTTR && <Audio
            style={{top: '-10px', left: '-5px'}}
            className="position-absolute p-0"
            variant={AudioType.Primary}
            src={srcAudio}
            isReadOnly={isReadOnly}
          />}
        <Image
          src={src}
          alt={alt}
          className={classNames({
            "image-question-exercise": !isFullScreen,
            "image-question-exercise-full": isFullScreen,
            "border-img": isTTR,
          })}
          maxHeight={maxHeight}
        />
        <FullScreen
          ref={refImgWrapper}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        />
      </div>
    );
  }

  return <Image src={src} alt={alt} maxHeight={maxHeight}/>;
};

export default ImageWithFullScreen;
