import { useState, useEffect } from "react";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import styles from "./StudentExercises.module.scss";
import { getInfoClassroomById } from "edu_lms/services/teachingManager";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH_CLASSROOM } from "edu_lms/constants/path";
import UnfinishedResult from "./components/UnfinishedResult";
import CompletedResult from "./components/CompletedResult";

const tabs = [
  // {
  //   title: "Bảng tin",
  //   icon: "fa fa-home mr-2",
  //   href: "#news",
  //   selected: false,
  // },
  // {
  //   title: "Thành viên",
  //   icon: "fa fa-users mr-2",
  //   href: "#member",
  //   selected: true,
  // },
  {
    title: "Bài tập",
    icon: "fa fa-address-book mr-2",
    href: "#exercise",
    selected: true,
  },
];
export default function StudentExercises({ classroomId }) {
  useScrollToTop();
  const history = useHistory();
  const [tabSelect, setTabSelect] = useState("#exercise");

  const [classroomInfo, setClassroomInfo] = useState({});

  const getInfoClassroom = () => {
    getInfoClassroomById(classroomId)
      .then((res) => {
        if (res.data.status === "success") {
          setClassroomInfo(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getInfoClassroom();
  }, []);
  const handClickBack = () => {
    history.push(ROUTE_PATH_CLASSROOM);
  };
  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr pb-5">
        <div className="container pt-3 pb-5">
          <div className={`row pt-3 ${styles.headerExercises}`}>
            <div className="col-lg-6 col-md-6 col-6">
              <div>
                <p className="font-weight-bold monkey-color-back-reading-book hoc10-f-header monkey-fz-25 ">
                  {classroomInfo?.title}
                </p>
                <button onClick={() => handClickBack()} className={styles.back}>
                  <img src="/assets/img/back.svg" alt="tro-lai" />
                </button>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-6">
              <div className={`mr-auto float-right d-flex ${styles.report}`}>
                <button className="mr-3">
                  <i className="fa fa-bar-chart pr-2" aria-hidden="true"></i>
                  <span>Báo cáo</span>
                </button>
                <img src="/assets/img/Vector.svg" alt="Bộ chọn" />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-center position-relative mb-3">
              <div className={styles.school}>
                <span className="pl-3">
                  {classroomInfo.school_name || "Không thuộc trường"}
                </span>
              </div>
              <p className={styles.student}>
                <span>{classroomInfo?.students_counts} học sinh</span>
                <span>{classroomInfo?.teachers_counts} giáo viên</span>
              </p>
            </div> */}
            <div className={`w-100 text-center border-top pt-3 ${styles.main}`}>
              <ul
                className="nav nav-pills d-flex justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                {tabs.map((tab, index) => (
                  <li key={index} className="mx-1 mx-sm-3">
                    <a
                      href={tab.href}
                      title={tab.title}
                      data-toggle="pill"
                      role="tab"
                      aria-selected={tab.selected}
                      className={tab.href === tabSelect ? styles.active : ""}
                      onClick={() => setTabSelect(tab.href)}
                    >
                      <i className={tab.icon}></i>
                      <span className="ml-1">{tab.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <UnfinishedResult
              classroomInfo={classroomInfo}
              classroomId={classroomId}
            />
            <CompletedResult
              classroomInfo={classroomInfo}
              classroomId={classroomId}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
