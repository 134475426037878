import * as types from "../../constants/actionsType";

export const onDispatchDataInfo = (data) => {
  return {
    type: types.PA_DATA_INFO,
    data,
  };
};


