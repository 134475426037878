import CKB_001 from "./CKB_001";
import DAD_BG from "./DAD_BG";
import DAD_BG_TV from "./DAD_BG_TV";
import DAD from "./DAD";
import FIB_001 from "./FIB_001";
import FIB_003 from "./FIB_003";
import FIB_BG from "./FIB_BG";
import FIBMath from "./FIBMath";
import FillColor from "./FillColor";
import FindCorrectMistakes from "./FindCorrectMistakes";
import Graph001 from "./Graph001";
import Graph002 from "./Graph002";
import IMG_002 from "./IMG_002";
import Karaoke from "./Karaoke";
import MAT_001 from "./MAT_001";
import MAT_BG from "./MAT_BG";
import MTC_003 from "./MTC_003";
import MTC_BG from "./MTC_BG";
import MathInput from "./MathInput";
import REV_001 from "./REV_001";
import SQC_001 from "./SQC_001";
import SROI_001 from "./SROI_001";
import SentenceFinding from "./SentenceFinding";
import WordFinding from "./WordFinding";
import WordSearch from "./WordSearch";
import AITTR from "./AITTR";

export {
  CKB_001,
  DAD_BG,
  DAD_BG_TV,
  DAD,
  FIB_001,
  FIB_003,
  FIB_BG,
  FIBMath,
  FillColor,
  FindCorrectMistakes,
  Graph001,
  Graph002,
  IMG_002,
  Karaoke,
  MAT_001,
  MAT_BG,
  MTC_003,
  MTC_BG,
  MathInput,
  REV_001,
  SQC_001,
  SROI_001,
  SentenceFinding,
  WordFinding,
  WordSearch,
  AITTR
};

export const TypeGameMultipleChoice = {
  IMAGE: "image",
  AUDIO: "audio",
  TEXT: "text",
  VIDEO: "video",
  LATEX: "latex",
  IMG_IMG: 1,
  IMG_TEXT_TEXT: 2,
  IMG_TEXT_IMAGE_TEXT: 3,
  TEXT_TEXT: 4,
  IMAGE_TEXT: 5,
};