import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as PATH from "edu_lms/constants/path";
import LoginWrapper from "edu_lms_v2/modules/Login/LoginWrapper";
import LoginSuccessModal from "edu_lms_v2/modules/Login/LoginSuccessModal";
import { cleanLocalStorage } from "edu_lms/constants/general";
import { onResultUserInfo } from "edu_lms/modules/selection";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import ForgotPassword from "edu_lms_v2/modules/ForgotPassword";
import icon_logout from "edu_lms_v2/assets/img/icon-logout.svg";
import {
  APP_ID as HOC10_APP_ID,
  TUTORING_APP_ID,
} from "edu_lms/constants/type";
import { locales } from "edu_lms_v2/i18n/i18n";
import { sourceFlag } from "edu_lms_v2/i18n/i18n";

export default function Header() {
  const { t, i18n } = useTranslation();
  const currentLanguage = locales[i18n.language];
  const imageLocale = sourceFlag[i18n.language];

  const [loginModalShow, setLoginModalShow] = useState(false);
  const [loginSuccessModalShow, setLoginSuccessModalShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isActiveNavbar, setActiveNavbar] = useState(false);
  const [isRequireLogin, setRequireLogin] = useState(false);
  const [isActiveForgotPw, setActiveForgotPw] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userInfo = onResultUserInfo();
  const fullName =
    useSelector((state) => state.signInReducers.user_info.full_name) ||
    "user2022";
  const avatar = useSelector((state) => state.signInReducers.user_info.avatar);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.full_name)
        dispatch(onDispatchDataInfo({ full_name: userInfo.full_name }));
      if (userInfo.avatar)
        dispatch(onDispatchDataInfo({ avatar: userInfo.avatar }));
    }
  }, []);

  useEffect(() => {
    setIsLoggedIn(Boolean(token));
  }, [token]);

  useEffect(() => {
    const language = localStorage.getItem("language");
    language && i18n.changeLanguage(language);
  }, [currentLanguage]);

  useEffect(()=>{},[])

  const toggleNavbar = () => {
    setActiveNavbar(!isActiveNavbar);
  };

  const Logout = () => {
    setIsLoggedIn(false);
    cleanLocalStorage();
    dispatch(onDispatchDataInfo({ avatar: "" }));
    history.push(PATH.ROUTE_PATH_V2_HOME);
  };
  const onLoginSuccess = () => {
    setLoginModalShow(false);
    setLoginSuccessModalShow(true);
  };

  const onRequireLogin = () => {
    setRequireLogin(false);
    setLoginModalShow(true);
  };
  const onActiveForgotPw = () => {
    setLoginModalShow(false);
    setActiveForgotPw(true);
  };

  const Navbar = styled.nav`
    .container {
      max-width: 1200px;
    }
    .width-logo {
      @media (min-width: 1024px) {
        width: 148px;
      }
    }
  `;

  const handleShowLogo = () => {
    const product = process.env.REACT_APP_APP_ID;
    const logoHoc10 = "/assets/img/updated_logo.png";
    const logoTutoring = "/assets/img/logo-tutoring1.png";
    if (Number(product) === HOC10_APP_ID) return logoHoc10;
    if (Number(product) === TUTORING_APP_ID) return logoTutoring;
  };

  const changeLanguage = (lng = 'en' | 'vi') => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  }

  return (
    <>
      <header>
        <Navbar
          className={classNames("navbar navbar-expand-lg", {
            active: isActiveNavbar,
          })}
          onClick={toggleNavbar}
        >
          <div className="container pr">
            <Link to={PATH.ROUTE_PATH_V2_HOME} className="logo width-logo">
              <img
                src={handleShowLogo()}
                className={classNames({
                  "w-50":
                    Number(process.env.REACT_APP_APP_ID) === TUTORING_APP_ID,
                })}
                alt="Logo Hoc10"
              />
            </Link>
            <button
              className={classNames("navbar-toggler border-0 ", {
                active: isActiveNavbar,
              })}
              type="button"
              onClick={toggleNavbar}
            >
              <span></span>
            </button>
            <div className="navbar-collapse" id="navbar">
              <ul className="navbar-nav" id="navbar-desktop-screen">
                <li>
                  <Link to={PATH.ROUTE_PATH_CLASSROOM} title="Lớp học">
                  {t("classroom")}
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PATH_MY_STUDY} title="Học liệu">
                    {t("learning materials")}
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_TUTORING_HOMEWORK} title="Luyện thi">
                  {t("test preparation")}
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav" id="navbar-mobile-screen">
                <li>
                  <Link to={PATH.ROUTE_PATH_CLASSROOM} title="Lớp học">
                  {t("classroom")}
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PATH_MY_STUDY} title="Học liệu">
                  {t("learning materials")}
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_TUTORING_HOMEWORK} title="Luyện thi">
                  {t("test preparation")}
                  </Link>
                </li>
                <li>
                  <a className="text-dark d-inline-block">{currentLanguage}</a>
                  <ul>
                    <li className="d-flex align-items-center" onClick={() => changeLanguage("vi")}>
                      <button className="mr-2" >
                        <img
                          style={{ width: "20px" }}
                          src={sourceFlag['vi']}
                          alt="Việt Nam"
                        />
                      </button>
                      Việt Nam
                    </li>
                    <li className="d-flex align-items-center" onClick={() => changeLanguage("en")}>
                      <button className="mr-2" >
                        <img
                          style={{ width: "20px" }}
                          src={sourceFlag['en']}
                          alt="English"
                        />
                      </button>
                      English
                    </li>
                  </ul>
                  <span className="show-menu"></span>
                </li>
              </ul>
              <div className="login m-hidden">
                {isLoggedIn ? (
                  <ul className="navbar-nav">
                    <li className="mr-0">
                      <div className="avatar__img">
                        <img
                          src={avatar || "/assets/img/avt2.png"}
                          className="fit"
                          alt="Ảnh đại diện"
                        />
                      </div>
                      <StyleFullname className="text-dark d-inline-block text-truncate">
                        {fullName}
                      </StyleFullname>
                      <ul className="sub-menu bg-sh">
                        <li>
                          <Link to={""} onClick={Logout}>
                            <i className="icon">
                              <img src={icon_logout} alt="Đăng xuất" />
                            </i>{" "}
                            {t("logout")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                ) : (
                  <div className="login__before flex-lc">
                    <div
                      title="Đăng nhập"
                      className="btn_login flex-center cursor"
                      onClick={() => setLoginModalShow(true)}
                    >
                      Đăng nhập
                    </div>
                  </div>
                )}
              </div>

              <div className="dropdown language m-hidden">
                <ul className="navbar-nav">
                  <li className="mr-0 ml-3">
                    <img src={imageLocale} alt="Language" />
                    <StyleFullname className="text-dark d-inline-block text-truncate">
                      {currentLanguage}
                    </StyleFullname>
                    <ul className="sub-menu bg-sh">
                      <li className="mb-2">
                        <button onClick={() => changeLanguage("vi")}>
                          <i className="icon">
                            <img src={sourceFlag['vi']} alt="Việt Nam" />
                          </i>{" "}
                          Việt Nam
                        </button>
                      </li>
                      <li>
                        <button onClick={() => changeLanguage("en")}>
                          <i className="icon">
                            <img src={sourceFlag['en']} alt="English" />
                          </i>{" "}
                          English
                        </button>
                      </li>
                    </ul>
                    <i className="fa fa-angle-down ml-1" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>

              <div className="bottom m-show-flex">
                {isLoggedIn ? (
                  <Link
                    to={""}
                    title="Đăng xuất"
                    className="btn-login flex-center"
                    data-toggle="modal"
                    data-target="#modal-login"
                    onClick={Logout}
                  >
                    <img src={icon_logout} className="mr-3" /> Đăng xuất
                  </Link>
                ) : (
                  <div
                    title="Đăng nhập"
                    className="btn-login flex-center cursor"
                    data-toggle="modal"
                    data-target="#modal-login"
                    onClick={() => setLoginModalShow(true)}
                  >
                    <img src={icon_logout} className="mr-3" /> Đăng nhập
                  </div>
                )}
              </div>
            </div>
            {isLoggedIn && (
              <div className="avatar flex-lc" id="avatar-desktop-screen">
                <div className="avatar__img">
                  <img
                    src={avatar || "/assets/img/avt2.png"}
                    className="fit"
                    alt="Ảnh đại diện"
                  />
                </div>
                <FullNameMobile className="avatar__name avatar-toggle postion-absolute text-truncate">
                  {fullName}
                </FullNameMobile>
              </div>
            )}
          </div>
        </Navbar>
      </header>

      <LoginWrapper
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        onLoginSuccess={onLoginSuccess}
        onActiveForgotPw={onActiveForgotPw}
      />

      <LoginSuccessModal
        show={loginSuccessModalShow}
        onHide={() => {
          setLoginSuccessModalShow(false);
        }}
      />
      <ForgotPassword
        isActiveForgotPw={isActiveForgotPw}
        setActiveForgotPw={setActiveForgotPw}
        setLoginModalShow={setLoginModalShow}
        isPopupLogin={true}
      />
    </>
  );
}
const StyleFullname = styled.div`
  padding: 26px 0;
  font-size: 17px;
  max-width: 130px;
`;
const Icon = styled.i`
  color: #ff7707;
`;

const FullNameMobile = styled.div`
  right: 50px;
  max-width: 150px;
`;
