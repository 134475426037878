import { useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";
import { cleanLocalStorage } from "edu_lms/constants/general";
import { TIME_OUT_LOGOUT } from "edu_lms/constants/type";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import Header from "tutoring/Layout/Header";
import TutoringFooter from "tutoring/Layout/Footer";

export default function TutoringLayout({ children, showHeader, showFooter }) {
  const dispatch = useDispatch();
  const idleTimerRef = useRef();
  const [openActiveBook, setOpenActiveBook] = useState(false);

  const onCloseActiveBook = () => {
    setOpenActiveBook(false);
  };

  const onIdle = () => {
    const isToken = localStorage.getItem("token");
    if (isToken) {
      cleanLocalStorage();
      dispatch(
        onDispatchDataInfo({
          job_id: "",
          full_name: "",
          birth_day: "",
          gender_id: "",
          email: "",
          phone: "",
          province_id: "",
          district_id: "",
          ward_id: "",
          school_id: "",
          list_grade_subject: [],
          show_change_pw: false,
          notification: 1,
          role_id: 0,
        })
      );
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={TIME_OUT_LOGOUT}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showHeader && (
        <Header
          openActiveBook={openActiveBook}
          onCloseActiveBook={onCloseActiveBook}
        />
      )}
      {children}
      {showFooter && <TutoringFooter />}
    </>
  );
}
