import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Element } from "react-scroll";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import * as Sentry from "@sentry/react";
import i18n from 'i18next';

import {
  onDispatchSrcAudio,
  onDispatchSetUserLocation,
} from "./actions";
import { onResultUserInfo, setItemUserInfo } from "../selection";
import history from "./../../helpers/history";
import RouteList from "edu_lms_v2/RouteList";
import Maintenance from "edu_lms/components/Maintenance";
import { getUserLocation } from "edu_lms_v2/services/ipAddress";
import { getUserInfo } from "../../services/signIn/signIn";
import { onDispatchDataInfo } from "../SignIn/actions";

const maintenance = false;

class App extends Component {

  changeLanguage = (language) => {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log('changeLanguage err', err);
    });
  }
  
  componentDidMount() {
    if (!maintenance) {
      getUserLocation()
        .then((res) => {
          if (res.data.status === "success") {
            localStorage.setItem("countryCode", res.data.data?.location?.countryCodeHere);
            this.changeLanguage((res.data.data?.location?.countryCodeHere == "VN") ? "vi" : "en");
            this.props.onDispatchSetUserLocation(res.data.data.location);
          }
        })
        .catch((error) => console.log(error));
      const token = localStorage.getItem("token");
      if (token) {
        getUserInfo().then((res) => {
          if (res.data.status === "success") {
            // Vi api get user info nay khong tra ve email, phone, is_user_hoc10, show_change_pw nen phai exclude 4 field nay truoc khi update user data o fe
            // TODO: Chi dung 1 API duy nhat get user info cho ca app
            const { email, phone, is_user_hoc10, show_change_pw } =
              onResultUserInfo();
            const userInfo = {
              ...res.data.data,
              email,
              phone,
              is_user_hoc10,
              show_change_pw,
            };
            this.props.onDispatchDataInfo(userInfo);
            setItemUserInfo(userInfo);
          }
        });
      }
    }
  }

  render() {
    const options = {
      timeout: 5000,
      position: positions.TOP_RIGHT,
      containerStyle: {
        top: "100px",
      },
    };

    return !maintenance ? (
      <Provider template={AlertTemplate} {...options}>
        <Element name="myScrollToElement">
          <Router history={history}>
            <RouteList />
          </Router>
        </Element>
        {this.props.srcAudio && (
          <ReactAudioPlayer
            src={this.props.srcAudio}
            className="d-none"
            autoPlay={true}
            controls={true}
            onEnded={() => this.props.onDispatchSrcAudio("")}
          />
        )}
      </Provider>
    ) : (
      <Maintenance />
    );
  }
}


const mapStateToProps = (state) => {
  const {
    statusModal,
    isShowLoading,
    srcAudio,
  } = state.app;
  return {
    statusModal,
    isShowLoading,
    srcAudio,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchSrcAudio,
      onDispatchSetUserLocation,
      onDispatchDataInfo,
    },
    dispatch
  );
};

export default Sentry.withProfiler(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
