import AxiosService from "../axiosService";

const listGrade = "list-grade?";
const listSubject = "list-subject?";


export const getListGrade = (data = 0) => {
  const axiosService = new AxiosService();
  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${listGrade}get_all=${data}`
  );
};
export const getListSubject = (id) => {
  const axiosService = new AxiosService();
  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${listSubject}grade_id=${id}`
  );
};

