import _ from "lodash";
import { TYPE_CHECK_ANSWER } from "../constants/SenFinding";

function onResultAnswerIcon(data, dataIcons, type = 2, id = "answer_id") {
  const result = data.map((item) => {
    const resultFilterIcon = dataIcons.filter(
      (icon) => icon.icon_id == item[`${id}`]
    )[0];
    return { ...item, ...resultFilterIcon, status: type === 1 ? 0 : false };
  });
  return result;
}

const formatActivityDataSenFinding = (dataAct) => {
  const { game_config, icon_list } = dataAct;
  const dataAnswersConfig = game_config?.data.map((dataParagraphItem) => {
    const dataParagraph = onResultAnswerIcon(
      dataParagraphItem.paragraph,
      icon_list[0]?.icons,
      1,
      "sentence_id"
    );
    return { ...dataParagraphItem, paragraph: dataParagraph };
  });
  const dataQuestionConfig = icon_list?.[0].icons.filter(
    (icon) => icon.icon_id === game_config.question.icon_id
  );

  dataAct.dataAnswers = dataAnswersConfig;
  dataAct.dataQuestions = dataQuestionConfig;
  return dataAct;
};

export function onChangeColor(status, hideResultAnswer) {
  if (
    hideResultAnswer &&
    (Number(status) === TYPE_CHECK_ANSWER.correct ||
      Number(status) === TYPE_CHECK_ANSWER.wrong)
  ) {
    return "monkey-color-blue monkey-bc-blue";
  }
  let result = "monkey-color-black monkey-bc-transparent";
  switch (Number(status)) {
    case TYPE_CHECK_ANSWER.active:
      return (result = "monkey-color-blue monkey-bc-blue");
    case TYPE_CHECK_ANSWER.correct:
      return (result = "monkey-bg-green monkey-bc-green monkey-color-white");
    case TYPE_CHECK_ANSWER.wrong:
      return (result = "monkey-bg-red monkey-bc-red monkey-color-white");
    default:
      return result;
  }
}

const formatAnswerTexts = (gameData) => {
  const dataConfigAnswers = gameData.dataAnswers.map((item) => {
    const dataParagraph = item.paragraph.map((data) => {
      data.correct && (data.status = TYPE_CHECK_ANSWER.correct);
      return { ...data };
    });
    return { paragraph: dataParagraph };
  });
  return dataConfigAnswers;
};

export { formatActivityDataSenFinding, formatAnswerTexts };
