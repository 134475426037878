export const pages = {
  home: {
    title: "Hoc10 - Bám sát chương trình GDPT mới 2018",
    description:
      "Truy cập Hoc10 ngay để đọc trọn bộ sách giáo khoa Cánh Diều và những tài liệu học tập, giảng dạy hữu ích.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}`,
  },
  intro: {
    title: "Giới thiệu về Hoc10",
    description:
      "Hoc10 mang sứ mệnh xây dựng hệ sinh thái giáo dục, kết nối nhà trường, học sinh và phụ huynh, tạo môi trường dạy và học hiệu quả.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}gioi-thieu/`,
    titleBreadcumb: "Giới thiệu",
  },
  intro_one_percent: {
    title: "Về chúng tôi | Hoc10",
    description:
      "Công ty One Percent đi đầu về lĩnh vực công nghệ trong giáo dục, tạo môi truờng dạy và học trực tuyến chất lượng, sáng tạo.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}ve-chung-toi/`,
    titleBreadcumb: "Về chúng tôi",
  },
  intro_book: {
    title: "Bộ sách cánh diều | Hoc10",
    description:
      "Bộ sách giáo khoa điện tử Cánh Diều trên Hoc10 tích hợp nhiều tính năng: Học liệu điện tử, mục lục điện tử, bài tập tương tác,...",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}bo-sgk-canh-dieu/`,
    titleBreadcumb: "Bộ sách giáo khoa cánh diều",
  },
  intro_author: {
    title: "Đội ngũ chuyên gia | Hoc10",
    description:
      "Đội ngũ chuyên gia Hoc10 là những người giàu kinh nghiệm viết SGK, giàu kinh nghiệm về GDPT, giáo dục tiểu học.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}doi-ngu-chuyen-gia/`,
    titleBreadcumb: "Đội ngũ chuyên gia",
  },
  list_book: {
    title: "Tủ sách | Hoc10",
    description:
      "Tủ sách điện tử tương tác từ lớp 1 đến lớp 12, bao gồm tất cả các môn học dành cho giáo viên, học sinh và phụ huynh tham khảo.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}tu-sach/`,
    titleBreadcumb: "Tủ sách",
  },
  questions: {
    title: "Câu hỏi thường gặp | Hoc10",
    description:
      "Hoc10 hỗ trợ nhanh chóng các vấn đề đăng nhập, đăng ký, quản lý tài khoản, hướng dẫn sử dụng,... cho giáo viên, học sinh và phụ huynh.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}cau-hoi-thuong-gap/`,
    titleBreadcumb: "Câu hỏi thường gặp",
  },
  contact: {
    title: "Liên hệ | Hoc10",
    description:
      "Hoc10 cung cấp các kênh thông tin giúp giáo viên, học sinh và phụ huynh gửi nội dung chia sẻ và nhận tư vấn miễn phí.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}lien-he/`,
    titleBreadcumb: "Liên hệ",
  },
  policy: {
    title: "Chính sách bảo mật | Hoc10",
    description:
      "Hoc10 cam kết sử dụng thông tin khách hàng hợp lý, có cân nhắc và đúng theo quy định của pháp luật Việt Nam.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}chinh-sach-bao-mat/`,
    titleBreadcumb: "Chính sách bảo mật",
  },
  term: {
    title: "Điều khoản sử dụng | Hoc10",
    description:
      "Cung cấp những quy định chung, quyền và trách nhiệm của Hoc10 với người sử dụng.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}dieu-khoan-su-dung/`,
    titleBreadcumb: "Điều khoản sử dụng",
  },
  slide_library: {
    title: "Bài giảng | Hoc10",
    description:
      "Nơi đăng tải các giáo án được xây dựng bởi một đội ngũ giáo viên giỏi, dày dặn kinh nghiệm.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}bai-giang/`,
    titleBreadcumb: "Bài giảng",
  },
  register: {
    title: "Đăng ký | Hoc10",
    description:
      "Đăng ký tài khoản nhanh chóng chỉ với 3 bước. Truy cập tủ sách học MIỄN PHÍ.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}dang-ky/`,
    titleBreadcumb: "Đăng ký",
  },
  update_infomation: {
    title: "Cập nhật thông tin cá nhân | Hoc10",
    description:
      "Cập nhật thông tin cá nhân: Tên, ngày tháng năm sinh, số điện thoại, email... dễ dàng.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}cap-nhat-thong-tin/`,
    titleBreadcumb: "Cập nhật thông tin",
  },
  student: {
    title: "Học sinh | Hoc10",
    description:
      "900+ bài luyện tập, 500+ đề thi thử, 10.000+ học sinh đang học với Hoc10. Dễ dàng học tập ngay tại nhà.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}hoc-sinh/`,
    titleBreadcumb: "Học sinh",
  },
  teacher: {
    title: "Giáo viên | Hoc10",
    description:
      "100+ đầu sách, 300+ bài giảng đã thiết kế được  trên 5.000 giáo viên tin dùng. ",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}giao-vien/`,
    titleBreadcumb: "Giáo viên",
  },
  mock_test: {
    title: "Luyện thi | Hoc10",
    description:
      "900+ bài luyện tập, 500+ đề thi thử, 10.000+ học sinh đang học với Hoc10. Dễ dàng học tập ngay tại nhà.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}luyen-thi/`,
    titleBreadcumb: "Luyện thi",
  },
  training: {
    title: "Tập huấn | Hoc10",
    description:
      "Truy cập Hoc10 ngay để đọc trọn bộ sách giáo khoa Cánh Diều và những tài liệu học tập, giảng dạy hữu ích.",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: `${process.env.REACT_APP_URL}taphuan`,
    titleBreadcumb: "Tập huấn",
  },
};
