import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getLevelSchool } from "edu_lms_v2/services/listBook";

export default function SchoolLevel({ setValueSchool, valueSchool }) {
  const { t } = useTranslation();
  const [optionSchool, setOptionSchool] = useState([]);

  useEffect(() => {
    getLevelSchool()
      .then((res) => {
        if (res.data.status === "success") {
          const newData = res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          setOptionSchool(newData);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="filter__item">
      <h4>{t("school level")}</h4>
      <div className="filter__item__list filter__item__list--select">
        <Select
          value={valueSchool}
          options={optionSchool}
          onChange={(option) => setValueSchool(option)}
          styles={{ control: (base) => ({ ...base, marginTop: "15px" }) }}
        />
      </div>
    </div>
  );
}
