import React, { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import _ from "lodash";
import { TYPE_DATA } from "edu_lms_v2/libraries/hoc10Game/constants";
import THEMES from "edu_lms_v2/libraries/hoc10Game/constants/themes";
import OrderAnswerIndex from "./components/OrderAnswerIndex";
import OrderAnswerNoIndex from "./components/OrderAnswerNoIndex";
import ContentAnswer from "./components/ContentAnswer";
import ContentAnswerNoIndex from "./components/ContentAnswerNoIndex";
import { AUDIO_ACTION } from "edu_lms/constants/type";
import { getStylesOrderAnswerIndex, getStylesOrderAnswerNoIndex } from "../../helpers/MTC_003";
import { Audio, AudioType } from "../../components";
import "./styles.scss";

// For MTC_003, CKB_001
const MultiChoiceContent = ({
  answer,
  isCheckedAnswer,
  hideResultAnswer = false,
  showCorrectAnswer,
  isReadOnly = false,
  theme,
  selectedAnswers,
  onClickAnswer,
}) => {
  const {
    typeOrderAnswer,
    typeContentAnswer,
    numberOfAnswersInRow,
    answers,
  } = answer;
  const [srcAudio, setSrcAudio] = useState("");
  const [audioAnswer, setAudioAnswer]= useState({ answerId: "", src: "" });

  const handleClickAnswer = (answerId) => {
    if (isReadOnly) return;
    onClickAnswer(answerId);
    setSrcAudio(AUDIO_ACTION);
    setTimeout(() => {
      setSrcAudio("");
    }, 500);
  };

  const handleToggleAudioAnswer = (answerId, srcAudio) => {
    setAudioAnswer({ answerId, src: srcAudio });
  }

  const handleResetAudioAnswer = () => {
    setAudioAnswer({ answerId: "", src: "" });
  }

  const columns = theme === THEMES.MultipleChoice.Outline ? 2 : numberOfAnswersInRow;
  return (
    <ContentWrapper columns={columns}>
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      {answers.map((answer, index) => {
        const { color, background } = getStylesOrderAnswerNoIndex({
          isCheckedAnswer,
          hideResultAnswer,
          showCorrectAnswer,
          selectedAnswers,
          answer,
        });

        if (theme === THEMES.MultipleChoice.Outline) {
          return (
            <div
              key={index}
              className={classNames(
                "theme1-box cursor d-flex align-items-center",
                {
                  "justify-content-center": _.includes(
                    typeContentAnswer,
                    TYPE_DATA.IMAGE
                  ),
                  "view-only": isReadOnly,
                }
              )}
              style={{ borderColor: color, background }}
              onClick={() => handleClickAnswer(answer.answerId)}
            >
              <OrderAnswerNoIndex
                answer={answer}
                selectedAnswers={selectedAnswers}
                styles={{ color }}
              />
              <ContentAnswerNoIndex
                answer={answer}
                typeContentAnswer={typeContentAnswer}
              />
            </div>
          );
        }

        return (
          <div
            key={index}
            className={classNames("cursor d-flex align-items-center", {
              "justify-content-center": numberOfAnswersInRow > 2,
              "view-only": isReadOnly,
              "flex-column-revert": _.includes(
                typeContentAnswer,
                TYPE_DATA.IMAGE
              ),
            })}
          >
            <div
              className="d-flex justify-content-center monkey-fz-25 label-answer-index__wrapper"
              onClick={() => handleClickAnswer(answer.answerId)}
            >
              <OrderAnswerIndex
                typeIndex={typeOrderAnswer}
                index={index}
                styles={getStylesOrderAnswerIndex({
                  isCheckedAnswer,
                  hideResultAnswer,
                  showCorrectAnswer,
                  selectedAnswers,
                  answer,
                })}
              />
            </div>
            <ContentAnswer
              audioAnswer={audioAnswer}
              answer={answer}
              typeContentAnswer={typeContentAnswer}
              onClickAnswer={() => handleClickAnswer(answer.answerId)}
              onToggleAudioAnswer={handleToggleAudioAnswer}
            />
          </div>
        );
      })}
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      <Audio
        isFullSrc
        src={srcAudio}
        variant={AudioType.Secondary}
        autoPlay
        className="d-none"
      />
      <Audio
        src={audioAnswer.src}
        variant={AudioType.Secondary}
        autoPlay
        onEnded={handleResetAudioAnswer}
        className="d-none"
      />
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
    </ContentWrapper>
  );
};

export default MultiChoiceContent;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  grid-gap: 15px;
  text-align: center;

  .view-only {
    pointer-events: none;
  }

  .theme1-box {
    position: relative;
    max-height: 250px;
    min-height: 48px;
    padding-left: 30px;
    border: 2px solid #eaeced;
    border-radius: 10px;
    overflow: hidden;
  }
  .theme1-box:hover {
    border-color: rgba(255, 119, 0, 0.6) !important;
  }
  .theme1-box .content-answer__wrapper {
    img {
      height: 100%
    }
  }
`;
