import React from "react";
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import CTAIcon from "./CTAIcon";
import CTAMessage from "./CTAMessage";
import {
  AUDIO_ERROR,
  AUDIO_VICTORY,
  ALERT_GAME,
  BOOK_LANGUAGE,
  TYPE_ENGLISH,
} from "edu_lms/constants/type";
import GameButton from "../GameButton";
import { TYPE_BUTTON_GAME } from "../../constants";

export default function FinalReport({
  total,
  correctTotal,
  alertType,
  languageBook,
  onResetAnswer,
}) {
  const textBookLanguage =
    BOOK_LANGUAGE.find((lang) => lang.id === Number(languageBook)) || {};

  return (
    <div className="d-flex justify-content-center h-100 text-center monkey-fz-30">
      <div className="d-inline-flex w-100 align-items-center">
        <div className="fireworks w-100 text-center">
          <ContentAlert className="d-flex justify-content-center align-items-center text-center">
            <div className="icon-alert">
              <CTAIcon alertType={alertType} />
            </div>
            <div className="align-items-center flex-column pt-3">
              <p className="text-center">
                <span className="monkey-color-blue p-2">
                  {correctTotal}/{total}
                </span>{" "}
                {textBookLanguage.editShowResult}
              </p>
              <CTAMessage alertType={alertType} languageBook={TYPE_ENGLISH} />
            </div>
          </ContentAlert>
          <GameButton type={TYPE_BUTTON_GAME.Refresh} onClick={onResetAnswer} />
          <ReactAudioPlayer
            src={alertType === ALERT_GAME.success ? AUDIO_VICTORY : AUDIO_ERROR}
            className="d-none"
            autoPlay
            controls
          />
        </div>
      </div>
    </div>
  );
}

const ContentAlert = styled.div`
  padding-right: 100px;
  .icon-alert {
    width: 100px;
  }
`;
