import React, { useState, useEffect, useImperativeHandle } from "react";
import { formatAnswerTexts } from "../../helpers/SenFinding";
import { TYPE_CHECK_ANSWER } from "../../constants/SenFinding";
import Answer from "./Answer";
import HeaderQuestion from "./HeaderQuestion";
import { forwardRef } from "react";

const SentenceFinding = (
  {
    gameData,
    hideResultAnswer = false,
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { dataQuestions, dataAnswers, game_config, selectedAnswers } = gameData;
  const [answers, setAnswers] = useState(dataAnswers);
  useEffect(() => {
    setAnswers(selectedAnswers || dataAnswers);
  }, [gameData]);

  useEffect(() => {
    if (showCorrectAnswer) {
      const allCorrectAnswerTexts = formatAnswerTexts(gameData);
      setAnswers(allCorrectAnswerTexts)
    }
  }, [showCorrectAnswer, hideResultAnswer]);

  const onActiveText = (answerId) => {
    const newData = answers.map((dataItem) => {
      const dataParagraph = dataItem.paragraph.map((item) => {
        if (item.sentence_id === answerId) {
          item.status = item.status
            ? TYPE_CHECK_ANSWER.default
            : TYPE_CHECK_ANSWER.active;
        } else if (
          item.status === TYPE_CHECK_ANSWER.wrong ||
          item.status === TYPE_CHECK_ANSWER.correct
        ) {
          item.status = TYPE_CHECK_ANSWER.active;
        }
        return { ...item };
      });
      return { ...dataItem, paragraph: dataParagraph };
    });
    onPlaying(false);
    setAnswers(newData);
  };

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
  }));

  const handleCheckAnswer = () => {
    let isCorrect = true;
    const dataConfig = answers.map((data) => {
      const dataParagraph = data.paragraph.map((item) => {
        if (item.correct) {
          if (item.status === TYPE_CHECK_ANSWER.active) {
            item.status = TYPE_CHECK_ANSWER.correct;
          } else {
            isCorrect = false;
          }
        } else {
          if (item.status === TYPE_CHECK_ANSWER.active) {
            item.status = TYPE_CHECK_ANSWER.wrong;
            isCorrect = false;
          }
        }
        return { ...item };
      });
      return { ...data, paragraph: dataParagraph };
    });
    setAnswers(dataConfig);
    onComplete({ isCorrect, selectedAnswers: dataConfig });
  };
  if(!answers) return;

  return (
    <>
      <HeaderQuestion gameConfig={game_config} content={dataQuestions[0]} />
      <Answer
        dataAnswers={answers[0].paragraph}
        fontSize={game_config.font_size}
        isReadOnly={isReadOnly}
        hideResultAnswer={hideResultAnswer && !showCorrectAnswer}
        onActiveText={onActiveText}
      />
    </>
  );
};

export default forwardRef(SentenceFinding);
