import { Redirect, Route, Switch } from "react-router-dom";

import authentication from "edu_lms/modules/Authentication";
import * as PATH from "edu_lms/constants/path";
import routes from "edu_lms/components/router";
import TutoringLayout from "tutoring/Layout";
import { NotFoundContainer } from "edu_lms/modules/NotFound";

export function PrivateRoute({
  component: Component,
  layout: Layout,
  ...rest
}) {
  switch (rest.routerKey) {
    case 1:
      return (
        <Route
          {...rest}
          render={(props) => (
            <Layout
              {...props}
              showHeader={rest.showHeader}
              showFooter={rest.showFooter}
            >
              {rest.accept ? <Component {...props} /> : <NotFoundContainer />}
            </Layout>
          )}
        />
      );
    case 2:
      return (
        <Route
          {...rest}
          render={(props) => {
            return authentication.isAuthentication() ? (
              <Layout
                {...props}
                isIdleTimer={true}
                showHeader={rest.showHeader}
                showFooter={rest.showFooter}
              >
                {rest.accept ? <Component {...props} data={rest.data} /> : <NotFoundContainer />}
              </Layout>
            ) : (
              <Redirect
                to={{
                  pathname: PATH.ROUTE_PATH_V3_SIGN_IN,
                  state: { from: rest.location.pathname },
                }}
              />
            );
          }}
        />
      );
    default:
      return "";
  }
}

export default function RouteList() {

  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.main}
          layout={TutoringLayout}
          routerKey={route.key}
          showHeader={route.showHeader}
          showFooter={route.showFooter}
          data={route.routes}
          accept={route.accept}
          sensitive
        />
      ))}
    </Switch>
  );
}
